import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ChangeActionGeneralComponent } from '../change-action-general.component';
import { ChangeOrderRequestSidebarComponent } from './change-order-request-sidebar/change-order-request-sidebar.component';
import {
  CORDetail,
  PCODetail,
  PotentialChangeOrderModel,
  PotentialChangeOrderStatuses,
} from '@app/store/prime-commitments/prime-commitments.types';
import { emptyPotentialChangeGeneral } from '@app/store/prime-commitments/prime-commitments.constants';
import {
  primeChangesActions,
  primePCODetailsSidebarActions,
} from '@app/store/prime-commitments/prime-commitments.actions';
import { DeepCopyService } from '@app/services/deep-copy.service';
import { primeCommitmentsDetailsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { PotentialChangeOrderSidebarComponent } from '@app/framework/interaction-bar/prime-changes/prime-potential-changes/potential-change-line-items/potential-change-order-sidebar.component';

@Component({
  selector: 'app-change-action-sidebar',
  standalone: true,
  imports: [
    ChangeActionGeneralComponent,
    PotentialChangeOrderSidebarComponent,
    ChangeOrderRequestSidebarComponent,
  ],
  templateUrl: './change-action-sidebar.component.html',
  styleUrl: './change-action-sidebar.component.scss',
})
export class ChangeActionSidebarComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);

  pcoDetail$ = this.store.select(primeCommitmentsDetailsSelectors.selectPCODetailsSidebar);
  corDetail$ = this.store.select(primeCommitmentsDetailsSelectors.selectCORDetailsSidebar);
  pcoDetail: PCODetail = null;
  corDetail: CORDetail = null;

  @Input() data: {
    isEdit: boolean;
    changeActionType: 'Potential Change Order' | 'Change Order Request';
  };
  currentPage: 1 | 2 = 1;
  model: PotentialChangeOrderModel = {
    generalData: emptyPotentialChangeGeneral(),
    selectedPCOIds: [],
    lineItems: [],
  };

  ngOnInit() {
    this.store.dispatch(primeChangesActions.onChangeActionSidebarOpen());
    if (!this?.data?.isEdit) {
      return;
    }
    // then load data from PCO or COR Details
    if (this.data.changeActionType === 'Potential Change Order') {
      this.pcoDetail$.pipe(take(1)).subscribe((pcoDetail: PCODetail) => {
        this.pcoDetail = pcoDetail;
        this.model = {
          generalData: {
            type: 'Potential Change Order',
            id: pcoDetail.id,
            title: pcoDetail.title,
            number: pcoDetail.number,
            status: pcoDetail.status,
            scope: pcoDetail.scope,
            date: pcoDetail.date,
            duration_extension: String(pcoDetail.duration_extension),
            description: pcoDetail.description,
          },
          lineItems: [], // line items are not editable, on save it will be loaded in the store with the original values
          selectedPCOIds: [],
        };
      });
    } else {
      this.corDetail$
        .pipe(
          filter((data) => !!data),
          take(1),
        )
        .subscribe((corDetail) => {
          this.corDetail = corDetail;
          this.model = {
            generalData: {
              type: 'Change Order Request',
              id: corDetail.id,
              title: corDetail.title,
              number: corDetail.number,
              status: corDetail.status,
              date: corDetail.date,
              duration_extension: null,
              description: corDetail.description,
            },
            lineItems: [],
            selectedPCOIds: corDetail.pcos.map((pco) => pco.id),
          };
        });
    }
  }

  ngOnDestroy() {
    this.store.dispatch(primeChangesActions.onChangesSidebarClosed());
  }

  decrementPage() {
    if (this.currentPage === 1) {
      return;
    }

    console.log('model', this.model);
    this.currentPage--;
  }

  incrementPage() {
    this.currentPage++;
  }

  savePCO() {
    this.store.dispatch(
      primeChangesActions.onPCOSubmit({ model: DeepCopyService.deepCopy(this.model) }),
    );
  }

  updateForm() {
    this.pcoDetail = {
      ...this.pcoDetail,
      id: this.model.generalData.id,
      title: this.model.generalData.title,
      number: this.model.generalData.number,
      status: this.model.generalData.status as PotentialChangeOrderStatuses,
      date: this.model.generalData.date,
      duration_extension: this.model.generalData.duration_extension,
      description: this.model.generalData.description,
    };

    this.store.dispatch(
      primePCODetailsSidebarActions.onPCOChangeActionFirstPageSync({
        PCO: DeepCopyService.deepCopy(this.pcoDetail),
      }),
    );
  }

  /**
   * Save or Update the Change Order Request
   */
  saveCOR() {
    this.store.dispatch(
      primeChangesActions.onCORSubmit({
        model: DeepCopyService.deepCopy(this.model),
      }),
    );
  }

  clearLines() {
    this.model.lineItems = [];
  }
}
