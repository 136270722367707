<div class="left">
  <div class="form column titles-column">
    <div
      class="pr-[15px] box-border flex gap-1"
      [ngClass]="{
        'pl-4': isSubItem
      }"
    >
      <div cdkDragHandle class="icon-draggable flex items-center justify-center text-color_secondary cursor-grab"></div>
      @if (userService.isManager) {
        <app-floating-input
          class="w-full"
          [ngClass]="{
            'subitem-spend-input-bg': isSubItem,
            'spend-input-bg': !isSubItem
          }"
          [name]="'title_' + item.id"
          (change)="registerChangeName($event.target.value, item)"
          (iconClick)="deleteLineFromStore(item.id)"
          (focusin)="clearName(item)"
          (focusout)="showName(item)"
          [(model)]="item.name"
          [disabled]="hasCommitments$ | async"
          [iconClass]="{
            position: 'p-input-icon-right',
            icon: canDelete ? 'icon icon-remove pt-1' : ''
          }"
          [verticalSpacing]="false"
        >
        </app-floating-input>
      } @else {
        <div
          class="spend-name-input-gc"
          [ngClass]="{
            'bg-shade_7': !isSubItem,
            'bg-color_light_green_4': isSubItem
          }"
        >
          <div class="name">{{ item.name }}</div>
          <div class="other-info">{{ item.division }} {{ item.cost_type }}</div>
        </div>
      }
    </div>
  </div>
  <div class="form column budget-area">
    <div class="input total-year">
      <app-spend-custom-input
        [disabled]="
          item.distribution === DISTRIBUTION_TYPES.MANUAL ||
          item?.committed_items?.length > 0 ||
          item?.subitems?.length > 0
        "
        (valueChange)="setItemTotal($event, item); calcDistribution(item)"
        class="app-custom-input"
        [value]="item.project_total"
        [ngClass]="{
          'totals-match-error':
            item.project_total !== item.original_budget_total &&
            !!item.commitment_start_date &&
            item.distribution === DISTRIBUTION_TYPES.MANUAL,
          'subitem-spend-input-bg': isSubItem,
          'spend-input-bg': !isSubItem
        }"
        [pTooltip]="
          item.project_total !== item.original_budget_total &&
          !!item.commitment_start_date &&
          item.distribution === DISTRIBUTION_TYPES.MANUAL
            ? 'For manual distribution with contract, the total must be equal to the initial total which is ' +
              (item.original_budget_total >= 0
                ? '$' + item.original_budget_total
                : '($' + item.original_budget_total * -1 + ')')
            : ''
        "
        tooltipPosition="bottom"
      >
      </app-spend-custom-input>
    </div>
  </div>
</div>
<div class="right">
  <div class="distribution-type-area">
    @if (!userService.isGeneralContractor || isSubItem || item.subitems.length === 0) {
      <div class="input column">
        <app-input-calendar
          [minStartDate]="minStartDate"
          [date]="item.start_date"
          (dateChanged)="setStartDate($event, item); calcDistribution(item)"
          [showLabel]="false"
          [name]="'start_date_' + item.id"
          class="prime-calendar-spend"
          readonly
        ></app-input-calendar>
      </div>

      <div class="input select-with-border">
        <app-dropdown
          (modelChange)="setItemDuration($event, item); calcDistribution(item)"
          [model]="item.duration"
          [name]="'duration' + item.id"
          [ngModelOptions]="{ standalone: true }"
          [options]="durationMonths"
          [showLabel]="false"
          [showError]="false"
          appendTo="body"
          class="spend-app-dropdown"
        ></app-dropdown>
      </div>
      <!--    <div *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS" class="input disabled"></div>-->

      <div class="input select-with-border">
        <app-dropdown
          optionValue="key"
          optionLabel="name"
          (modelChange)="setItemDistribution($event, item); calcDistribution(item)"
          [model]="item.distribution"
          [name]="'distribution_type_' + item.id"
          [ngModelOptions]="{ standalone: true }"
          [options]="distributionTypes"
          [showLabel]="false"
          [showError]="false"
          appendTo="body"
          class="spend-app-dropdown"
        ></app-dropdown>
      </div>
    } @else {
      <div class="spend-value-missing">N/A</div>
      <div class="spend-value-missing">N/A</div>
      <div class="spend-value-missing">N/A</div>
    }
  </div>

  <div class="months">
    <div class="input-wrapper month-input column">
      <div class="input">
        <app-spend-custom-input
          [ngClass]="{
            'subitem-spend-input-bg': isSubItem,
            'spend-input-bg': !isSubItem
          }"
          [disabled]="true"
          class="app-custom-input"
          [value]="item.year_total"
        >
        </app-spend-custom-input>
      </div>
    </div>
    @for (month of MONTHS_KEYS; track $index) {
      <div class="input-wrapper month-input column">
        <div class="input">
          <app-spend-custom-input
            (valueChange)="registerChange($event, item, month)"
            class="app-custom-input"
            [ngClass]="{
              'project-activity': !item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL,
              'subitem-spend-input-bg': isSubItem,
              'spend-input-bg': !isSubItem
            }"
            [disabled]="
              item.monthly_disable[month] ||
              item.distribution !== DISTRIBUTION_TYPES.MANUAL ||
              (!isSubItem && userService.isGeneralContractor)
            "
            [value]="item.monthly_data[month]"
            [pTooltip]="
              (!item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL) ||
              item.monthly_disable[month]
                ? tooltipContent
                : ''
            "
            tooltipPosition="bottom"
          >
          </app-spend-custom-input>
          <ng-template #tooltipContent>
            @if (!item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL) {
              Input field cannot be edited, as it is being distributed based on project activity budget.
            } @else if (item.monthly_disable[month]) {
              Input field cannot be edited outside project activity.
            }
          </ng-template>
        </div>
      </div>
    }
  </div>
</div>
