<form #spendEntry="ngForm" role="form" class="mb-6">
  <app-commitment-entry-text
    (removeClicked)="removeEntry()"
    entryType="General"
    [index]="index"
    [text]="'Spend Entry'"
    [disableAddDescription]="!model?.item?.id || disableAddDescription"
    [disableRemovedEntry]="model?.has_commitments || disableRemovedEntry"
    [disabledAddDescriptionTooltip]="disabledAddDescriptionTooltip"
  ></app-commitment-entry-text>

  <div class="horizontal-spacing">
    <div class="two-input-row">
      <div
        class="p-float-label flex flex-col p-input-icon-right mb-2 mt-7"
        [ngClass]="{ 'p-disabled': model?.has_commitments }"
        (click)="onLineItemInputClick($event)"
      >
        <input
          required
          readonly
          class="w-full cursor-pointer pr-8 overflow-hidden text-nowrap text-ellipsis"
          type="text"
          pInputText
          name="item_name"
          placeholder="Budget Line Item"
          #item_name="ngModel"
          #budgetLineOptionsTrigger="cdkOverlayOrigin"
          cdkOverlayOrigin
          [(ngModel)]="model.item.name"
          [ngClass]="{
            'ng-invalid ng-dirty': item_name.invalid && item_name.touched
          }"
        />
        <label> Budget Line Item</label>
        <small
          [ngClass]="{
            hidden: item_name.valid || item_name.untouched
          }"
          class="p-error text-uppercase"
          >REQUIRED</small
        >

        <i
          class="-mt-[4px]"
          [ngClass]="isDropdownShown.line_item ? 'dropdown-icon opened' : 'dropdown-icon closed'"
        ></i>

        <app-overlay-general
          [(show)]="isDropdownShown.line_item"
          [trigger]="budgetLineOptionsTrigger"
          [overlayPositions]="dropdownAlignRight"
        >
          <app-budget-line-item-view-table-dropdown
            [disabledLineItems]="disabledLineItems"
            (optionSelected)="lineItemOptionSelected($event)"
            [commitmentType]="commitmentType"
          >
          </app-budget-line-item-view-table-dropdown>
        </app-overlay-general>
      </div>

      <div
        [ngClass]="{ 'p-disabled': model?.has_commitments || disableBudgetTagDropdown }"
        class="p-float-label flex flex-col p-input-icon-right mb-2 mt-7"
        (click)="isDropdownShown.budget_tag = true"
      >
        <input
          pInputText
          cdkOverlayOrigin
          #optionsTrigger="cdkOverlayOrigin"
          id="budget_tag"
          name="budget_tag"
          #budget_tag="ngModel"
          [(ngModel)]="model.budget_tag.name"
          class="pointer-cursor pr-8 overflow-hidden text-nowrap text-ellipsis"
          [ngClass]="{
            'ng-invalid ng-dirty': budget_tag.invalid && budget_tag.touched
          }"
          placeholder="Budget Tag"
          readonly
        />

        <label>Budget Tag</label>
        <small
          [ngClass]="{
            hidden: budget_tag.valid || budget_tag.untouched
          }"
          class="p-error text-uppercase"
          >REQUIRED</small
        >

        <i class="-mt-[4px]" [ngClass]="isDropdownShown.budget_tag ? 'dropdown-icon opened' : 'dropdown-icon closed'">
        </i>

        <app-overlay-general
          [(show)]="isDropdownShown.budget_tag"
          [trigger]="optionsTrigger"
          [overlayPositions]="dropdownAlignRight"
        >
          <app-budget-tag-templates-dropdown
            *ngIf="isDropdownShown.budget_tag"
            [budgetTemplateTags]="budgetTags$ | async"
            [disabledBudgetTagIds]="disabledBudgetTagIds"
            [hasTemplate]="!!model.budget_tag.id"
            (optionSelected)="selectedBudgetTag($event)"
            (unlinkTemplate)="unlinkTemplate()"
          ></app-budget-tag-templates-dropdown>
        </app-overlay-general>
      </div>
    </div>

    <div
      [ngClass]="{ 'p-disabled': model?.has_commitments }"
      class="two-input-row"
      *ngIf="commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST"
    >
      <span *ngIf="commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT" class="flex-1 min-w-0">
        <app-input-calendar
          #calendar_picker
          [required]="true"
          [date]="model.start_date"
          [minStartDate]="commitmentAddedAt ?? projectData?.start_date"
          [showIcon]="true"
          (dateChanged)="updateEntryDate($event)"
          placeholder="Start Date"
        ></app-input-calendar>
      </span>

      <app-dropdown
        *ngIf="changeOrderType !== CHANGE_ORDER_TYPE.DOLLARS"
        [required]="true"
        name="duration"
        [(model)]="model.duration"
        [options]="spendDurationMonths"
        optionLabel="label"
        optionValue="value"
        [placeholder]="
          changeOrderType === CHANGE_ORDER_TYPE.SCOPE ||
          commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER
            ? 'Duration'
            : 'Duration Extension'
        "
        [label]="
          changeOrderType === CHANGE_ORDER_TYPE.SCOPE ||
          commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER
            ? 'Duration'
            : 'Duration Extension'
        "
        appendTo="body"
        (modelChange)="updateCommitmentItem()"
        [ngClass]="{
          'col-span-2': commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER
        }"
      >
        <ng-template let-duration #selectedContent>
          {{ duration.label }} {{ duration.label === 1 ? 'Month' : 'Months' }}
        </ng-template>
        <ng-template let-duration #optionContent>
          {{ duration.label }} {{ duration.label === 1 ? 'Month' : 'Months' }}
        </ng-template>
      </app-dropdown>
    </div>

    @for (cost of model.costs; let costIndex = $index; track trackByIndexAndCost(cost, index)) {
      <app-cost-description
        #costDescription
        [commitmentType]="commitmentType"
        [spendEntryIndex]="index"
        [costIndex]="costIndex"
        [model]="cost"
        [totalCostCount]="model.costs.length"
        [changeOrderType]="changeOrderType"
      ></app-cost-description>
    }
  </div>
</form>
