import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { Store } from '@ngrx/store';
import { TooltipModule } from 'primeng/tooltip';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import {
  getDefaultMarkup,
  getDefaultMarkupGroup,
} from '@app/store/prime-commitments/prime-commitments.constants';
import {
  IPrimeContractModel,
  PrimeDescriptionOption,
} from 'src/app/store/prime-commitments/prime-commitments.types';
import { CommitmentEntryTextComponent } from '@app/framework/commitments/sidebar/commitment-entry-text/commitment-entry-text.component';
import { CommitmentSidebarTitleComponent } from '@app/framework/commitments/sidebar/commitment-sidebar-title/commitment-sidebar-title.component';
import { primeSidebarActions } from '@app/store/prime-commitments/prime-commitments.actions';

@Component({
  selector: 'app-prime-change-order-markups',
  standalone: true,
  imports: [
    FloatingInputComponent,
    AddRemoveButtonsComponent,
    SimpleButtonComponent,
    DropdownComponent,
    FormsModule,
    NgScrollbar,
    CommitmentEntryTextComponent,
    CommitmentSidebarTitleComponent,
    TooltipModule,
  ],
  templateUrl: './prime-change-order-markups.component.html',
  styleUrl: './prime-change-order-markups.component.scss',
})
export class PrimeChangeOrderMarkupsComponent implements AfterViewInit {
  private readonly store = inject(Store);

  @ViewChild('COMarkups') markupsForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();

  allScheduleOfValues: PrimeDescriptionOption[] = [];
  allMarkupGroupCombos: { label: string; value: number[] }[] = [];

  ngAfterViewInit() {
    this.allScheduleOfValues = this.model.spendEntries.flatMap((entry) => {
      return entry.spend_descriptions.map((spendDescription) => {
        return {
          description_id: spendDescription.id,
          name: spendDescription.description,
        };
      });
    });

    this.setMarkupCombinations();
  }

  addGroup() {
    this.model.markupGroups.push(getDefaultMarkupGroup());
    this.model.markupGroups = this.model.markupGroups.map((item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });
    this.setMarkupCombinations();
  }

  removeGroup(groupIndex: number) {
    this.model.markupGroups.splice(groupIndex, 1);
    this.model.markupGroups = this.model.markupGroups.map((item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });
    this.setMarkupCombinations();
  }

  setMarkupCombinations() {
    this.allMarkupGroupCombos = this.getValidCombinations().flatMap((combinations) => {
      const label = combinations
        .map((groupIndex) => {
          if (groupIndex === 0) {
            return 'Subtotal';
          }
          return 'Group ' + groupIndex;
        })
        .join(' + ');
      return { label: label, value: combinations };
    });
  }

  /**
   * Generate all valid combinations.
   * Ensures that subtotal (0) is always included.
   * Excludes the full subset [0, 1, 2, 3].
   * @return {number[][]} Array of valid group combinations.
   */
  getValidCombinations(): number[][] {
    const subtotal = 0; // this is always included
    const groupIndexes = this.model.markupGroups.map((_, index) => index + 1); // these are all the group indexes
    const totalGroups = groupIndexes.length;
    const combinations: number[][] = [];

    // Iterate over all possible subsets (excluding empty and full set), 2^N - 1 (111) is skipped to avoid selecting all groups.
    // Ex: Math.pow(2, 3) = 8, Binary range: 000 → 111 (0 to 7)
    for (let subsetMask = 0; subsetMask < Math.pow(2, totalGroups) - 1; subsetMask++) {
      const combination = [subtotal]; // Always include subtotal (0)

      for (let index = 0; index < totalGroups; index++) {
        // checks if the bit at position index is set (1) and group should be included or not
        // ex: 001 => [1], 110 => [3, 2], 011, [2,1], etc
        if (subsetMask & Math.pow(2, index)) {
          combination.push(groupIndexes[index]);
        }
      }

      combinations.push(combination);
    }

    return combinations.sort((a, b) => a.length - b.length);
  }

  removeItem(index: number, markupIndex: number) {
    this.model.markupGroups[index].markups.splice(markupIndex, 1);
    this.model.markupGroups[index].markups = this.model.markupGroups[index].markups.map(
      (item, index) => {
        return {
          ...item,
          index,
        };
      },
    );
  }

  addItem(index: number, markupIndex: number) {
    this.model.markupGroups[index].markups.splice(markupIndex + 1, 0, getDefaultMarkup());
    this.model.markupGroups[index].markups = this.model.markupGroups[index].markups.map(
      (item, index) => {
        return {
          ...item,
          index,
        };
      },
    );
  }

  isFormValid() {
    this.markupsForm.form.markAllAsTouched();
    return this.markupsForm.form.valid;
  }

  goToNextPage() {
    this.markupsForm.form.markAllAsTouched();
    if (!this.isFormValid()) {
      return;
    }
    this.store.dispatch(primeSidebarActions.onClickNext());
  }

  goBack() {
    this.store.dispatch(primeSidebarActions.onPrimeContactViewPreviousPage());
  }
}
