<div
  #spendTableWrapper
  cdkDropListOrientation="vertical"
  cdkDropListLockAxis="y"
  cdkDropList
  cdkScrollable
  class="data-wrapper relative"
  [ngClass]="{
    'cursor-grab': isDragging,
    'lines-vertical-spacing':
      (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET ||
      (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_PARENT_ONLY
  }"
  (cdkDropListDropped)="dropLineItem($event)"
>
  <app-budget-line-item-table-header
    [selectedBudgetType]="selectedBudgetType$ | async"
  ></app-budget-line-item-table-header>

  <div class="vertical-line first"></div>
  <div class="vertical-line second"></div>
  <div
    class="vertical-line third"
    [style]="{
      height: thirdLineHeight() + 'px'
    }"
  ></div>

  @if (
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET ||
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_PARENT_ONLY ||
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_PARENT_CHILD ||
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS ||
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS_GC
  ) {
    @for (item of items; track item.id) {
      <div
        cdkDrag
        (cdkDragStarted)="isDragging = true"
        (cdkDragEnded)="isDragging = false"
        class="lines-animation"
        [ngClass]="{
          'lines-vertical-spacing-inner': (selectedBudgetType$ | async) !== SPEND_TYPES.BUDGET
        }"
      >
        <app-budget-line-item
          class="form column lines-wrapper w-fit 2xl:w-auto"
          [item]="item"
          [minStartDate]="projectStartDate"
          [canDelete]="
            (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET &&
            items.length > 1 &&
            !(hasCommitments$ | async) &&
            !userService.isGeneralContractor
          "
        ></app-budget-line-item>

        @if (
          ((selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_PARENT_CHILD ||
            (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS_GC) &&
          item.subitems?.length > 0
        ) {
          <div
            cdkDropListOrientation="vertical"
            cdkDropListLockAxis="y"
            cdkDropList
            class="pb-0 lines-vertical-spacing"
            [ngClass]="{
              'cursor-grab': isDragging
            }"
            (cdkDropListDropped)="dropSubitem($event, item)"
          >
            @for (subitem of item.subitems; track subitem.id) {
              <div cdkDrag class="lines-vertical-spacing-inner lines-animation">
                <app-budget-line-item
                  class="form column lines-wrapper w-fit 2xl:w-auto"
                  [item]="subitem"
                  [minStartDate]="projectStartDate"
                  [canDelete]="false"
                  [isSubItem]="true"
                ></app-budget-line-item>

                @if (
                  (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS_GC &&
                  subitem?.committed_items?.length > 0
                ) {
                  <div
                    cdkDropListOrientation="vertical"
                    cdkDropListLockAxis="y"
                    cdkDropList
                    class="lines-vertical-spacing-inner"
                    [ngClass]="{
                      'cursor-grab': isDragging
                    }"
                    (cdkDropListDropped)="dropCommittedItem($event, item)"
                  >
                    @for (commitedItem of subitem.committed_items; track commitedItem.id) {
                      <app-committed-line-item
                        class="form column lines-wrapper lines-animation w-fit 2xl:w-auto"
                        cdkDrag
                        (cdkDragStarted)="isDragging = true"
                        (cdkDragEnded)="isDragging = false"
                        [cdkDragDisabled]="commitedItem.type === 'forecast_modification'"
                        [committedItem]="commitedItem"
                        [lineItem]="item"
                        [minStartDate]="projectStartDate"
                      ></app-committed-line-item>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }

        @if ((selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS) {
          <div
            cdkDropListOrientation="vertical"
            cdkDropListLockAxis="y"
            cdkDropList
            class="lines-vertical-spacing"
            [ngClass]="{
              'cursor-grab': isDragging
            }"
            (cdkDropListDropped)="dropCommittedItem($event, item)"
          >
            @for (commitedItem of item.committed_items; track commitedItem.id) {
              <app-committed-line-item
                class="form column lines-wrapper lines-animation"
                cdkDrag
                (cdkDragStarted)="isDragging = true"
                (cdkDragEnded)="isDragging = false"
                [cdkDragDisabled]="commitedItem.type === 'forecast_modification'"
                [committedItem]="commitedItem"
                [lineItem]="item"
                [minStartDate]="projectStartDate"
              ></app-committed-line-item>
            }
          </div>
        }
      </div>
    }
  } @else if ((selectedBudgetType$ | async) === SPEND_TYPES.COMMITMENTS) {
    <div
      cdkDropListOrientation="vertical"
      cdkDropListLockAxis="y"
      cdkDropList
      cdkScrollable
      class="lines-vertical-spacing"
      [ngClass]="{
        'cursor-grab': isDragging
      }"
      (cdkDropListDropped)="dropCommittedItemOnly($event)"
    >
      @for (commitedItem of committedItems; track commitedItem.id) {
        <app-committed-line-item
          class="form column lines-wrapper lines-animation"
          cdkDrag
          [cdkDragDisabled]="commitedItem.type === 'forecast_modification'"
          (cdkDragStarted)="isDragging = true"
          (cdkDragEnded)="isDragging = false"
          [committedItem]="commitedItem"
          [lineItem]="commitedItem.lineItem"
          [minStartDate]="projectStartDate"
        ></app-committed-line-item>
      }
    </div>
  } @else if ((selectedBudgetType$ | async) === SPEND_TYPES.PRIME_CONTRACT) {
    <div
      cdkDropListOrientation="vertical"
      cdkDropListLockAxis="y"
      cdkDropList
      cdkScrollable
      class="lines-vertical-spacing"
      [ngClass]="{
        'cursor-grab': isDragging
      }"
      (cdkDropListDropped)="dropLineItem($event)"
    >
      @for (primeLine of primeLines; track primeLine.id) {
        <app-prime-line-item
          class="form column lines-wrapper lines-animation"
          cdkDrag
          (cdkDragStarted)="isDragging = true"
          (cdkDragEnded)="isDragging = false"
          [item]="primeLine"
          [minStartDate]="projectStartDate"
          [canDelete]="false"
        ></app-prime-line-item>
      }
    </div>
  }

  <app-budget-line-item-totals
    [hideTotals]="
      !items.length ||
      (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS ||
      (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS_GC
    "
    [selectedBudgetType]="selectedBudgetType$ | async"
  ></app-budget-line-item-totals>
</div>
