<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex my-2.5 mx-7 flex-col flex-1">
    <app-dropdown
      [optionLabel]="null"
      [optionValue]="null"
      [(model)]="model.defaultInvoiceRetainageRate"
      placeholder="Invoice Retainage Rate %"
      label="Invoice Retainage Rate %"
      name="invoice_retainage_rate"
      class="w-full"
      [options]="invoiceRetainageRates"
    >
      <ng-template #selectedContent #optionContent let-option>
        <div class="capitalize text-color_primary text-medium">{{ option }} %</div>
      </ng-template>
    </app-dropdown>
    <form class="h-full" #retainageSettings="ngForm" role="form">
      @for (exception of model.exceptions; track index; let index = $index) {
        <div class="flex items-center gap-2">
          <app-dropdown
            required
            [optionLabel]="null"
            [optionValue]="'description_id'"
            [(model)]="exception.description_id"
            [placeholder]="'Schedule Of Value ' + (index + 1)"
            [label]="'Schedule Of Value ' + (index + 1)"
            [name]="'schedule_of_value_' + index"
            class="w-full"
            [options]="allScheduleOfValues"
          >
            <ng-template #selectedContent #optionContent let-option>
              <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
            </ng-template>
          </app-dropdown>

          <app-floating-input
            required
            [(model)]="exception.rate"
            [name]="'invoice_retention_rate_' + index"
            label="Rate %"
            placeholder="Rate %"
            type="number"
            class="w-full"
          ></app-floating-input>

          <app-add-remove-buttons
            class="mt-3.5"
            (removeItem)="removeItem(index)"
            (addItem)="addItem(index)"
            [disabledAdd]="model.exceptions.length === allScheduleOfValues.length"
          >
          </app-add-remove-buttons>
        </div>
      } @empty {
        <app-simple-button
          [wrapperClass]="{ 'w-full': true }"
          size="large"
          buttonStyle="color_secondary_border"
          (click)="addItem(0)"
          >Add Retainage Exception</app-simple-button
        >
      }
    </form>
  </div>
</ng-scrollbar>

<div class="flex my-6 mx-7 justify-end gap-4">
  <app-simple-button (click)="submitForm()" buttonStyle="color_accent_filled">{{
    model.id ? 'Update' : 'Submit'
  }}</app-simple-button>
</div>
