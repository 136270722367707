import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { FormsModule, NgForm } from '@angular/forms';
import { SimpleButtonComponent } from '../../../../buttons/simple-medium-button/simple-button.component';
import { BackButtonBoxComponent } from '../../../../back-button-box/back-button-box.component';
import {
  ExistingScopeLineItem,
  NewScopeLineItem,
  PCOScope,
  PrimeChangesLineItem,
} from '@app/store/prime-commitments/prime-commitments.types';
import { getEmptyPotentialChangeLineItem } from '@app/store/prime-commitments/prime-commitments.constants';
import { PcoNewScopeEntryComponent } from '@app/framework/interaction-bar/prime-changes/prime-potential-changes/potential-change-line-items/pco-new-scope-entry/pco-new-scope-entry.component';
import { PcoExistingScopeEntryComponent } from '@app/framework/interaction-bar/prime-changes/prime-potential-changes/potential-change-line-items/pco-existing-scope-entry/pco-existing-scope-entry.component';

@Component({
  selector: 'app-potential-change-order-sidebar',
  standalone: true,
  imports: [
    SimpleButtonComponent,
    NgScrollbar,
    FormsModule,
    BackButtonBoxComponent,
    PcoNewScopeEntryComponent,
    PcoExistingScopeEntryComponent,
  ],
  templateUrl: './potential-change-order-sidebar.component.html',
  styleUrl: './potential-change-order-sidebar.component.scss',
})
export class PotentialChangeOrderSidebarComponent {
  @ViewChild('lineItemsForm') form: NgForm;

  @Input() lineItems: (ExistingScopeLineItem | NewScopeLineItem)[] = [];
  @Input({ required: true }) name: string;
  @Input({ required: true }) scope: PCOScope;
  @Output() lineItemsChange = new EventEmitter<PrimeChangesLineItem[]>();
  @Output() back = new EventEmitter<void>();
  @Output() saveForm = new EventEmitter<void>();

  addEntry() {
    this.lineItems.push({
      ...getEmptyPotentialChangeLineItem(),
    });
    if (this.scope === 'new') {
      (this.lineItems[this.lineItems.length - 1] as NewScopeLineItem).descriptions = [''];
    } else {
      (this.lineItems[this.lineItems.length - 1] as ExistingScopeLineItem).costs = [
        { name: '', id: null },
      ];
    }
    this.lineItemsChange.emit(this.lineItems);
  }

  goBack() {
    this.back.emit();
  }

  submitForm() {
    this.form.form.markAllAsTouched();
    if (this.form.form.invalid) {
      return;
    }
    this.saveForm.emit();
  }

  deleteLineItem(index: number) {
    this.lineItems.splice(index, 1);
  }

  lineItemUpdated($event: PrimeChangesLineItem, index: number) {
    this.lineItems[index] = $event;
    this.lineItemsChange.emit(this.lineItems);
  }
}
