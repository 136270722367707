import { CONTRACTOR_TYPE } from './user.constants';

export enum PROJECT_VIEWS {
  DEFAULT,
  COMMITMENTS,
  PROJECT_SPEND,
  CASHFLOW,
  DOCUMENTS,
  GANTT_SCHEDULE,
  // SUMMARY,
  PROGRESS_TRACKING,
  BUDGET_ALLOCATION,
  BUDGET_HISTORY,
  PROJECT_UPDATES,
  RFI,
  POTENTIAL_CHANGE_ORDERS,
  POTENTIAL_CHANGE_ORDER_REQUESTS,
}

export enum PROJECT_VIEWS_CONTRACTOR {
  COMMITMENTS = PROJECT_VIEWS.COMMITMENTS,
  DOCUMENTS = PROJECT_VIEWS.DOCUMENTS,
  GANTT_SCHEDULE = PROJECT_VIEWS.GANTT_SCHEDULE,
  // SUMMARY = PROJECT_VIEWS.SUMMARY,
}

export interface ViewProjectNavOption {
  value: PROJECT_VIEWS;
  text: string;
}

export const ManagerFinancialViews: ViewProjectNavOption[] = [
  { value: PROJECT_VIEWS.PROJECT_SPEND, text: 'Project Spend' },
  { value: PROJECT_VIEWS.CASHFLOW, text: 'Cashflow' },
  { value: PROJECT_VIEWS.COMMITMENTS, text: 'Commitments' },
];

export const ServiceProviderFinancialViews: ViewProjectNavOption[] = [
  { value: PROJECT_VIEWS.PROJECT_SPEND, text: 'Project Spend' },
  { value: PROJECT_VIEWS.CASHFLOW, text: 'Cashflow' },
  { value: PROJECT_VIEWS.COMMITMENTS, text: 'Commitments' },
  { value: PROJECT_VIEWS.POTENTIAL_CHANGE_ORDERS, text: 'PCOs' },
  { value: PROJECT_VIEWS.POTENTIAL_CHANGE_ORDER_REQUESTS, text: 'CORs' },
];

export const trackingViews: ViewProjectNavOption[] = [
  { value: PROJECT_VIEWS.PROGRESS_TRACKING, text: 'Progress' },
  { value: PROJECT_VIEWS.GANTT_SCHEDULE, text: 'Schedule' },
  { value: PROJECT_VIEWS.PROJECT_UPDATES, text: 'Updates' },
  { value: PROJECT_VIEWS.RFI, text: 'RFIs' },
];

export enum MANAGE_PROJECT_TABS {
  SERVICE_TEAM = 'SERVICE_TEAM',
  COMMITMENTS = 'COMMITMENTS',
  PRIME_CONTRACT = 'PRIME_CONTRACT',
  BUDGET_CASHFLOW = 'BUDGET_CASHFLOW',
  PROPOSAL = 'PROPOSAL',
  CONTACTS = 'CONTACTS',
}

export const contractorTypes: { id: CONTRACTOR_TYPE; text: string }[] = [
  { id: CONTRACTOR_TYPE.CONTRACTOR, text: 'Contractors' },
  { id: CONTRACTOR_TYPE.ARCHITECT, text: 'Architects' },
  { id: CONTRACTOR_TYPE.SUBCONTRACTOR, text: 'Subcontractors' },
  { id: CONTRACTOR_TYPE.INSPECTOR, text: 'Inspectors' },
  { id: CONTRACTOR_TYPE.ENGINEER, text: 'Engineers' },
  { id: CONTRACTOR_TYPE.MATERIAL_SUPPLIER, text: 'Material Suppliers' },
  { id: CONTRACTOR_TYPE.CONSULTANT, text: 'Consultants' },
];

export enum VIEW_PROJECT_REFRESH_CAUSE {
  INTERACTION_BAR_CLOSED = 'INTERACTION_BAR_CLOSED',
  COMMITMENTS_UPDATED = 'COMMITMENTS_UPDATED',
  SPEND_SET_ALL = 'SPEND_SET_ALL',
  SPEND_SAVE = 'SPEND_SAVE',
}
