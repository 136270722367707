<div
  class="upload-drop-area rich-text-edit-upload"
  appUploadDrop
  [disableAppUploadDrop]="!allowFileUpload"
  (fileDropped)="emitUploadFiles($event)"
>
  <div class="rte-container">
    <ng-scrollbar class="std-scrollbar editor-scroll-container" orientation="vertical" appearance="native">
      <tiptap-editor
        class="editor"
        [class.editor-mode]="theme === 'editor'"
        [editor]="editor"
        [ngModel]="_editorValue"
        (ngModelChange)="emitEditorValueChange($event)"
      ></tiptap-editor>
    </ng-scrollbar>

    <div class="editor-menu h-8" [class.editor-mode]="theme === 'editor'">
      <div
        aria-hidden="true"
        class="icon btn icon-bold pointer-cursor"
        (click)="editor.chain().focus().toggleBold().run()"
        [class.icon-active]="editor.isActive('bold')"
        matTooltip="Insert Bold"
        matTooltipShowDelay="1000"
      ></div>

      <div
        aria-hidden="true"
        class="icon btn icon-italic pointer-cursor"
        (click)="editor.chain().focus().toggleItalic().run()"
        [class.icon-active]="editor.isActive('italic')"
        matTooltip="Insert Italic"
        matTooltipShowDelay="1000"
      ></div>

      <div
        aria-hidden="true"
        class="icon btn icon-underline pointer-cursor"
        (click)="editor.chain().focus().toggleUnderline().run()"
        [class.icon-active]="editor.isActive('underline')"
        matTooltip="Insert Underline"
        matTooltipShowDelay="1000"
      ></div>

      <div class="ml-3 flex items-center">
        @if (showLinkInput) {
          <app-placeholder-input
            (keyDown.enter)="confirmLink()"
            class="relative !w-56 !h-8 !pr-9"
            [(model)]="linkInputValue"
            name="link"
            placeholder="URL"
          >
            <div icon class="absolute top-1/2 -translate-y-1/2 right-1 flex items-center justify-center">
              <div
                aria-hidden="true"
                class="icon-approved text-color_light_green_3 cursor-pointer"
                (click)="confirmLink()"
              ></div>
              <div
                aria-hidden="true"
                class="icon-remove-circle-x text-color_red_negative cursor-pointer"
                (click)="discardLink()"
              ></div>
            </div>
          </app-placeholder-input>
        } @else {
          <div
            aria-hidden="true"
            class="icon btn icon-link pointer-cursor"
            (click)="openLinkInput()"
            [class.icon-active]="editor.isActive('link')"
            matTooltip="Insert Link"
            matTooltipShowDelay="1000"
          ></div>
        }
      </div>

      <app-upload-window
        *ngIf="allowFileUpload"
        #uploadWindow
        (chosenFiles)="emitUploadFiles($event)"
        [allowMultipleFiles]="true"
        class="ml-3 relative pointer-cursor"
      >
        <div
          matTooltip="Upload Files"
          matTooltipShowDelay="1000"
          (click)="uploadWindow.openWindow()"
          aria-hidden="true"
          class="icon btn icon-upload-square"
        ></div>
      </app-upload-window>
    </div>
  </div>

  <div *ngIf="filesToUpload.length" class="upload-container">
    <ng-scrollbar class="std-scrollbar" orientation="vertical" appearance="native">
      <div *ngFor="let fileToUpload of filesToUpload; let index = index" class="upload-item">
        <div class="text-shade_1 flex-1 ml-1.5">
          {{ fileToUpload.file.name | removeFileExtension | shortenFileName: 18 }}
        </div>
        <div class="relative w-7 h-full">
          <i
            aria-hidden="true"
            (click)="emitRemoveFileToUpload(fileToUpload, index)"
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer text-color_secondary icon-minus-box"
          ></i>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
