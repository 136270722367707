<div class="flex flex-col px-7 py-8 gap-2.5">
  <app-upload-window
    #uploadWindowComponent
    [disabled]="true"
    (chosenFiles)="registerFileUpload($event)"
    [hideUploadIcon]="true"
    [acceptFileTypes]="'.xlsx'"
  >
    <div class="border border-shade_5 rounded border-solid p-3 mt-0.5">
      <span
        [ngClass]="{ 'p-disabled': file }"
        (click)="uploadWindowComponent.hiddenFileInput.openFileUploadWindow()"
        class="text-color_secondary flex items-center gap-1.5 pointer-cursor"
      >
        <span class="text-xl icon-folded-file-upload flex items-center"></span>
        <span class="italic text-color_secondary font-bold text-size_l"> Click to upload </span>
      </span>
      @if (file) {
        <span class="flex items-center gap-5 pointer-cursor">
          <span class="text-color_secondary my-1.5 flex-1">
            {{ file.name | shortenFileName }}
          </span>
          <span
            (click)="removeFile()"
            class="icon-remove-circle-x text-size_icon text-color_secondary hover:text-color_accent"
          ></span>
        </span>
      }
    </div>
  </app-upload-window>
  <span (click)="downloadXLSX()" class="text-color_secondary font-semibold pl-1 text-sm cursor-pointer">
    Download XLS Template</span
  >
</div>
