import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { BorderedDropdownIconComponent } from '@app/framework/bordered-dropdown-icon/bordered-dropdown-icon.component';

@Component({
  selector: 'app-sidebar-header',
  standalone: true,
  imports: [NgClass, BorderedDropdownIconComponent],
  templateUrl: './sidebar-header.component.html',
  styleUrl: './sidebar-header.component.scss',
})
export class SidebarHeaderComponent {
  @Input() showBackButton!: boolean;
  @Output() backButtonClicked = new EventEmitter<void>();
}
