<app-commitment-sidebar-title
  [showBackButton]="true"
  [title]="model.id ? 'Edit Prime Contract' : 'Add Prime Contract'"
  (goBack)="goBack()"
>
  <div (click)="addValuesFromBudget()" class="cursor-pointer text-color_secondary flex gap-2 items-center">
    <span>Create From Budget</span>
    <span
      class="icon-download_budget text-size_icon_bigger"
      pTooltip="Copy the budget dollars respective to each budget line from the cashflow allocation."
    ></span>
  </div>
</app-commitment-sidebar-title>
<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex flex-col flex-1">
    <form #spendEntriesPage="ngForm" role="form">
      @for (
        spendEntry of model.spendEntries;
        track spendEntry.id + '_' + spendEntryIndex;
        let spendEntryIndex = $index
      ) {
        <app-commitment-entry-text
          (removeClicked)="removeEntry(spendEntryIndex)"
          entryType="General"
          [index]="spendEntryIndex"
          [text]="'Spend Entry'"
        ></app-commitment-entry-text>
        <div class="flex flex-col my-2.5 mx-7">
          <app-dropdown
            [optionLabel]="null"
            [optionValue]="'id'"
            [model]="spendEntry.item_id"
            (modelChange)="onLineItemChange($event, spendEntryIndex)"
            [required]="true"
            placeholder="Line Item"
            label="Line Item"
            [name]="'line_item_' + spendEntryIndex"
            [appendTo]="'body'"
            class="w-full"
            [options]="allLineItems$ | async"
          >
            <ng-template #selectedContent #optionContent let-option>
              <div class="flex flex-col">
                <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
                <div class="capitalize text-color_primary font-normal">
                  {{ option?.division }} {{ option?.cost_type }}
                </div>
              </div>
            </ng-template>
          </app-dropdown>

          @for (
            spendDescription of spendEntry.spend_descriptions;
            track spendDescription.id + '_' + descriptionIndex;
            let descriptionIndex = $index
          ) {
            <div class="flex items-center gap-2">
              <app-floating-input
                required
                class="w-full overflow-hidden"
                [(model)]="spendDescription.description"
                [name]="'description_' + spendEntryIndex + '_' + descriptionIndex"
                [label]="'Schedule of value ' + (descriptionIndex + 1)"
                [placeholder]="'Schedule of value' + (descriptionIndex + 1)"
              ></app-floating-input>

              <app-floating-input
                required
                class="w-full"
                [(model)]="spendDescription.value"
                [name]="'value_' + spendEntryIndex + '_' + descriptionIndex"
                label="Dollar Amount"
                placeholder="Dollar Amount"
                [formatMoney]="true"
              ></app-floating-input>

              <app-add-remove-buttons
                class="mt-4"
                [disabledRemove]="spendEntry.spend_descriptions.length === 1"
                (removeItem)="removeSpendDescription(spendEntryIndex, descriptionIndex)"
                (addItem)="addSpendDescription(spendEntryIndex, descriptionIndex)"
              >
              </app-add-remove-buttons>
            </div>
          }
        </div>
      }
    </form>
  </div>

  <app-simple-button
    class="flex px-7 my-2.5"
    size="large"
    [wrapperClass]="{ 'w-full': true }"
    buttonStyle="color_secondary_border"
    (click)="addEmptySpendEntry()"
    >Add Spend Entry</app-simple-button
  >
</ng-scrollbar>

<div class="mt-auto border-b border-b-solid border-shade_5"></div>

<div class="my-6 mx-7 flex justify-between items-center gap-4">
  <div class="text-color_primary text-sm font-bold">Total Contract</div>
  <div class="text-color_primary text-sm font-bold">
    {{ totals | money: 'comaSign' }}
  </div>
  <app-simple-button (click)="goToNextPage()"> Next </app-simple-button>
</div>
