import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { NotificationsService } from '../../../services/notifications.service';
import {
  DateCustomPipe,
  NO_TIMEZONE_DATE,
  UTC_DATE,
} from '../../../pipes/framework/date-custom.pipe';
import {
  areDocsLoaded,
  getSelectedFolder,
  getSharedBy,
} from '../../../store/documents/documents.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-state';
import {
  back,
  clearAll,
  driveExternalFolderClicked,
  driveFolderClicked,
  reload,
  reloadDriveFolder,
  reloadExternalDrive,
} from '../../../store/documents/documents.actions';
import { REST_DRIVE_FILES, REST_DRIVE_VIEW_EXTERNAL_DRIVE } from '../../../restApi/RestRoutes';
import { OPTIONS } from '../../../framework/constants/options-list.constants';
import { DriveApiService } from '../../../services/drive-api.service';
import { DocumentItem } from '../../../store/documents/documents.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { NOTIFICATION_TYPE } from '../../../framework/constants/notification.constants';
import { SheetComponent } from './sheet/sheet.component';
import { DocumentsEffects } from '../../../store/documents/documents.effects';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { DocumentComponent } from './document/document.component';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import {
  backProjDoc,
  projectFolderClicked,
  reloadProjDocs,
  reloadProjectFolder,
  reset,
} from '../../../store/projectDocuments/projectDocuments.actions';
import {
  areProjDocsLoaded,
  getProjectDocsSelectedFolder,
  getSelectedProjectId,
} from '../../../store/projectDocuments/projectDocuments.selectors';
import { CurrentUserService } from '../../../services/current-user.service';
import {
  allItemOptions,
  DRIVE_VIEWS,
  DriveNotification,
  EXCELL_MIME,
  PERMISSION_LITERALS,
  WORD_MIME,
} from '../../../framework/constants/documents.constants';
import { DriveService } from '../../../services/drive.service';
import {
  catchError,
  delay,
  first,
  skip,
  skipWhile,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { OVERLAY_TYPES, OverlayOptionsPipe } from '../../../pipes/framework/overlay-options.pipe';
import { BottomNotificationService } from '../../../services/bottom-notification.service';
import { PERMISSION_TYPES } from '../../../pipes/framework/drivePermissions.pipe';
import { AddProjectBase } from '../projects/add-project/AddProjectBase';
import {
  IMetaData,
  PresignedFileUploadService,
} from '../../../services/presigned-file-upload.service';
import { UploadWindowComponent } from '../../../framework/upload/upload-window/upload-window.component';
import {
  driveHeaderOptionOverlayPositions,
  driveOptionOverlayPositions,
} from '../../../framework/overlays/option-list.constants';
import {
  CUSTOM_OVERLAY_VIEWS,
  CustomOverlayService,
} from '../../../services/custom-overlay.service';
import {
  Directory,
  UploadableContent,
  UploadDropDirective,
} from '../../../directives/upload-drop.directive';
import { BytesPipe } from '../../../pipes/framework/bytes.pipe';
import { UncommonPermissionsPipe } from '../../../pipes/framework/uncommon-permissions.pipe';
import { PermissionToTextPipe } from '../../../pipes/framework/permission-to-text.pipe';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { DriveEmptyAreaComponent } from '../../../framework/drive-empty-area/drive-empty-area.component';
import { FadedTextComponent } from '../../../framework/faded-text/faded-text.component';
import { TooltipModule } from 'primeng/tooltip';
import { StyledOptionsListComponent } from '../../../framework/overlays/styled-options-list/styled-options-list.component';
import { FloatingInputComponent } from '../../../framework/inputs/floating-input/floating-input.component';
import { Checkbox, CheckboxModule } from 'primeng/checkbox';
import { NgScrollbar } from 'ngx-scrollbar';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { BottomNotificationComponent } from '../../../framework/bottom-notification/bottom-notification.component';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.scss'],
  standalone: true,
  imports: [
    BottomNotificationComponent,
    NgClass,
    NgIf,
    NgScrollbar,
    UploadDropDirective,
    CheckboxModule,
    FormsModule,
    FloatingInputComponent,
    ReactiveFormsModule,
    StyledOptionsListComponent,
    UploadWindowComponent,
    TooltipModule,
    NgFor,
    FadedTextComponent,
    DriveEmptyAreaComponent,
    DocumentComponent,
    SheetComponent,
    MatFormField,
    MatInput,
    AsyncPipe,
    DecimalPipe,
    PermissionToTextPipe,
    UncommonPermissionsPipe,
    DateCustomPipe,
    BytesPipe,
    OverlayOptionsPipe,
  ],
})
export class DriveComponent extends AddProjectBase implements OnInit, AfterViewInit, OnDestroy {
  static isProjectView = new BehaviorSubject(false);
  @ViewChildren('documentsCheckboxes') documentsCheckboxes: Checkbox[];
  @ViewChild('selectAllDocumentCheckBox') selectAllDocumentCheckBox: Checkbox;
  @ViewChildren('documents') documents;
  @ViewChildren('fileNameInput') fileNameInput;
  @ViewChildren('docs') docs;
  @ViewChild('uploadWindowComponent') uploadWindow: UploadWindowComponent;
  @ViewChild('sheet') sheet: SheetComponent;
  @ViewChild('doc') word: DocumentComponent;
  @ViewChild('floatingInputComponent') folderNameInput: FloatingInputComponent;

  allDocsCheckbox: boolean = false;

  areDocsLoaded = this.store.select(areDocsLoaded);
  isReset = this.store.select(reset);
  folderName = new UntypedFormControl('', []);
  regexExtension = /(?:\.([^.]+))?$/;
  selectedFolder: Observable<DocumentItem>;
  _selectedFiles: DocumentItem[] = [];
  _selectedFilesId: number[] = [];
  get selectedFiles() {
    return this._selectedFiles;
  }

  set selectedFiles(files: DocumentItem[]) {
    this._selectedFiles = files;
    this._selectedFilesId = files.map((file) => file.id);
  }
  currentDocuments: DocumentItem[] = [];
  NO_TIMEZONE_DATE = NO_TIMEZONE_DATE;
  UTC_DATE = UTC_DATE;
  REST_DRIVE_FILES = REST_DRIVE_FILES;
  DRIVE_VIEWS = DRIVE_VIEWS;
  currentFolder: DocumentItem = null;
  PERMISSION_LITERALS = PERMISSION_LITERALS;
  notification: DriveNotification = {
    message: '',
    type: NOTIFICATION_TYPE.CLOSE,
  };
  uploadMetaData: Partial<IMetaData>;
  isPublicLinkAccessed = false;
  uuid: string | null = null;

  selectedView = new BehaviorSubject<DRIVE_VIEWS>(DRIVE_VIEWS.DEFAULT);
  externalUploadEndpoint = null;

  filenameForm = this.formBuilder.group({
    fileName: '',
  });

  documentFileData: ArrayBuffer; // can contain a word or a spreadsheet file's data
  FOLDER_NAME_PLACEHOLDER = 'New Folder';
  folderNamePlaceholder = this.FOLDER_NAME_PLACEHOLDER;
  private isRenaming = false;

  isProjectView = false;
  projectId: number;
  isManagerLike = false;
  sharedBy$;

  subscriptions: Subscription = new Subscription();
  isDestroyed$ = new Subject<void>();
  OVERLAY_TYPES = OVERLAY_TYPES;
  PERMISSION_TYPES = PERMISSION_TYPES;
  OPTIONS = OPTIONS;
  driveOptionsList = allItemOptions.filter((option) => option.value !== OPTIONS.EDIT);

  protected optionOverlayPositions = driveOptionOverlayPositions;
  protected driveHeaderOptionOverlayPositions = driveHeaderOptionOverlayPositions;

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
    private driveApi: DriveApiService,
    public driveService: DriveService,
    private nav: Router,
    private formBuilder: UntypedFormBuilder,
    private docEffects: DocumentsEffects,
    private barState: InteractionBarStateService,
    public userService: CurrentUserService,
    private activatedRoute: ActivatedRoute,
    public bottomNotif: BottomNotificationService,
    private cdr: ChangeDetectorRef,
    private presignedFileUploadService: PresignedFileUploadService,
    private overlayService: CustomOverlayService,
    private overlayOptionsPipe: OverlayOptionsPipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkRoute();
    this.isManagerLike = this.userService.isManagerLike;
    this.initSubscriptions();
  }

  /**
   * handles the 'popstate' event -- calls custom back function to a folder
   * @param event
   */
  browserBackButtonHandler = (event) => {
    const canGoBack = this.isProjectView
      ? this.driveService.folderPath.length > 1
      : this.driveService.folderPath.length > 0;
    if (canGoBack) {
      const folderIndex = this.driveService.folderPath.length - 2;
      this.goBack(folderIndex);
    } else {
      // to prevent double back navigation
      // this.addItemToBrowserHistory(); // currently not used as we need to let users to navigate back
    }
  };
  ngAfterViewInit() {
    window.onpopstate = this.browserBackButtonHandler;
  }

  /**
   * adds an item to the global history variable (needs to be called when folder clicked)
   * @private
   */
  private addItemToBrowserHistory() {
    history.pushState({}, '', location.href);
  }

  discardChanges() {
    this.selectedView.next(DRIVE_VIEWS.DEFAULT);
    this.documentFileData = null;
    this.filenameForm.controls.fileName.setValue('');
    delete this.uploadMetaData.user_drive_file_id;
  }

  /**
   * saves a Word or an Excel file.
   */
  async saveDocument() {
    if (!this.filenameForm.value.fileName || this.filenameForm.value.fileName.length === 0) {
      this.notif.showError(`File name can't be empty`);
      return;
    }
    let mimeType;
    let extension;
    let component: SheetComponent | DocumentComponent;
    if (this.selectedView.value === DRIVE_VIEWS.EXCEL) {
      mimeType = EXCELL_MIME;
      extension = 'xlsx';
      component = this.sheet;
      component.downloadOnSave = false;
    } else if (this.selectedView.value === DRIVE_VIEWS.WORD) {
      mimeType = WORD_MIME;
      extension = 'docx';
      component = this.word;
    }

    component
      .getFile()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((blob) => {
        const fileName = this.filenameForm.get('fileName').value;
        this.selectedView.next(DRIVE_VIEWS.DEFAULT);
        this.cdr.detectChanges(); // this is important, otherwise this.uploadButton is undefined
        const file = new File([blob], `${fileName}.${extension}`, {
          type: mimeType,
        });
        this.presignedFileUploadService
          .uploadFileToS3WithNotifs(file, {
            ...this.uploadMetaData,
            name: file.name,
            size: file.size,
            type: file.type,
          })
          .subscribe({
            next: (uploadData) => {
              // be aware my friend, hacky code ahead
              if (uploadData.file_id) {
                // wait for the upload to finish, only then call the reload
                this.reload();
              }
            },
            error: (err) => {
              console.warn(err);
              this.notif.showError('An error occurred during the upload.');
            },
          });
        // this.uploadButton.uploadFile(file);
        this.filenameForm.controls.fileName.setValue('');
        delete this.uploadMetaData.user_drive_file_id;
        this.filenameForm.reset();
      });
  }

  setDriveView(view: DRIVE_VIEWS) {
    this.selectedView.next(view);
  }

  // todo: might be a good idea to move this to the state
  goBack(folderIndex) {
    const backCount = this.driveService.folderPath.length - folderIndex - 1;
    for (let i = 0; i < backCount; i++) {
      this.driveService.folderPath.pop();
      if (this.isProjectView) {
        this.store.dispatch(backProjDoc());
      } else {
        this.store.dispatch(back());
      }
    }
  }

  getType(item: any): string {
    if (this.driveService.isFolder(item)) {
      return 'Folder';
    }
    return this.regexExtension.exec(item.name)[1]
      ? '.' + this.regexExtension.exec(item.name)[1]
      : '';
  }

  // todo: move this to the state....
  getArray(folders, files): DocumentItem[] {
    return [...(folders ?? []), ...(files ?? [])];
  }

  // todo: move this to the state....
  getFolderId() {
    // remove this #$@!#$@!$#$#@
    if (this.currentFolder) {
      return this.currentFolder?.id;
    } else {
      return 0;
    }
  }

  setCurrentDocuments() {
    this.currentDocuments = [];
    this.currentDocuments.push(...this.currentFolder.children, ...(this.currentFolder.files ?? []));
  }

  // todo: move this to the state....
  setCurrentPath() {
    if (this.currentFolder?.is_drive) {
      this.driveService.folderPath = [];
    } else {
      if (!this.driveService.folderPath.find((o) => o.id === this.currentFolder?.id)) {
        this.driveService.folderPath.push({
          name: this.currentFolder?.name,
          id: this.currentFolder?.id,
        });
      }
    }
  }

  openDocument(item: DocumentItem) {
    if (this.isRenaming) {
      return;
    }

    if (this.driveService.isFolder(item)) {
      this.folderClicked(item);
    } else {
      this.openFile(item);
    }
  }

  openFile(file: DocumentItem) {
    this.notif.showLoading();
    this.registerOption(OPTIONS.OPEN, file);
  }

  folderClicked(item) {
    // needs to be used in case a folder is selected and black magic happens
    this.addItemToBrowserHistory();
    if (this.isProjectView) {
      this.store.dispatch(projectFolderClicked({ folderId: item.id }));
    } else {
      this.store.dispatch(
        this.isPublicLinkAccessed
          ? driveExternalFolderClicked({ id: item.id, uuid: this.uuid })
          : driveFolderClicked({ id: item.id }),
      );
    }
  }

  /**
   * Called when files uploaded by button press <br/>
   * @param event
   */
  registerFileUpload(files: File[]) {
    const metadata = files.map((_) => this.uploadMetaData);
    this.presignedFileUploadService.uploadMultipleFilesToS3(files, metadata, this.uuid).subscribe(
      (fileStatuses) => {
        if (fileStatuses.filter((status) => !!status?.error).length > 0) {
          // this.notif.showError('An error occurred during the upload.');
          setTimeout(() => {
            this.reload();
          }, 2000);
        } else {
          this.reload();
        }
      },
      (err) => {
        this.notif.showError('An error occurred during the upload.');
        console.warn(err);
        this.reload();
      },
    );
  }

  uploadMultipleFiles(files: File[], metadata: Partial<IMetaData>[]) {
    this.presignedFileUploadService
      .uploadMultipleFilesToS3(files, metadata, this.uuid, true)
      .subscribe(
        (fileStatuses) => {
          const totals = this.presignedFileUploadService.getTotals();

          if (totals.completed) {
            console.log('completed', totals);
            this.presignedFileUploadService.resetUploadState();

            if (totals.completedWithError) {
              let message = `Upload finished with errors: ${totals.errorCount} failed`;
              if (totals.uploadedCount >= 1) {
                message += ` but ${totals.uploadedCount} uploaded successfully.`;
              }
              this.notif.showError(message);
            } else if (totals.uploadedCount > 0) {
              // just to be sure, sometimes it outputs 'successfully uploaded 0 files'
              this.notif.showError(`Successfully uploaded ${totals.uploadedCount} files.`);
            }

            // setTimeout is needed to show the success message for enough time
            setTimeout(() => {
              this.reload();
            }, 3000);
          }
        },
        (err) => {
          // maybe unreachable code because the forkJoin won't emit an error because of the catchError operator
          console.warn(err);
        },
      );
  }

  /**
   * Called by the drop directive when files and/or folders are dropped <br/>
   * It counts the total number and size of files and calls the recursive function traverseFolder.
   */
  async onFilesDropped(uploadableContent: UploadableContent[]) {
    if (!this.currentFolder?.permissions?.[this.PERMISSION_LITERALS.CAN_MODIFY_CONTENT]) {
      this.notif.showError('Uploading here is forbidden!');
      return;
    }

    this.notif.showLoading();

    const toFolderId = this.currentFolder.id; // start from the current folder
    const size = uploadableContent.reduce((acc, curr) => acc + curr.size, 0);
    const fileCount = uploadableContent.reduce((acc, curr) => {
      if ((curr as Directory)?.children) {
        // if directory, return its file count
        return acc + (curr as Directory)?.totalFileCount;
      }

      // if file, just increment the count
      return acc + 1;
    }, 0);

    // important step, set the total number and size of files to be uploaded
    this.presignedFileUploadService.updateConstantTotals(size, fileCount);

    await this.traverseFolder(uploadableContent, toFolderId);
  }

  /**
   * Recursive function called first when files are dropped. <br/>
   * It navigates through the structure of the dropped files and folders and
   * Calls the all of the requests needed to create folders and upload files.
   */
  private async traverseFolder(uploadableContent: UploadableContent[], toFolderId) {
    // at the first call this.currentFolder is null, so we need to set it to the current folder
    toFolderId = toFolderId ?? this.currentFolder.id;
    const uploadMetadata: Partial<IMetaData> = { user_drive_folder_id: toFolderId };

    // --- handle files
    // there is no isDirectory in File type
    const files: File[] = uploadableContent.filter((f) => !(f as Directory)?.isDirectory) as File[];
    const filesMetadata = files.map((_) => uploadMetadata);

    this.uploadMultipleFiles(files, filesMetadata);

    // --- handle folders
    const folders: Directory[] = uploadableContent.filter(
      (f) => (f as Directory)?.isDirectory,
    ) as Directory[];

    const createFolderPromises = folders.map((originalFolder) =>
      // map together the original folder (created when a folder is dropped) and the created folder (backend response)
      this.createFolder(originalFolder.name, toFolderId, false).then((createdFolder) => ({
        createdFolder,
        originalFolder,
      })),
    );
    const foldersCreated = await Promise.all(createFolderPromises);

    const recursiveCallPromises = foldersCreated.map(({ createdFolder, originalFolder }) =>
      this.traverseFolder(originalFolder.children, createdFolder?.id),
    );
    await Promise.all(recursiveCallPromises);
  }

  registerMultipleOptions(event: OPTIONS) {
    switch (event) {
      case OPTIONS.UPLOAD_FILE:
        this.uploadWindow.openWindow();
        break;
      case OPTIONS.DOWNLOAD:
        this.download(this.selectedFiles);
        break;
      case OPTIONS.DELETE:
        this.deleteSelected();
        break;
      case OPTIONS.COPY:
        this.copyMoveSelected(this.selectedFiles);
        break;
      case OPTIONS.MOVE:
        this.moveSelectedFiles(this.selectedFiles);
        break;
      case OPTIONS.SHARE:
        this.barState.openShareFile({
          item: this.selectedFiles[0],
          projectId: this.projectId,
          isExternalShare: false,
        });
        break;
      case OPTIONS.EXTERNAL_SHARE:
        this.externalLinkCopyClipboard();
        break;
      case OPTIONS.CANCEL_SHARING:
        this.revokeSharingSelectedDocuments(this.selectedFiles);
        break;
      case OPTIONS.RENAME:
        this.rename(this.selectedFiles[0]);
        break;
      case OPTIONS.DUPLICATE:
        this.copyDocuments(this.selectedFiles);
        break;
      case OPTIONS.NEW_SPREADSHEET:
        this.setDriveView(DRIVE_VIEWS.EXCEL);
        break;
      case OPTIONS.NEW_WORD:
        this.setDriveView(DRIVE_VIEWS.WORD);
        break;
    }
  }

  async registerOption(event: any, item: DocumentItem) {
    switch (event) {
      case OPTIONS.DELETE:
        this.notif.showPopup('Are you sure you want to delete?').then((resp) => {
          if (resp) {
            this.notif.showLoading();
            this.delete(item);
          }
        });
        break;
      case OPTIONS.OPEN:
        if (item.type.includes('spreadsheetml.sheet') || item.type.includes('ms-excel')) {
          this.driveApi.getFileArrayBuffer(item.id, this.uuid).subscribe((data) => {
            console.log('data', data);
            this.setupSheetOrWordOpen(item);
            this.setDriveView(DRIVE_VIEWS.EXCEL);
            this.documentFileData = data;
            this.notif.close();
          });
        } else if (item.type.includes('wordprocessingml.document')) {
          console.log('open word');
          this.driveApi.getFileArrayBuffer(item.id, this.uuid).subscribe((data) => {
            this.setupSheetOrWordOpen(item);
            this.setDriveView(DRIVE_VIEWS.WORD);
            this.documentFileData = data;
            this.notif.close();
          });
        } else {
          this.driveApi.getFileViewURL(item.id, this.uuid).then(
            (url) => {
              window.open(url);
              this.notif.close();
            },
            (err) => {
              this.notif.showError(err);
            },
          );
        }
        break;
      case OPTIONS.DOWNLOAD:
        this.download([item]);
        break;
      case OPTIONS.SHARE:
        this.barState.openShareFile({ item, projectId: this.projectId, isExternalShare: false });
        break;
      case OPTIONS.EXTERNAL_SHARE:
        this.barState.openShareLink({
          items: item ? [item] : this.selectedFiles,
          isExternalShare: this.isPublicLinkAccessed,
        });
        break;
      case OPTIONS.CANCEL_SHARING:
        this.notif.showLoading();
        this.driveService.revokeSharing(item, this.userService.data.email).then(
          () => {
            this.notif.showSuccess('Successfully removed!');
            setTimeout(() => {
              this.reload();
            }, 2000);
          },
          (err) => {
            this.notif.showError("Couldn't cancel share.");
            console.warn(err);
          },
        );
        break;
      case OPTIONS.RENAME:
        const selectedFileIndex = this.currentDocuments.indexOf(item);
        this.fileNameInput.toArray()[selectedFileIndex].nativeElement.removeAttribute('disabled');
        this.fileNameInput.toArray()[selectedFileIndex].nativeElement.focus(); // will call focusHandler
        this.isRenaming = true;
        break;
      case OPTIONS.DUPLICATE:
        this.copyDocuments([item]);
        break;
      case OPTIONS.MOVE:
        this.bottomNotif
          .showPopup({
            type: NOTIFICATION_TYPE.POPUP,
            message:
              'navigate to the folder you want to paste the selected file / folder. Click “Paste” when ready.',
          })
          .then(async (response) => {
            if (response) {
              try {
                this.notif.showLoading();
                await this.driveService.move(item, this.getFolderId());
                this.reloadDriveFolder(
                  this.driveService.isFolder(item) ? item.id : item.user_drive_folder_id,
                );
                this.notif.showSuccess('Successfully moved.');
              } catch (e) {
                console.warn(e);
                this.notif.showError("Couldn't move.");
              } finally {
                setTimeout(() => {
                  this.reload();
                }, 1000);
              }
            }
          });
        break;
      case OPTIONS.COPY:
        this.bottomNotif
          .showPopup({
            type: NOTIFICATION_TYPE.POPUP,
            message:
              'navigate to the folder you want to paste the selected file / folder. Click “Paste” when ready.',
          })
          .then(async (response) => {
            if (response) {
              this.copyDocuments([item]);
            }
          });
        break;
    }
  }

  setupSheetOrWordOpen(item) {
    this.uploadMetaData = {
      ...this.uploadMetaData,
      user_drive_file_id: item.id,
    };
    const name = item.name.replace(/(?:\.([^.]+))?$/, '');
    this.filenameForm.controls.fileName.setValue(name);
  }

  registerEmptyAreaOption(event) {
    // handles right click options when clicked on empty area
    switch (event) {
      case OPTIONS.NEW_FOLDER:
        this.folderNameInput.inputElement.nativeElement.focus();
        // this.addFolder();
        break;
      case OPTIONS.UPLOAD_FILE:
        this.uploadWindow.openWindow();
        break;
      case OPTIONS.NEW_SPREADSHEET:
        this.setDriveView(DRIVE_VIEWS.EXCEL);
        break;
      case OPTIONS.NEW_WORD:
        this.setDriveView(DRIVE_VIEWS.WORD);
        break;
    }
  }

  /**
   * select or unselect files based on checked
   * @param checked
   */
  handleFilesSelection(checked: boolean) {
    if (checked) {
      this.selectedFiles = Object.assign([], this.currentDocuments);
    } else {
      this.selectedFiles = [];
    }
  }

  fileFocusHandler(event, item?: DocumentItem) {
    if (!item) {
      item = this.selectedFiles[0];
    }
    const index = this.currentDocuments.indexOf(item);
    switch (event) {
      case 'focusin':
        // leave input as it was, no need for empty value
        // this.fileNameInput.toArray()[index].nativeElement.value = '';
        break;
      case 'focusout':
        if (!this.fileNameInput.toArray()[index].nativeElement.value) {
          this.fileNameInput.toArray()[index].nativeElement.value =
            this.currentDocuments[index].name;
        }
        this.fileNameInput.toArray()[index].nativeElement.attributes.disabled = true;
        this.fileNameInput.toArray()[index].nativeElement.setAttribute('disabled', true);
        setTimeout(() => {
          this.isRenaming = false;
        }, 1000);
        break;
      case 'change':
        this.driveService.isFolder(this.currentDocuments[index])
          ? this.renameFolder(index)
          : this.renameFile(index);
        setTimeout(() => {
          this.isRenaming = false;
        }, 1000);
        break;
    }
  }

  selectFile(file) {
    console.log('select', file);
    const fileIndex = this.selectedFiles.indexOf(file);
    if (fileIndex < 0) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles.splice(fileIndex, 1);
    }
    this.selectedFiles = [...this.selectedFiles]; // need to create new array to detect changes for pipe
    this.allDocsCheckbox = this.selectedFiles.length === this.currentDocuments.length;
  }

  download(selectedFiles: any[]) {
    this.notif.showPopup('Do you want to download selected item?').then((resp) => {
      if (resp) {
        this.driveService.downloadSelectedDocuments(
          selectedFiles,
          this.currentFolder?.name,
          this.uuid,
          this.currentFolder?.id,
        );
      }
    });
  }

  deleteSelected() {
    this.notif.showPopup('Do you want to delete selected?').then((resp) => {
      if (resp) {
        this.selectedFiles.forEach((elem) => this.delete(elem));
        this.selectedFiles = [];
      }
    });
  }

  rename(selectedFile: DocumentItem) {
    this.registerOption(OPTIONS.RENAME, selectedFile);
  }

  addFolder() {
    if (this.folderName.value) {
      this.createFolder(this.folderName.value);
    } else {
      this.notif.showError('Please enter a folder name to create a folder!');
    }
  }

  async createFolder(
    name: string,
    parentId: number = null,
    showLoading = true,
  ): Promise<DocumentItem> {
    if (this.currentFolder?.id) {
      const body = {
        name,
        parent_id: parentId ?? this.currentFolder?.id,
      };

      try {
        const folder = await this.driveApi.createFolder(this.currentFolder?.id, body, this.uuid);
        this.folderName.setValue('');
        if (showLoading) {
          this.notif.showLoading();
          this.reload();
        }
        return folder;
      } catch (err) {
        this.notif.showError(err);
        return null;
      }
    }
  }

  renameFile(index) {
    const newName =
      this.fileNameInput.toArray()[index].nativeElement.value +
      '.' +
      this.regexExtension.exec(this.currentDocuments[index].name)[1];
    this.driveApi.renameFile(newName, this.currentDocuments[index].id, this.uuid).then(
      () => this.reload(),
      (err) => {
        console.warn(err);
        this.notif.showError(err.error.message);
      },
    );
  }

  renameFolder(index: number) {
    const newName = this.fileNameInput.toArray()[index].nativeElement.value;
    this.driveApi.renameFolder(newName, this.currentDocuments[index].id, this.uuid).then(
      () => this.reload(),
      (err) => {
        console.warn(err);
        this.notif.showError(err.error.message);
      },
    );
  }

  showNotification() {
    switch (this.notification.type) {
      case NOTIFICATION_TYPE.ERROR:
        this.notif.showError(this.notification.message);
        break;
      case NOTIFICATION_TYPE.SUCCESS:
        this.notif.showSuccess(this.notification.message);
        break;
      case NOTIFICATION_TYPE.CLOSE:
        this.notif.close();
        break;
      default:
        console.warn(
          'Default message: ',
          this.notification.message,
          ', type:',
          this.notification.type,
        );
    }
  }

  clearPlaceholder() {
    this.folderNamePlaceholder = '';
  }

  addPlaceholder() {
    this.folderNamePlaceholder = this.FOLDER_NAME_PLACEHOLDER;
  }

  reload(both = false) {
    if (this.uuid) {
      this.store.dispatch(reloadExternalDrive({ uuid: this.uuid }));
      return;
    }
    if (both) {
      this.store.dispatch(reloadProjDocs());
      this.store.dispatch(reload());
      return;
    }
    if (this.isProjectView) {
      this.store.dispatch(reloadProjDocs());
    } else {
      this.store.dispatch(reload());
    }
  }

  private copyDocuments(items: DocumentItem[]) {
    this.notif.showLoading();
    this.driveService.copy(this.getFolderId(), items).then(
      () => {
        this.notif.showSuccess('Successfully copied.');
        setTimeout(() => {
          this.reload();
        }, 1000);
      },
      (err) =>
        this.notif.showError(
          err?.error?.message ? err.error.message : 'An error occurred during the process.',
        ),
    );
  }

  copyMoveSelected(items: DocumentItem[]) {
    this.bottomNotif
      .showPopup({
        type: NOTIFICATION_TYPE.POPUP,
        message:
          'navigate to the folder you want to paste the selected file / folder. Click “Paste” when ready.',
      })
      .then(async (response) => {
        if (response) {
          this.notif.showLoading();
          this.copySelectedFiles(items);
        }
      });
  }

  copySelectedFiles(items: DocumentItem[]) {
    this.driveService.copy(this.getFolderId(), items).then(
      () => setTimeout(() => this.reload(), 1000),
      (err) =>
        this.notif.showError(
          err?.error?.message ? err.error.message : 'An error occurred during the process.',
        ),
    );
  }

  private delete(item: DocumentItem) {
    const request = this.driveService.isFolder(item)
      ? this.driveApi.deleteFolder(item.id, this.uuid)
      : this.driveApi.deleteFile(item.id, this.uuid);
    console.log('delete request', request);
    request.then(
      () => {
        this.notif.close();
        this.reload();
      },
      (err) => this.notif.showError('Could not delete.'),
    );
  }

  // todo: make simplify move, cancelsharing, copyselected methods in one function and handle type as all 3 look similar
  moveSelectedFiles(items: DocumentItem[]) {
    this.bottomNotif
      .showPopup({
        type: NOTIFICATION_TYPE.POPUP,
        message:
          'navigate to the folder you want to paste the selected file / folder. Click “Paste” when ready.',
      })
      .then(async (response) => {
        if (response) {
          this.notif.showLoading();
          const promises = [];
          items.forEach((item) => {
            promises.push(this.driveService.move(item, this.getFolderId()));
          });

          const result = forkJoin(promises);
          result
            .pipe(
              catchError((err) => {
                this.notif.showError('An error occurred during the process.');
                return of(err);
              }),
            )
            .subscribe((data) => {
              this.reloadDriveFolder(
                this.driveService.isFolder(items[0]) ? items[0].id : items[0].user_drive_folder_id,
              );
              this.reload();
            });
        }
      });
  }

  revokeSharingSelectedDocuments(items: DocumentItem[]) {
    this.notif.showLoading();
    const promises = [];
    items.forEach((item) => {
      const operation = this.driveService.revokeSharing(item, this.userService.data.email);
      promises.push(operation);
    });

    const result = forkJoin(promises);
    result
      .pipe(
        catchError((err) => {
          this.notif.showError('An error occurred during the process.');
          return of(err);
        }),
      )
      .subscribe(() => {
        this.notif.showSuccess('Successfully removed!');
        setTimeout(() => {
          this.reload();
        }, 2000);
      });
  }

  private checkRoute() {
    this.activatedRoute.paramMap.pipe(first()).subscribe((params) => {
      this.uuid = params.get('uuid');
      if (this.uuid) {
        this.isPublicLinkAccessed = true;
        this.externalUploadEndpoint = `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${this.uuid}/user-drive-files`;
        this.sharedBy$ = this.store.select(getSharedBy);
      }
    });
  }

  private handleProjectView() {
    this.store
      .select(getSelectedProjectId)
      .pipe(
        takeUntil(this.isDestroyed$),
        skipWhile((value) => !value),
        first(),
      )
      .subscribe((id) => {
        this.projectId = id;
      });
  }

  private initSubscriptions() {
    const isProjectView$ = DriveComponent.isProjectView.pipe(takeUntil(this.isDestroyed$));

    isProjectView$
      .pipe(
        tap((isProjectView) => {
          this.isProjectView = isProjectView;
          if (isProjectView) {
            this.handleProjectView();
          }
        }),
        switchMap((isProjectView) => {
          const selectedFolder = this.store.select(
            isProjectView ? getProjectDocsSelectedFolder : getSelectedFolder,
          );
          this.selectedFolder = selectedFolder;
          return selectedFolder;
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((folder) => {
        this.selectedFiles = [];
        this.currentFolder = folder;
        this.uploadMetaData = { user_drive_folder_id: this.getFolderId() };

        if (!this.currentFolder) {
          return;
        }
        if (!folder?.setFolderPath) {
          this.setCurrentPath();
        }
        this.setCurrentDocuments();
      });

    isProjectView$
      .pipe(
        switchMap((isProjectView) => {
          const isLoaded$ = this.store.select(isProjectView ? areProjDocsLoaded : areDocsLoaded);
          this.areDocsLoaded = isLoaded$;
          return isLoaded$;
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((isLoaded) => {
        if (isLoaded && this.notification.message && this.notification.type) {
          this.showNotification();
          this.notification.message = '';
        }
        if (this.documentFileData && isLoaded) {
          delete this.uploadMetaData.user_drive_file_id;
          this.documentFileData = null;
        }
        if (!isLoaded) {
          this.notif.showLoading();
        } else {
          this.notif.close();
        }
      });
  }

  externalLinkCopyClipboard() {
    this.barState.openShareLink({
      items: this.selectedFiles,
      isExternalShare: this.isPublicLinkAccessed,
    });
  }

  selectOneDocument(selectedCheckBox: Checkbox, item: DocumentItem, $event: MouseEvent) {
    this.handleFilesSelection(false);
    this.selectFile(item);
    this.openOverlay(item, $event);
  }

  openOverlay(item, $event: MouseEvent) {
    if (this.overlayService.isOpened()) {
      this.overlayService.closeOverlay();
    }
    this.overlayService.openOverlay(
      { position: 'relative', x: $event.x + 70, y: $event.y + 10, hasBackdrop: true },
      {
        listItems: this.overlayOptionsPipe.transform(
          item,
          OVERLAY_TYPES.DRIVE,
          this.isPublicLinkAccessed,
          this.currentFolder,
        ),
      },
      CUSTOM_OVERLAY_VIEWS.STYLED_OPTIONS_LIST,
    );

    this.overlayService
      .isOpened$()
      .pipe(skip(1), delay(500), takeUntil(this.isDestroyed$))
      .subscribe((isOpened) => {
        if (!isOpened) {
          this.handleFilesSelection(false);
        }
      });

    this.overlayService.outputData$.pipe(first()).subscribe((selectedOption: OPTIONS) => {
      this.registerOption(selectedOption, this.selectedFiles[0]);
    });
  }

  private reloadDriveFolder(id: number) {
    this.store.dispatch(
      this.isProjectView ? reloadProjectFolder({ id }) : reloadDriveFolder({ id }),
    );
  }

  ngOnDestroy(): void {
    if (!this.isProjectView) {
      this.driveService.folderPath = [];
      this.store.dispatch(clearAll());
    } else {
      this.goBack(0);
    }
    this.currentFolder = null;
    this.notif.close();
    window.removeEventListener('popstate', this.browserBackButtonHandler);
    this.subscriptions.unsubscribe();
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
