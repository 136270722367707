@defer (when wasDataLoadedOnce$ | async) {
  <div class="flex flex-col flex-1 min-h-0 m-4 mt-8">
    <div class="mx-4">
      <span class="flex items-center gap-1.5 relative">
        <div class="text-color_primary font-semibold text-lg">
          {{ (COR$ | async)?.title }}
        </div>
        <app-un-styled-options-list
          [listItems]="editOptions"
          (selectedOption)="registerOption($event)"
          #unStyledOptionsList
        >
          <ng-template>
            <span
              (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow(); $event.stopPropagation()"
              class="icon-options-inverted cursor-pointer text-color_secondary flex items-center"
            ></span>
          </ng-template>
        </app-un-styled-options-list>
      </span>
      <div class="text-color_primary font-normal text-sm">{{ (COR$ | async).number }}</div>
    </div>

    <div class="mt-6 border-b border-b-solid border-shade_5"></div>

    <div class="my-2 flex m-4">
      <div class="w-10/12 text-ellipsis line-clamp-2">
        {{ (COR$ | async)?.description ?? 'Missing description.' }}
      </div>
      <div class="w-2/12 flex items-center justify-center">
        <app-status-block class="w-full" [status]="(COR$ | async).status"></app-status-block>
      </div>
    </div>

    <div class="border-b border-b-solid border-shade_5"></div>

    <app-dynamic-projected-table
      class="block"
      [isEmpty]="false"
      [wasDataLoadedOnce]="wasDataLoadedOnce$ | async"
      [isLoading]="isLoading$ | async"
    >
      <div tableHeader class="grid-row header-row bg-shade_7">
        <div class="item font-semibold text-sm text-color_primary">Line Item & Description</div>
        <div></div>
        <div class="item font-semibold text-sm text-color_primary">Commitment Dollars</div>
      </div>

      <div projectedTable class="grid-table">
        @for (pco of (COR$ | async).pcos; track pco.id + '_' + index; let index = $index) {
          <app-expansion-panel
            [expanded]="expandedPCOs.get(pco.id)"
            (expandedChange)="expandedPCOs.set(pco.id, $event)"
          >
            <div header-row class="grid-row header-row">
              <div class="item flex items-center">
                @if (expandedPCOs.get(pco.id)) {
                  <span class="icon-expand-square mr-1"></span>
                } @else {
                  <span class="icon-collapse-square mr-1"></span>
                }

                <div class="item flex flex-col px-1">
                  <span class="font-semibold text-sm">
                    {{ pco.number }}
                  </span>
                  <span class="font-normal text-xs">{{ pco?.title }}</span>
                </div>
              </div>

              <span class="vertical-separator"></span>

              <div [class.negative]="pco.commitment_dollars < 0" class="item text-sm font-semibold">
                {{ pco.commitment_dollars | money: 'positiveComa' }}
              </div>

              <span class="vertical-separator"></span>

              <div class="item flex justify-start">{{ pco.duration_extension }} day duration extension</div>
            </div>

            <!--             todo: CREATE COMPONENT FOR PCO ITEMS & markup items-->
            <div content-row>
              <!--              PCO UNMARKED ITEMS-->
              @for (item of pco.pco_items; track item.id) {
                <app-expansion-panel
                  [expanded]="expandedLineItems.get(item.id)"
                  (expandedChange)="expandedLineItems.set(item.id, $event)"
                >
                  <div header-row class="grid-row header-row">
                    <div class="item flex items-center pl-4">
                      @if (expandedLineItems.get(item.id)) {
                        <span class="icon-expand-square mr-1"></span>
                      } @else {
                        <span class="icon-collapse-square mr-1"></span>
                      }

                      <div class="item flex flex-col px-1">
                        <app-faded-text [displayGrid]="true" class="font-semibold text-sm">{{
                          item.item_name
                        }}</app-faded-text>
                        <app-faded-text [displayGrid]="true" class="font-normal text-sm">
                          {{ item.division }} {{ item.cost_type }}
                        </app-faded-text>
                      </div>
                    </div>

                    <span class="vertical-separator"></span>

                    <div [class.negative]="item.commitment_dollars < 0" class="item text-sm font-normal">
                      {{ item.commitment_dollars | money: 'positiveComa' }}
                    </div>

                    <span class="vertical-separator"></span>

                    <div></div>
                  </div>
                  <div content-row>
                    @for (cost of item.pco_item_costs; track cost.id) {
                      <div class="grid-row header-row">
                        <app-faded-text [displayGrid]="true" class="font-normal text-xs pl-12">{{
                          cost.spend_description
                        }}</app-faded-text>

                        <span class="vertical-separator"></span>

                        <div [class.negative]="cost.commitment_dollars < 0" class="item text-sm font-normal">
                          {{ cost.commitment_dollars | money: 'positiveComa' }}
                        </div>

                        <span class="vertical-separator"></span>

                        <div class="item flex flex-col items-center text-color_primary">
                          <app-faded-text [displayGrid]="true" class="flex justify-start font-bold text-sm w-full">{{
                            cost.contract_title
                          }}</app-faded-text>
                          <app-faded-text [displayGrid]="true" class="flex justify-start font-normal text-sm w-full">{{
                            cost.company_name
                          }}</app-faded-text>
                        </div>
                      </div>
                    } @empty {
                      <span
                        class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
                        >There are no costs.
                      </span>
                    }
                  </div>
                </app-expansion-panel>
              } @empty {
                <span
                  class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
                  >There are no line items.</span
                >
              }

              <!--              PCO MARKUP ITEMS-->
              @for (item of pco.pco_markup_items; track item.id) {
                <app-expansion-panel
                  [expanded]="expandedLineItems.get(item.id)"
                  (expandedChange)="expandedLineItems.set(item.id, $event)"
                >
                  <div header-row class="grid-row header-row">
                    <div class="item flex items-center pl-4">
                      @if (expandedLineItems.get(item.id)) {
                        <span class="icon-expand-square mr-1"></span>
                      } @else {
                        <span class="icon-collapse-square mr-1"></span>
                      }

                      <div class="item flex flex-col px-1">
                        <app-faded-text [displayGrid]="true" class="font-semibold text-sm">{{
                          item.item_name
                        }}</app-faded-text>
                        <app-faded-text [displayGrid]="true" class="font-normal text-sm">
                          {{ item.division }} {{ item.cost_type }}
                        </app-faded-text>
                      </div>
                    </div>

                    <span class="vertical-separator"></span>

                    <div [class.negative]="item.commitment_dollars < 0" class="item text-sm font-normal">
                      {{ item.commitment_dollars | money: 'positiveComa' }}
                    </div>

                    <span class="vertical-separator"></span>

                    <div></div>
                  </div>
                  <div content-row>
                    @for (cost of item.pco_item_costs; track cost.id) {
                      <div class="grid-row header-row">
                        <app-faded-text [displayGrid]="true" class="font-normal text-xs pl-12">{{
                          cost.spend_description
                        }}</app-faded-text>

                        <span class="vertical-separator"></span>

                        <div [class.negative]="cost.commitment_dollars < 0" class="item text-sm font-normal">
                          {{ cost.commitment_dollars | money: 'positiveComa' }}
                        </div>

                        <span class="vertical-separator"></span>

                        <div class="item flex flex-col items-center text-color_primary">
                          <app-faded-text [displayGrid]="true" class="flex justify-start font-bold text-sm w-full">{{
                            cost.contract_title
                          }}</app-faded-text>
                          <app-faded-text [displayGrid]="true" class="flex justify-start font-normal text-sm w-full">{{
                            cost.company_name
                          }}</app-faded-text>
                        </div>
                      </div>
                    } @empty {
                      <span
                        class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
                        >There are no costs.
                      </span>
                    }
                  </div>
                </app-expansion-panel>
              } @empty {
                <span
                  class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
                  >There are no line items.</span
                >
              }
            </div>
          </app-expansion-panel>
        } @empty {
          <span class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
            >There are no items available.</span
          >
        }
      </div>

      <div tableFooter class="grid-row totals bg-shade_7">
        <div class="item font-semibold">{{ (COR$ | async).number }} Total</div>
        <span class="vertical-separator"></span>
        <div
          [class.negative]="(combinedCommitmentTotals$ | async) < 0"
          class="item font-semibold flex justify-center gap-1"
        >
          {{ combinedCommitmentTotals$ | async | money: 'positiveComa' }}
        </div>
        <span class="vertical-separator"></span>
        <div class="item text-sm font-normal flex justify-start items-center gap-1">
          {{ totalDaysExtension$ | async }} day duration extension
        </div>
      </div>
    </app-dynamic-projected-table>
  </div>
} @placeholder {
  <app-page-loading></app-page-loading>
}
