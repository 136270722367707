import { Component, inject, Input, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgScrollbar } from 'ngx-scrollbar';
import { Store } from '@ngrx/store';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { UploadWindowComponent } from '../../../upload/upload-window/upload-window.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { ShortenFileNamePipe } from '@app/pipes/framework/shorten-file-name.pipe';
import { IPrimeContractModel } from '@app/store/prime-commitments/prime-commitments.types';
import { primeContractStatuses } from '@app/store/prime-commitments/prime-commitments.constants';
import { primeSidebarActions } from '@app/store/prime-commitments/prime-commitments.actions';

@Component({
  selector: 'app-prime-contract-general-page',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    DropdownComponent,
    FloatingInputComponent,
    FormsModule,
    InputCalendarComponent,
    UploadWindowComponent,
    ShortenFileNamePipe,
    SimpleButtonComponent,
    NgScrollbar,
  ],
  templateUrl: './prime-contract-general-page.component.html',
  styleUrl: './prime-contract-general-page.component.scss',
})
export class PrimeContractGeneralPageComponent {
  private readonly store = inject(Store);

  @ViewChild('fileUpload') fileUpload: UploadWindowComponent;
  @ViewChild('generalPage') generalPage: NgForm;

  protected readonly statuses = primeContractStatuses;

  @Input() model: IPrimeContractModel;

  registerFileUpload($event: File[]) {}

  removeFile(file: any, index: number) {}

  isFormValid() {
    this.generalPage.form.markAllAsTouched();
    return this.generalPage.form.valid;
  }

  goToNextPage() {
    if (!this.isFormValid()) {
      return;
    }

    this.store.dispatch(primeSidebarActions.onClickNext());
  }
}
