@defer (when wasDataLoadedOnce$ | async) {
  <div class="flex flex-col flex-1 min-h-0 m-4 mt-8">
    <div class="mx-4">
      <span class="flex items-center gap-1.5 relative">
        <div class="text-color_primary font-semibold text-lg">
          {{ PCO.title }}
        </div>
        <app-un-styled-options-list
          [listItems]="editOptions"
          (selectedOption)="registerOption($event)"
          #unStyledOptionsList
        >
          <ng-template>
            <span
              (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow(); $event.stopPropagation()"
              class="icon-options-inverted cursor-pointer text-color_secondary flex items-center"
            ></span>
          </ng-template>
        </app-un-styled-options-list>
        <div class="ml-auto gap-2 flex align-center absolute right-0 mb-0 mt-5 mr-3 items-center">
          @if ((autoSave$ | async) === 'syncing') {
            <span class="text-color_primary font-semibold text-base capitalize">Syncing</span>
            <div class="dots-container">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          } @else if ((autoSave$ | async) === 'synced') {
            <span class="text-color_primary font-semibold text-base capitalize cursor-default">{{
              autoSave$ | async
            }}</span>
            <span class="text-color_accept icon-checkbox-round"></span>
          } @else if ((autoSave$ | async) === 'error') {
            <span class="text-color_primary font-semibold text-base capitalize cursor-default"> Could not sync </span>
            <span class="text-color_reject icon-warning"></span>
          } @else {
            <div pTooltip="All line items must have a selected value.">
              <span class="text-color_primary font-semibold text-base capitalize cursor-default">
                {{ autoSave$ | async }}
              </span>
              <span class="text-color_accent icon-warning"></span>
            </div>
          }
        </div>
      </span>
      <div class="text-color_primary font-normal text-sm">{{ PCO.number }}</div>
    </div>

    <div class="mt-6 border-b border-b-solid border-shade_5"></div>

    <div class="my-2 flex m-4">
      <div class="w-10/12 text-ellipsis line-clamp-2">
        {{ PCO?.description ?? 'Missing description.' }}
      </div>
      <div class="w-2/12 flex items-center justify-center">
        <app-status-block class="w-full" [status]="PCO.status"></app-status-block>
      </div>
    </div>

    <div class="border-b border-b-solid border-shade_5"></div>

    <app-dynamic-projected-table
      class="block"
      [isEmpty]="false"
      [wasDataLoadedOnce]="wasDataLoadedOnce$ | async"
      [isLoading]="isLoading$ | async"
    >
      <div tableHeader class="grid-table">
        <div class="grid-row header-row bg-shade_7">
          <div class="item font-semibold text-sm text-color_primary">Line Item & Description</div>
          <div></div>
          <div class="item font-semibold text-sm text-color_primary flex flex-col">
            <span>Budget</span>
            <span>Dollars</span>
          </div>
          <div></div>
          <div class="item font-semibold text-sm text-color_primary">Commitment Dollars</div>
          <div></div>
          <div class="item font-semibold text-sm text-color_primary pl-5">Commitment Reference</div>
        </div>
      </div>

      <div projectedTable class="grid-table">
        @for (pcoItem of PCO.pco_items; track index; let index = $index) {
          <app-expansion-panel
            [expanded]="expandedItems.get(index)"
            (expandedChange)="expandedItems.set(index, $event)"
          >
            <app-prime-pco-details-header
              #pcoDetailHeader
              header-row
              [isExpanded]="expandedItems.get(index)"
              [scope]="PCO.scope"
              (deleteItem)="deleteUnmarkedLineItem(pcoItem.id)"
              (addPCORow)="addItem($event, pcoItem)"
              [data]="pcoItem"
              (valueChanged)="updateUnmarkedLineItem($event, index)"
            />

            <div content-row>
              @for (cost of pcoItem.pco_item_costs; let costIndex = $index; track costIndex) {
                <app-prime-pco-details-expansion-content
                  [scope]="PCO.scope"
                  [lineItemId]="pcoItem.item_id"
                  (deleteItem)="deleteCost(pcoItem, costIndex)"
                  [data]="cost"
                  (valueChanged)="updatePCOCost($event, pcoItem, costIndex)"
                ></app-prime-pco-details-expansion-content>
              } @empty {
                <span
                  class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
                  >There are no costs.
                  <span
                    (click)="addItem(OPTIONS.ADD_SPEND_DESCRIPTION, pcoItem)"
                    class="text-color_secondary font-bold cursor-pointer"
                    >Click here to add cost.</span
                  ></span
                >
              }
            </div>
          </app-expansion-panel>
        } @empty {
          <span class="border-b border-solid border-b-shade_5 flex flex-col items-center font-semibold text-shade_3 p-4"
            >There are no unmarked line item.
            <span (click)="addBudgetLine()" class="text-color_secondary font-bold cursor-pointer"
              >Click here to add.</span
            ></span
          >
        }

        @for (pcoItem of PCO.pco_markup_items; track pcoItem.id + '_' + index; let index = $index) {
          <app-pco-detail-markup-line-item
            [data]="pcoItem"
            (valueChange)="updateMarkupLineItem($event, index)"
          ></app-pco-detail-markup-line-item>
        }
      </div>

      <div tableFooter class="grid-table totals">
        <div class="grid-row">
          <div class="item">{{ PCO.number }} Total</div>
          <span class="vertical-separator"></span>
          <div [class.negative]="(combinedBudgetTotals$ | async) < 0" class="item flex justify-center gap-1">
            {{ combinedBudgetTotals$ | async | money: 'positiveComa' }}
          </div>
          <span class="vertical-separator"></span>
          <div [class.negative]="(combinedCommitmentTotals$ | async) < 0" class="item flex justify-center gap-1">
            {{ combinedCommitmentTotals$ | async | money: 'positiveComa' }}
          </div>
          <span class="vertical-separator"></span>
          <div class="item"></div>
        </div>
      </div>
    </app-dynamic-projected-table>
  </div>
} @placeholder {
  <app-page-loading></app-page-loading>
}
