import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { BudgetLineItemTableHeaderComponent } from '../budget-line-item-table-header/budget-line-item-table-header.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass } from '@angular/common';
import { BudgetLineItemComponent } from '../budget-line-item/budget-line-item.component';
import {
  getProjectStartDate,
  getSelectedSpendType,
  getSelectedYear,
  getStoreUpdates,
  selectAllCommittedItems,
  selectHasCommitments,
  selectLineItemsExtended,
  selectPrimeLinesExtended,
} from '../../../../../../store/spend/spend.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import {
  ICommittedItem,
  ICommittedItemExtended,
  ILastSpendStoreUpdate,
  ILineItem,
  ILineItemExtended,
  ISubitemExtended,
  SpendStoreUpdateTypes,
} from '../../../../../../store/spend/spend.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import {
  spendActionTypes,
  updateCommittedLineItemsBulk,
  updateLineItem,
  updateLineItemsBulk,
} from '../../../../../../store/spend/spend.actions';
import { DeepCopyService } from '../../../../../../services/deep-copy.service';
import { BudgetLineItemTotalsComponent } from '../budget-line-item-totals/budget-line-item-totals.component';
import { CommittedLineItemComponent } from '../budget-line-item/committed-line-item/committed-line-item.component';
import { CdkScrollable } from '@angular/cdk/overlay';
import { SpendDistributionService } from '../../../../../../services/spend-distribution.service';
import { CurrentUserService } from '../../../../../../services/current-user.service';
import { IBudgetTemplateSubitemGC } from '../../../../../../store/templates/templates.types';
import { PrimeLineItemComponent } from '../budget-line-item/prime-line-item/prime-line-item.component';
import { SPEND_TYPES } from '../../../../../../framework/constants/spend.constants';

@Component({
  selector: 'app-budget-line-item-table',
  standalone: true,
  imports: [
    BudgetLineItemTableHeaderComponent,
    CdkDrag,
    AsyncPipe,
    BudgetLineItemComponent,
    CdkDropList,
    NgClass,
    BudgetLineItemTotalsComponent,
    CommittedLineItemComponent,
    CdkScrollable,
    PrimeLineItemComponent,
  ],
  templateUrl: './budget-line-item-table.component.html',
  styleUrl: './budget-line-item-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(window:resize)': 'updateWrapperHeight()',
  },
})
export class BudgetLineItemTableComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly store = inject(Store);
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly spentDistributionService = inject(SpendDistributionService);
  protected readonly userService = inject(CurrentUserService);

  protected readonly isDestroyed$ = new Subject<boolean>();
  protected readonly selectedYear$ = this.store.select(getSelectedYear);
  protected readonly lastStoreUpdate$ = this.store.select(getStoreUpdates);
  protected readonly projectStartDate$ = this.store.select(getProjectStartDate);
  protected readonly hasCommitments$ = this.store.select(selectHasCommitments);
  protected readonly selectedBudgetType$ = this.store.select(getSelectedSpendType);
  protected readonly committedItems$: Observable<ICommittedItemExtended[]> =
    this.store.select(selectAllCommittedItems);
  protected readonly primeLines$ = this.store.select(selectPrimeLinesExtended);
  protected readonly SPEND_TYPES = SPEND_TYPES;
  isDragging = false;
  items: ILineItemExtended[] = [];
  committedItems: ICommittedItemExtended[] = [];
  primeLines: ILineItemExtended[] = [];
  projectStartDate: string;
  selectedYear: number;

  /**
   * returns all items and subitems in a single, flat array
   */
  get allSubitems(): ILineItemExtended[] {
    return this.items.flatMap((item) => item.subitems);
  }

  // all the lines in the view including parent items, subitems, committed items, prime items, cats, dogs, lamas, whatever this component has to offer
  allLines = viewChildren(CdkDrag);
  mainWrapper = viewChild.required<ElementRef<HTMLDivElement>>('spendTableWrapper');
  // this couldn't be a computed property because it needs to be updated on window resize
  wrapperClientHeight = signal(0);
  // the height of the third line in the table
  thirdLineHeight = computed(() => {
    if (!this.allLines()[0]) {
      return 0;
    }

    const baseHeight = 50;
    const lineCount = this.allLines().length;
    const suggestedHeight = lineCount * baseHeight + 24;
    const minHeight = this.wrapperClientHeight() - 2;
    return Math.max(minHeight, suggestedHeight);
  });

  ngOnInit(): void {
    this.lastStoreUpdate$
      // todo: consider using less debounce time
      .pipe(takeUntil(this.isDestroyed$), debounceTime(300))
      .subscribe(this.onStoreUpdate);

    this.createStoreSubscriptions();
    this.getInitialLineItems();
  }

  ngAfterViewInit(): void {
    // we need to wait for the view to be initialized to get the correct height of the wrapper
    setTimeout(() => {
      this.updateWrapperHeight();
    }, 10);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  updateWrapperHeight() {
    this.wrapperClientHeight.set(this.mainWrapper().nativeElement.clientHeight);
  }

  getInitialLineItems() {
    // this component is recreated every time it is opened
    // but the SET_ALL update type is called once,
    // so we need to set the line items on every component creation
    this.store
      .select(selectLineItemsExtended)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => !!value),
        debounceTime(300),
        take(1),
      )
      .subscribe((lineItems) => {
        this.setAllLineItems(lineItems);
      });
  }

  createStoreSubscriptions() {
    this.projectStartDate$.pipe(takeUntil(this.isDestroyed$)).subscribe((date) => {
      this.projectStartDate = date;
    });

    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
    });

    this.committedItems$.pipe(takeUntil(this.isDestroyed$)).subscribe((items) => {
      this.committedItems = items;
    });

    this.primeLines$.pipe(takeUntil(this.isDestroyed$)).subscribe((lines) => {
      this.primeLines = lines;
    });
  }

  getLineWithDisabledMonths(item: ILineItemExtended) {
    item = cloneDeep(item);
    return item;
  }

  onStoreUpdate = (storeUpdate: ILastSpendStoreUpdate) => {
    console.log('storeUpdate', storeUpdate);
    switch (storeUpdate.lastStoreUpdate.type) {
      case SpendStoreUpdateTypes.SET_ALL: {
        this.setAllLineItems(storeUpdate.lineItems);
        break;
      }
      case SpendStoreUpdateTypes.ADD: {
        const localLine = this.getLineWithDisabledMonths(storeUpdate.lineItems[0]);
        this.items.push(localLine);
        break;
      }
      case SpendStoreUpdateTypes.UPDATE: {
        this.updateLineLocally(storeUpdate.lineItems[0]);
        break;
      }
      case SpendStoreUpdateTypes.UPDATE_SUBITEM: {
        this.updateLineLocally(storeUpdate.lineItems[0]);
        this.updateLineLocally(storeUpdate.lineItems[1]);
        break;
      }
      case SpendStoreUpdateTypes.DELETE: {
        this.deleteLineLocally(storeUpdate.lastStoreUpdate.lineId);
        break;
      }
      case SpendStoreUpdateTypes.FILTER_CHANGE: {
        storeUpdate.lineItems.forEach((item) => {
          this.updateLineLocally(item);
        });
        break;
      }
    }
    this.changeDetectorRef.detectChanges();
  };

  updateLineLocally(item: ILineItemExtended | ISubitemExtended) {
    let newItem = cloneDeep(item);
    let index = this.items.findIndex((it) => it.id === newItem.id);

    if (index < 0) {
      const subItem = this.allSubitems.find((it) => it.id === newItem.id);
      if (!subItem) {
        console.warn('updateLineLocally: item not found', newItem, this.items);
        return;
      }

      // if we've found a subitem, we need to update the parent item with the new subitem
      // maybe not the cleanest way to update subitems, because we are updating the whole parent item,
      // but you know how it is, time is money
      newItem = this.items.find((it) => it.id === (item as ISubitemExtended).parent_id);
      index = this.items.findIndex((it) => it.id === newItem.id);
      if (!newItem || index < 0) {
        console.warn('updateLineLocally: parent item not found', newItem);
        return;
      }

      newItem.subitems = newItem.subitems.map((subitem) => {
        if (subitem.id === item.id) {
          return item;
        }
        return subitem;
      });
    }

    this.setModifiedItem(index, newItem);
  }

  deleteLineLocally(id: number) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  /**
   * Updates an item in the list at the given index.
   * It is intentionally written this way listing the properties one by one
   * to avoid creating a new object thus improving performance.
   */
  setModifiedItem(index: number, newItem: ILineItemExtended) {
    this.items[index].id = newItem.id;
    this.items[index].name = newItem.name;
    this.items[index].project_total = newItem.project_total;
    this.items[index].year_total = newItem.year_total;
    this.items[index].start_date = newItem.start_date;
    this.items[index].commitment_start_date = newItem.commitment_start_date;
    this.items[index].duration = newItem.duration;
    this.items[index].distribution = newItem.distribution;
    this.items[index].budget = newItem.budget;
    this.items[index].row_number = newItem.row_number;
    this.items[index].committed_items = newItem.committed_items;
    this.items[index].subitems = newItem.subitems;
    Object.entries(this.items[index].monthly_data).forEach(([key, value]) => {
      if (value !== newItem.monthly_data[key]) {
        this.items[index].monthly_data[key] = cloneDeep(newItem.monthly_data[key]);
      }
    });
  }

  setAllLineItems(lineItems: ILineItemExtended[]) {
    // UI does not freeze this way
    setTimeout(() => {
      this.items = cloneDeep(lineItems);
      this.changeDetectorRef.detectChanges();
    }, 10);
  }

  setRowNumberUntilIndex(index: number) {
    this.items.forEach((item, lineIndex) => {
      if (lineIndex <= index) {
        item.row_number = lineIndex;
        this.store.dispatch(updateLineItem({ lineItem: DeepCopyService.deepCopy(item) }));
      }
    });
  }

  dropLineItem(event: CdkDragDrop<ILineItemExtended, any>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    const newItems = this.items.map((item, lineIndex): ILineItem => {
      return {
        ...this.spentDistributionService.extendedItemToOriginal(item),
        row_number: lineIndex,
      };
    });

    this.store.dispatch(updateLineItemsBulk({ lineItems: newItems }));
  }

  dropCommittedItem(event: CdkDragDrop<ILineItem, any>, item: ILineItemExtended) {
    // todo review this logic for subitems
    const lastAllowedIndex = item.committed_items.findIndex(
      (item) => item.type === 'forecast_modification',
    );

    if (lastAllowedIndex !== -1 && event.currentIndex >= lastAllowedIndex) {
      event.currentIndex = lastAllowedIndex - 1;
    }

    moveItemInArray(item.committed_items, event.previousIndex, event.currentIndex);

    const committedItems = item.committed_items.map((committedItem, lineIndex): ICommittedItem => {
      return {
        ...this.spentDistributionService.extendedCommitmentItemToOriginal(committedItem),
        committed_row_number: lineIndex,
      };
    });

    this.store.dispatch(updateCommittedLineItemsBulk({ committedLineItems: committedItems }));
  }

  dropCommittedItemOnly(event: CdkDragDrop<ICommittedItemExtended, any>) {
    const lastAllowedIndex = this.committedItems.findIndex(
      (item) => item.type === 'forecast_modification',
    );

    if (lastAllowedIndex !== -1 && event.currentIndex >= lastAllowedIndex) {
      event.currentIndex = lastAllowedIndex - 1;
    }

    moveItemInArray(this.committedItems, event.previousIndex, event.currentIndex);

    const committedItems = this.committedItems.map((committedItem, lineIndex) => {
      return {
        ...this.spentDistributionService.extendedCommitmentItemToOriginal(committedItem),
        row_number: lineIndex,
      };
    });

    this.store.dispatch(updateCommittedLineItemsBulk({ committedLineItems: committedItems }));
  }

  dropSubitem($event: CdkDragDrop<IBudgetTemplateSubitemGC, any>, item: ILineItemExtended) {
    moveItemInArray(item.subitems, $event.previousIndex, $event.currentIndex);

    const newItem = DeepCopyService.deepCopy(item);
    newItem.subitems = newItem.subitems.map((subitem, index) => {
      return {
        ...subitem,
        row_number: index,
      };
    });
    this.store.dispatch(spendActionTypes.updateSubitemsBulk({ subitems: newItem.subitems }));
  }
}
