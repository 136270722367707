import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_BILLING_PLANS } from '../restApi/RestRoutes';
import {
  IInvoiceBackendResponse,
  ISubscribeResponse,
  ISubscriptionPlan,
} from '../framework/constants/subscription.constants';
import { NotificationsService } from './notifications.service';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly currentUser = inject(CurrentUserService);
  private readonly rest = inject(RestRequestService);
  private readonly notif = inject(NotificationsService);

  /**
   * Gets all available subscription plans.
   */
  getAllPlans(): Observable<ISubscriptionPlan[]> {
    const userType = this.currentUser?.data?.type?.name;
    const queryParams = userType
      ? { usertype: userType === 'manager' ? 'manager' : 'service_provider' }
      : {};
    return this.rest.getWithObservable(REST_BILLING_PLANS, {}, queryParams).pipe(
      map((plans: ISubscriptionPlan[]) =>
        plans.map((plan) => ({
          ...plan,
          stripe_monthly_price: (plan?.stripe_monthly_price ?? 0) / 100,
          stripe_yearly_price: (plan?.stripe_yearly_price ?? 0) / 100,
        })),
      ),
    );
  }

  /**
   * Gets the Stripe Customer Portal URL where users can manage their subscription. <br/>
   * Works only if a user has a Stripe Customer created (has customer_id).
   */
  getCustomerPortalUrl(): Observable<{ url: string }> {
    return this.rest.getWithObservable(`${REST_BILLING_PLANS}/portal`);
  }

  /**
   * Gets the customer portal url and opens it. <br/>
   * It handles notifications and errors.
   * @param newTab
   */
  openCustomerPortal(newTab = true) {
    this.notif.showLoading();

    this.getCustomerPortalUrl().subscribe(
      (res) => {
        if (!res.url) {
          console.error('no url returned from server');
          return;
        }

        window.open(res.url, newTab ? '_blank' : '_self');
      },
      (err) => {
        console.error('Error getting customer portal url', err);
        this.notif.showError(
          'An error occurred while trying to get the customer portal url. Please try again later.',
        );
      },
      () => {
        this.notif.close();
      },
    );
  }

  /*
   * Initiates the subscription process for a plan. <br/>
   * It will return a URL to the Stripe Checkout page where the user can complete the subscription.
   */
  subscribeToPlan(
    planId: number,
    type: 'monthly' | 'yearly' = 'monthly',
  ): Observable<ISubscribeResponse> {
    return this.rest.postWithObservable(`${REST_BILLING_PLANS}/${planId}/subscribe`, {
      type,
    });
  }

  getInvoices(page = 1, perPage = 10): Observable<IInvoiceBackendResponse> {
    return this.rest.getWithObservable(
      `${REST_BILLING_PLANS}/user/invoices`,
      {},
      { page, perPage },
    );
  }

  getAllSubscriptions(): Observable<any[]> {
    return this.rest.getWithObservable(`${REST_BILLING_PLANS}/user/subscriptions`);
  }
}
