import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, spendFeatureKey, SpendState } from './spend.reducer';
import {
  DistributionFieldType,
  IBudget,
  ICommittedItem,
  ICommittedItemExtended,
  IForecastBudget,
  ILastSpendStoreUpdate,
  ILineItem,
  ILineItemExtended,
  ILineItemsTotal,
  IMonthlySpendData,
  ISubitemExtended,
  SpendStoreUpdateTypes,
} from './spend.interfaces';
import {
  defaultMonthlyData,
  defaultMonthlyDisable,
  SPEND_TYPES,
} from '@app/framework/constants/spend.constants';
import { FiscalService } from '@app/services/fiscal.service';

export const spendFeatureSelector = createFeatureSelector<SpendState>(spendFeatureKey);

export const selectAllLineItems = createSelector(spendFeatureSelector, selectAll);

export const selectLineItemsAndSubitems = createSelector(selectAllLineItems, (lineItems) => {
  return lineItems.flatMap((item) => [item, ...item.subitems]);
});

export const getSelectedYear = createSelector(spendFeatureSelector, (state) => state.selectedYear);

export const getSpendDistributionState = createSelector(
  spendFeatureSelector,
  (state) => state.spendDistributionState,
);

export const getModifiedLineItems = createSelector(
  spendFeatureSelector,
  (state) =>
    ({
      modifiedLineItems: state.modifiedLineItems,
      newLineItems: state.newLineItems,
      deletedLineItems: state.deletedLineItems,
    }) as Partial<SpendState>,
);

export const getProjectStartDate = createSelector(
  spendFeatureSelector,
  (state) => state.projectStartDate,
);
export const getSelectedSpendType = createSelector(
  spendFeatureSelector,
  (state) => state.selectedSpendType,
);
export const getIsLoading = createSelector(spendFeatureSelector, (state) => state.isLoading);

export const getLineItemById = (id: number, searchSubitems = false) =>
  createSelector(selectAllLineItems, (items) => {
    if (searchSubitems) {
      return items.flatMap((item) => item.subitems).find((item) => item.id === id);
    }
    return items.find((item) => item.id === id);
  });

export const projectHasBudget = createSelector(selectAllLineItems, (lineItems) => {
  return lineItems.length > 0;
});

export const selectLineItemsExtended = createSelector(
  selectAllLineItems,
  getSelectedYear,
  (lineItems, selectedYear) => {
    const lineItemsSorted = lineItems.toSorted((a, b) => a.row_number - b.row_number);
    return lineItemsSorted.map((item) => {
      const lineItem = getLineItemExtended(item, selectedYear);
      lineItem.committed_items = lineItem.committed_items.sort(
        (a, b) => a.committed_row_number - b.committed_row_number,
      );
      if (lineItem.subitems) {
        lineItem.subitems = lineItem.subitems
          .map((subitem) => {
            return getLineItemExtended(subitem, selectedYear);
          })
          .toSorted((a, b) => a.row_number - b.row_number);
      }
      return lineItem;
    });
  },
);

export const selectPrimeLinesExtended = createSelector(
  selectAllLineItems,
  getSelectedYear,
  (lineItems, selectedYear) => {
    const primeLines = lineItems.filter((item) => item.used_in_pc);
    const lineItemsSorted = primeLines.toSorted((a, b) => a.row_number - b.row_number);
    return lineItemsSorted.map((item) => {
      return getPrimeLineExtended(item, selectedYear);
    });
  },
);

export const selectLineItemsAndSubitemsExtended = createSelector(
  selectLineItemsExtended,
  (lineItems) => {
    return lineItems.flatMap((item) => {
      return [item, ...item.subitems];
    });
  },
);

export const selectAllCommittedItems = createSelector(
  selectLineItemsAndSubitemsExtended,
  (items) => {
    return items
      .flatMap((item) =>
        item.committed_items.map((committedItem) => ({ ...committedItem, lineItem: item })),
      )
      .sort((a, b) => a.row_number - b.row_number);
  },
);

const getMonthlyDataForYear = (
  budget: IBudget[],
  selectedYear: number,
  budgetKey = 'monthly_budget',
): IMonthlySpendData => {
  const yearData = budget?.find((bud) => bud.year === selectedYear);
  return yearData?.[budgetKey] ?? { ...defaultMonthlyData };
};

const getMonthlyForecastForYear = (
  budget: IForecastBudget[],
  selectedYear: number,
): IMonthlySpendData => {
  const yearData = budget?.find((bud) => bud.year === selectedYear);
  return yearData?.monthly_forecast ?? { ...defaultMonthlyData };
};

export const getForecastProjectTotalPerItem = (item: ILineItem | ICommittedItem) => {
  return item.budget
    ?.map((bud) => bud?.monthly_forecast ?? { ...defaultMonthlyData })
    ?.reduce(
      (total, monthlySpendData) =>
        total +
        Object.values(monthlySpendData).reduce(
          (monthlyTotal: number, value: number) => monthlyTotal + value,
          0,
        ),
      0,
    );
};

const yearlyTotal = (monthlyData: IMonthlySpendData) => {
  return monthlyData ? Object.values(monthlyData).reduce((acc, curr) => acc + curr, 0) : 0;
};

export const projectTotalPerItem = (
  item: ILineItem | ICommittedItem,
  budgetKey: DistributionFieldType = 'monthly_budget',
) => {
  return item.budget
    ?.map((bud) => bud?.[budgetKey] ?? { ...defaultMonthlyData })
    ?.reduce(
      (total, monthlySpendData) =>
        total +
        Object.values(monthlySpendData).reduce(
          (monthlyTotal: number, value: number) => monthlyTotal + value,
          0,
        ),
      0,
    );
};

const mapCommittedItems = (selectedYear: number) => (committedItem: ICommittedItem) => {
  const commitedMonthlyData: IMonthlySpendData = getMonthlyForecastForYear(
    committedItem.budget,
    selectedYear,
  );
  return {
    ...committedItem,
    monthly_data: commitedMonthlyData,
    monthly_disable: { ...defaultMonthlyDisable },
    year_total: yearlyTotal(commitedMonthlyData),
    project_total: getForecastProjectTotalPerItem(committedItem),
  };
};

const getLineItemExtended = (item: ILineItem, selectedYear: number) => {
  const monthlyData: IMonthlySpendData = getMonthlyDataForYear(item.budget, selectedYear);
  const yearTotal = yearlyTotal(monthlyData);
  const projectTotal = projectTotalPerItem(item);
  const lineItem: ILineItemExtended = {
    ...item,
    year_total: yearTotal,
    monthly_data: monthlyData,
    monthly_disable: { ...defaultMonthlyDisable },
    project_total: projectTotal,
    committed_items: item.committed_items.map(mapCommittedItems(selectedYear)),
    subitems: !item.subitems
      ? null
      : item.subitems.map((subitem) => {
          // todo rename
          const subItemMonthlyData: IMonthlySpendData = getMonthlyDataForYear(
            subitem.budget,
            selectedYear,
          );
          return {
            ...subitem,
            committed_items: subitem.committed_items.map(mapCommittedItems(selectedYear)),
            subitems: [],
            monthly_data: subItemMonthlyData,
            monthly_disable: { ...defaultMonthlyDisable },
            year_total: yearlyTotal(subItemMonthlyData),
            project_total: getForecastProjectTotalPerItem(subitem),
          };
        }),
  };

  return lineItem;
};

const getPrimeLineExtended = (item: ILineItem, selectedYear: number) => {
  const monthlyData: IMonthlySpendData = getMonthlyDataForYear(
    item.budget,
    selectedYear,
    'monthly_prime',
  );
  const yearTotal = yearlyTotal(monthlyData);
  const projectTotal = projectTotalPerItem(item, 'monthly_prime');
  const lineItem: ILineItemExtended = {
    ...item,
    year_total: yearTotal,
    monthly_data: monthlyData,
    monthly_disable: { ...defaultMonthlyDisable },
    project_total: projectTotal,
    committed_items: [],
    subitems: [],
  };

  return lineItem;
};

const getTotalsForOneItem = (
  item: ILineItemExtended | ICommittedItemExtended,
  total: ILineItemsTotal,
) => {
  total.project_total += item.project_total ? item.project_total : 0;
  total.year_total += item.year_total ? item.year_total : 0;
  Object.keys(total.monthly_data).forEach((monthKey) => {
    total.monthly_data[monthKey] += item.monthly_data?.[monthKey] ? item.monthly_data[monthKey] : 0;
  });

  return total;
};

export const selectLineItemTotals = createSelector(
  selectLineItemsExtended,
  selectPrimeLinesExtended,
  getSelectedSpendType,
  (lineItems, primeLines, selectedSpendType) => {
    const total: ILineItemsTotal = {
      project_total: 0,
      year_total: 0,
      monthly_data: { ...defaultMonthlyData },
    };

    if (
      selectedSpendType === SPEND_TYPES.BUDGET ||
      selectedSpendType === SPEND_TYPES.BUDGET_PARENT_ONLY ||
      selectedSpendType === SPEND_TYPES.BUDGET_PARENT_CHILD
    ) {
      return lineItems.reduce((acc, item) => getTotalsForOneItem(item, acc), total);
    }

    if (selectedSpendType === SPEND_TYPES.PRIME_CONTRACT) {
      return primeLines.reduce((acc, item) => getTotalsForOneItem(item, acc), total);
    }

    if (selectedSpendType === SPEND_TYPES.COMMITMENTS) {
      return lineItems.reduce((allCommittedTotals, lineItem) => {
        // first calculate the total for the parent lines
        const lineItemsCommittedTotal = lineItem.committed_items.reduce(
          (lineItemCommittedTotals, committedItem) => {
            return getTotalsForOneItem(committedItem, lineItemCommittedTotals);
          },
          allCommittedTotals,
        );

        // then add the subitems' committed items' totals
        return lineItem.subitems.reduce((subitemTotals, subitem) => {
          return subitem.committed_items.reduce((subitemCommittedTotals, committedItem) => {
            return getTotalsForOneItem(committedItem, subitemCommittedTotals);
          }, subitemTotals);
        }, lineItemsCommittedTotal);
      }, total);
    }

    return total;
  },
);

// return the newly added years which are not be present in line item budget data
export const getNewYears = createSelector(spendFeatureSelector, (state) => state.newYears);

// returns all the years that can be selected in the dropdown
export const getSelectableYears = createSelector(
  selectAllLineItems,
  getNewYears,
  (lineItems, newYears) => {
    return getAllPossibleYearsFunction(lineItems, newYears);
  },
);

const getLastStoreUpdate = createSelector(spendFeatureSelector, (state) => state.lastStoreUpdate);

export const getNextOrder = createSelector(selectAllLineItems, (lineItems): number => {
  if (!lineItems.length) {
    return 1;
  }

  const maxOrder = Math.max(...lineItems.map((item) => item?.row_number ?? 0));
  return maxOrder + 1;
});

export const getStoreUpdates = createSelector(
  selectLineItemsAndSubitemsExtended, // todo [2024-10-14]: it should not recalculate everything
  getLastStoreUpdate,
  (extendedLineItems, update): ILastSpendStoreUpdate => {
    let lineItems: ILineItemExtended[] = [];
    if (
      update.type === SpendStoreUpdateTypes.SET_ALL ||
      update.type === SpendStoreUpdateTypes.FILTER_CHANGE
    ) {
      lineItems = extendedLineItems.filter((it) => !(it as ISubitemExtended).is_subitem);
    } else if (
      update.type === SpendStoreUpdateTypes.DELETE ||
      update.type === SpendStoreUpdateTypes.NONE
    ) {
      lineItems = [];
    } else if (update.type === SpendStoreUpdateTypes.UPDATE_SUBITEM) {
      const item = extendedLineItems.find((lineItem) => lineItem.id === update.lineId);
      if (!item) {
        console.error('getStoreUpdates: could not find line item with id', update.lineId);
        return {
          lastStoreUpdate: update,
          lineItems: [],
        };
      }
      const subitem = item.subitems.find((subitem) => subitem.id === update.subitemId);
      lineItems.push(item, subitem);
    } else {
      const item = extendedLineItems.find((lineItem) => lineItem.id === update.lineId);
      lineItems.push(item);
    }

    return {
      lastStoreUpdate: update,
      lineItems,
    };
  },
);

// mixes together the years from line items and years added added by the user
// returns an ascending continuous array (no gaps between years)
export const getAllPossibleYearsFunction = (lineItems: ILineItem[], newYears: number[]) => {
  const possibleYears = getLineItemPossibleYearsFunction(lineItems);
  if (possibleYears?.[0] === undefined) {
    return [];
  }
  const allYears = possibleYears.concat(newYears);
  const minYear = allYears.reduce((acc, value) => (acc > value ? value : acc), allYears[0]);
  const maxYear = allYears.reduce((acc, value) => (acc < value ? value : acc), allYears[0]);
  return new Array(maxYear - minYear + 1).fill(0).map((__, index) => minYear + index);
};

// returns the years present in lineItem.budget
export const getLineItemPossibleYearsFunction = (lineItems: ILineItem[]) => {
  if (lineItems.length === 0) {
    return [FiscalService.fiscalYear];
  }
  const allYears = lineItems
    .map((item) => item.budget.map((budget) => budget.year))
    .reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);

  return [...new Set(allYears)].sort((a, b) => a - b);
};

/**
 * @deprecated - we use the hasCommitments selector instead for disabling things
 */
export const getDisableBasedOnTemplate = createSelector(spendFeatureSelector, (state) => {
  return state.hasTemplates;
});

export const getSpendLineItemSummary = createSelector(spendFeatureSelector, (state) => {
  return state.spendLineItemSummary;
});

export const selectHasCommitments = createSelector(selectLineItemsAndSubitems, (lineItems) => {
  return lineItems.some((lineItem) => !!lineItem.commitment_start_date);
});

/**
 * returns true if there are any line items with a prime budget having any value > 0
 */
export const selectHasPrimeLines = createSelector(selectLineItemsAndSubitems, (lineItems) => {
  return lineItems.some((lineItem) => lineItem.used_in_pc);
});

export const selectSpendViews = (isManager: boolean) =>
  createSelector(
    spendFeatureSelector,
    selectHasCommitments,
    selectHasPrimeLines,
    (state, hasCommitments, hasPrimeLines) => {
      let views: SPEND_TYPES[];
      if (isManager) {
        views = [SPEND_TYPES.BUDGET];
        if (hasCommitments) {
          views.push(SPEND_TYPES.BUDGET_COMMITMENTS);
          views.push(SPEND_TYPES.COMMITMENTS);
        }
      } else {
        views = [SPEND_TYPES.BUDGET_PARENT_ONLY, SPEND_TYPES.BUDGET_PARENT_CHILD];
        if (hasCommitments) {
          views.push(SPEND_TYPES.BUDGET_COMMITMENTS_GC);
          views.push(SPEND_TYPES.COMMITMENTS);
        }
        if (hasPrimeLines) {
          views.push(SPEND_TYPES.PRIME_CONTRACT);
        }
      }
      return views;
    },
  );

export const selectAllSpendSubItems = createSelector(selectAllLineItems, (lineItems) => {
  const allSubitems = lineItems.flatMap((item) => item.subitems);
  return allSubitems.map((item) => {
    return {
      id: item.id,
      name: item.name,
      division: item.division,
      cost_type: item.cost_type,
      value: item.original_budget_total,
    };
  });
});
