import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormsModule, NgModel } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { InputTextModule } from 'primeng/inputtext';
import { NgScrollbar } from 'ngx-scrollbar';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { CommitmentSidebarComponent } from '../commitment-sidebar/commitment-sidebar.component';
import { AppState } from '../../../../store/app-state';
import { NotificationsService } from '../../../../services/notifications.service';
import { StorageService } from '../../../../services/storage.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import {
  COMPANY_DROPDOWN_SELECT_TYPES,
  CompanyDropdownComponent,
  ICompanyDropdownSelectEvent,
} from '../company-dropdown/company-dropdown.component';
import {
  commitmentCombinedSelectors,
  commitmentsSelectors,
} from '../../../../store/commitments/commitments.selectors';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import {
  CHANGE_ORDER_TYPE,
  changeOrderTypeOptions,
  defaultChangeOrder,
  defaultCost,
} from '../../../../store/commitments/commitments.constants';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { fadeInGeneral } from '../../../../../assets/styles/animations';
import {
  COMMITMENT_APPROVAL_STATUS,
  ISidebarChangeOrder,
  ISidebarCommitmentItem,
} from '../../../../store/commitments/commitments.types';
import { COMMITMENT_STATUS } from '../../commitments-interaction-bar-view/commitments-interaction-bar-view.utilities';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { SpendEntryComponent } from '../spend-entry/spend-entry.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { OverlayGeneralComponent } from '../../../overlays/overlay-general/overlay-general.component';
import { ArrowButtonBoxComponent } from '../../../dropdown-button-box/arrow-button-box.component';
import { UploadCommitmentsSidebarComponent } from '../upload-commitments-sidebar/upload-commitments-sidebar.component';
import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { CommitmentsSidebarHelpComponent } from '../commitments-sidebar-help/commitments-sidebar-help.component';
import { CommitmentSidebarTitleComponent } from '../commitment-sidebar-title/commitment-sidebar-title.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingTextareaComponent } from '../../../inputs/floating-textarea/floating-textarea.component';

@Component({
  selector: 'app-change-order-sidebar',
  templateUrl: './change-order-sidebar.component.html',
  styleUrls: [
    './change-order-sidebar.component.scss',
    '../commitment-sidebar/commitment-sidebar.component.scss',
  ],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [
    NgIf,
    CommitmentSidebarTitleComponent,
    CommitmentsSidebarHelpComponent,
    CommitmentEntryTextComponent,
    NgScrollbar,
    FormsModule,
    UploadCommitmentsSidebarComponent,
    InputTextModule,
    CdkOverlayOrigin,
    NgClass,
    OverlayGeneralComponent,
    CompanyDropdownComponent,
    DropdownModule,
    InputCalendarComponent,
    InputTextareaModule,
    NgFor,
    SpendEntryComponent,
    AsyncPipe,
    DecimalPipe,
    MoneyPipe,
    SimpleButtonComponent,
    FloatingInputComponent,
    DropdownComponent,
    FloatingTextareaComponent,
  ],
})
export class ChangeOrderSidebarComponent
  extends CommitmentSidebarComponent
  implements AfterViewInit
{
  totalChangeOrderCost$ = this.store.select(commitmentsSelectors.totalChangeOrderCostDescription);
  sidebarChangeOrder$ = this.store.select(commitmentsSelectors.getSideBarChangeOrder);
  changeOrderEntries$ = this.store.select(commitmentsSelectors.getChangeOrderEntries);
  canAddChangeOrderSpendEntries$ = this.store.select(
    commitmentCombinedSelectors.canAddChangeOrderSpendEntries,
  );

  spendEntriesData: ISidebarCommitmentItem[] = [];
  isDropdownShown = {
    contract_id: false,
    approval_status: false,
    service_provider: false,
  };
  model: Partial<ISidebarChangeOrder> = {
    ...defaultChangeOrder,
  };
  commitmentType = COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER;

  protected readonly COMMITMENT_APPROVAL_STATUS = COMMITMENT_APPROVAL_STATUS;
  protected readonly CHANGE_ORDER_TYPE = CHANGE_ORDER_TYPE;
  showHelp: boolean;
  changeOrderTypeOptions = [...changeOrderTypeOptions];

  constructor(
    protected store: Store<AppState>,
    protected notif: NotificationsService,
    protected storage: StorageService,
    protected user: CurrentUserService,
    protected interactionBar: InteractionBarStateService,
  ) {
    super(store, notif, storage, user, interactionBar);
  }

  ngAfterViewInit() {
    this.sidebarChangeOrder$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      if (data) {
        this.model = DeepCopyService.deepCopy(data);
        console.log('this.model', this.model);
        this.isEdit = !!data.id;
        if (!this.isEdit || this.model.change_order_type !== CHANGE_ORDER_TYPE.DEFAULT) {
          // you cannot select DEFAULT, it's only for old change orders
          this.changeOrderTypeOptions = changeOrderTypeOptions.filter(
            (type) => type.value !== CHANGE_ORDER_TYPE.DEFAULT,
          );
        } else {
          this.changeOrderTypeOptions = [...changeOrderTypeOptions];
        }
        this.filterContractsBySelectedCompany();
      }
    });

    this.changeOrderEntries$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.spendEntriesData = DeepCopyService.deepCopy(data);
    });
  }

  updateChangeOrderDate(date: string) {
    console.log('new date', date);
    this.store.dispatch(
      commitmentsActions.updateSelectedChangeOrder({
        change_order: { ...this.model, added_at: date },
      }),
    );
  }

  updateChangeOrder(field?: string) {
    if (field === 'change_order_type') {
      for (const spendEntry of this.model.commitment_items) {
        // if change_order_type has changed to DOLLARS, set duration to default value and remove costs without a parent_id
        if (this.model.change_order_type === CHANGE_ORDER_TYPE.DOLLARS) {
          spendEntry.duration = 0;
          spendEntry.costs = spendEntry.costs.filter((cost) => !!cost.parent_id);
        }
        // if change_order_type has changed to DURATION, remove costs
        if (this.model.change_order_type === CHANGE_ORDER_TYPE.DURATION) {
          spendEntry.costs = [];
        }
        // if change_order_type has changed to DOLLARS_DURATION, remove costs without a parent_id
        if (this.model.change_order_type === CHANGE_ORDER_TYPE.DOLLARS_DURATION) {
          spendEntry.costs = spendEntry.costs.filter((cost) => !!cost.parent_id);
        }
        // if change_order_type has changed to SCOPE, remove costs with a parent_id which are saved too
        if (this.model.change_order_type === CHANGE_ORDER_TYPE.SCOPE) {
          spendEntry.costs = spendEntry.costs.filter((cost) => !cost.parent_id && !!cost.id);
        }
        if (
          !spendEntry.costs?.length &&
          this.model.change_order_type !== CHANGE_ORDER_TYPE.DURATION
        ) {
          spendEntry.costs = [{ ...defaultCost }];
        }
      }
    }
    this.store.dispatch(
      commitmentsActions.updateSelectedChangeOrder({
        change_order: this.model,
      }),
    );
  }

  selectedCompany(event: ICompanyDropdownSelectEvent, serviceProviderModel: NgModel) {
    switch (event.type) {
      case COMPANY_DROPDOWN_SELECT_TYPES.SELECT: {
        this.model.service_provider = {
          name: event.data.company_name,
        };
        if (event.isTemporaryContractor) {
          this.model.service_provider.temporary_service_provider_id = event.data.id;
        } else {
          this.model.service_provider.service_provider_user_id = event.data.id;
        }

        this.model.contract_id = null;
        this.formFirstPage?.form?.get('contract_id')?.markAsUntouched();
        this.formFirstPage?.form?.get('contract_id')?.markAsPristine();

        this.updateChangeOrder();
        break;
      }
      default: {
        console.warn('Action not handled', event);
        break;
      }
    }
    this.isDropdownShown.service_provider = false;
  }

  addSpendEntry() {
    this.canAddChangeOrderSpendEntries$.pipe(take(1)).subscribe((canAdd) => {
      if (canAdd) {
        this.store.dispatch(commitmentsActions.addChangeOrderSpendEntry());
      }
    });
  }

  saveOrModify() {
    if (this.model.id) {
      this.notif.showLoading('Updating Change Order...');
      this.store.dispatch(commitmentsActions.modifyChangeOrder());
      return;
    }
    this.notif.showLoading('Saving Change Order...');
    this.store.dispatch(commitmentsActions.saveChangeOrder());
  }

  getApprovalStatuses(): COMMITMENT_STATUS[] {
    const statuses = [COMMITMENT_STATUS.REVIEW];
    if (this.user.isManagerLike || this.model?.approval_status === COMMITMENT_STATUS.APPROVED) {
      statuses.push(COMMITMENT_STATUS.APPROVED);
    }
    if (this.user.isManagerLike || this.model?.approval_status === COMMITMENT_STATUS.PENDING) {
      statuses.push(COMMITMENT_STATUS.PENDING);
    }
    return statuses;
  }

  loadContractSummaryDetails() {
    console.log(this.model.contract_id);
    this.store.dispatch(
      commitmentsActions.loadSidebarSelectedContractSummary({ contractId: this.model.contract_id }),
    );
  }

  needHelpClicked(showHelp: boolean) {
    this.showHelp = showHelp;
  }
}
