<div class="bud-template-column">
  <ng-scrollbar class="std-scrollbar" appearance="native" orientation="vertical">
    @for (template of templates$ | async; track template.id) {
      <div
        (click)="selectTemplate(template.id)"
        class="template-item"
        [ngClass]="{
          selected: template?.id === (selectedTemplate | async)?.id,
          'no-hover': template.is_edit
        }"
        data-cy="template-item"
      >
        <app-template-input [templateView]="templateType" [templateId]="template.id"></app-template-input>
      </div>
    }
  </ng-scrollbar>
</div>
