import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { TooltipModule } from 'primeng/tooltip';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import {
  getEmptySpendDescription,
  getEmptySpendEntry,
} from '@app/store/prime-commitments/prime-commitments.constants';
import { MoneyPipe } from '@app/pipes/framework/money-short.pipe';
import {
  IPrimeContractModel,
  IPrimeLineItemResponse,
} from '@app/store/prime-commitments/prime-commitments.types';
import { CommitmentEntryTextComponent } from '@app/framework/commitments/sidebar/commitment-entry-text/commitment-entry-text.component';
import { CommitmentSidebarTitleComponent } from '@app/framework/commitments/sidebar/commitment-sidebar-title/commitment-sidebar-title.component';
import { selectAllSpendSubItems } from '@app/store/spend/spend.selectors';
import { primeSidebarActions } from '@app/store/prime-commitments/prime-commitments.actions';

@Component({
  selector: 'app-prime-contract-values',
  standalone: true,
  imports: [
    AddRemoveButtonsComponent,
    FloatingInputComponent,
    DropdownComponent,
    FormsModule,
    AsyncPipe,
    SimpleButtonComponent,
    MoneyPipe,
    NgScrollbar,
    CommitmentEntryTextComponent,
    CommitmentSidebarTitleComponent,
    TooltipModule,
  ],
  templateUrl: './prime-contract-values.component.html',
  styleUrl: './prime-contract-values.component.scss',
})
export class PrimeContractValuesComponent {
  private readonly store = inject(Store);

  @ViewChild('spendEntriesPage') primeValuesForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();

  allLineItems$: Observable<IPrimeLineItemResponse[]> = this.store.select(
    primeCommitmentsSelectors.selectAllLineItems,
  );

  allBudgetSublines$ = this.store.select(selectAllSpendSubItems);

  /**
   * Adds all line items from the budget / cashflow allocation to the model.
   */
  addValuesFromBudget() {
    this.allBudgetSublines$.pipe(take(1)).subscribe((lineItems) => {
      const newlyAllocatedSpendEntries = [];
      lineItems.forEach((item) => {
        newlyAllocatedSpendEntries.push({
          ...getEmptySpendEntry(),
          item_id: item.id,
          spend_descriptions: [{ ...getEmptySpendDescription(), value: item.value }],
        });
      });
      this.model.spendEntries = newlyAllocatedSpendEntries;
    });
  }

  onLineItemChange(id: number, index: number) {
    this.model.spendEntries[index].item_id = id;
  }

  addEmptySpendEntry() {
    this.model.spendEntries.push(getEmptySpendEntry());
    this.setSpendEntriesIndex();
  }

  removeSpendDescription(spendEntryIndex: number, index: number) {
    if (this.model.spendEntries.length === 1) {
      return;
    }

    this.model.spendEntries[spendEntryIndex].spend_descriptions.splice(index, 1);
    this.setSpendDescriptionIndex(spendEntryIndex);
  }

  addSpendDescription(spendEntryIndex: number, descriptionIndex: number) {
    this.model.spendEntries[spendEntryIndex].spend_descriptions.splice(descriptionIndex + 1, 0, {
      ...getEmptySpendDescription(),
      index: descriptionIndex,
    });
    this.setSpendDescriptionIndex(spendEntryIndex);
  }

  setSpendDescriptionIndex(spendEntryIndex: number) {
    this.model.spendEntries[spendEntryIndex].spend_descriptions.map((item, i) => {
      return {
        ...item,
        index: i,
      };
    });
  }

  isFormValid() {
    this.primeValuesForm.form.markAllAsTouched();
    return this.primeValuesForm.form.valid;
  }

  goToNextPage() {
    if (!this.isFormValid()) {
      return;
    }

    this.store.dispatch(primeSidebarActions.onClickNext());
  }

  removeEntry(index: number) {
    this.model.spendEntries.splice(index, 1);
    this.setSpendEntriesIndex();
  }

  setSpendEntriesIndex() {
    this.model.spendEntries = this.model.spendEntries.map((item, i) => {
      return {
        ...item,
        index: i,
      };
    });
  }

  goBack() {
    this.store.dispatch(primeSidebarActions.onPrimeContactViewPreviousPage());
  }

  get totals() {
    return this.model.spendEntries.reduce((acc, spendEntry) => {
      return (
        Number(acc) +
        spendEntry.spend_descriptions.reduce((acc, description) => {
          return Number(acc) + Number(description.value);
        }, 0)
      );
    }, 0);
  }
}
