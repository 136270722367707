<div class="sidebar-header-wrapper">
  <div
    (click)="backButtonClicked.emit()"
    class="small-title flex items-center gap-2"
    [ngClass]="{ 'cursor-pointer': showBackButton }"
  >
    @if (showBackButton) {
      <app-bordered-dropdown-icon [isClosed]="true" useAsBackButton="true"></app-bordered-dropdown-icon>
    }
    <ng-content select="[smallTitle]"></ng-content>
  </div>

  <div class="main-title">
    <ng-content select="[mainTitle]"></ng-content>
  </div>
</div>
