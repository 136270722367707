import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { AsyncPipe, NgClass } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import dayjs from 'dayjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { PageLoadingComponent } from '../../../page-loading/page-loading.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { ProjectSetupBaseComponent } from '../project-setup-base.component';
import { projectActions } from '../../../../store/projects/projects.actions';
import { GCProject } from '../../../../pages/webapp/projects/projects.interface';
import US_STATES from '../../../constants/states.constants';

@Component({
  selector: 'app-project-setup-gc',
  standalone: true,
  imports: [
    FormsModule,
    TooltipModule,
    AsyncPipe,
    PageLoadingComponent,
    NgScrollbar,
    NgClass,
    FloatingInputComponent,
    InputCalendarComponent,
    DropdownComponent,
  ],
  templateUrl: './project-setup-gc.component.html',
  styleUrl: './project-setup-gc.component.scss',
})
export class ProjectSetupGcComponent extends ProjectSetupBaseComponent implements OnInit {
  @Input() isEdit: boolean;
  @ViewChild('setupForm') setupForm: NgForm;

  protected readonly states = US_STATES;
  // to be updated with the actual value from response
  hasCommitments = false;

  model: GCProject = {
    id: null,
    auto_generated_project_id: null,
    custom_project_id: '',
    title: '',
    start_date: null,
    anticipated_completion_date: null,
    location: '',
    city: '',
    status: null,
    address: '',
    state: '',
  };

  customIdEditable = false;
  originalCustomId = null;
  hasCustomId = false;

  ngOnInit() {
    super.ngOnInit();
    this.actions
      .pipe(takeUntil(this.isDestroyed$), ofType(projectActions.editProjectDataLoaded), delay(100))
      .subscribe((action) => {
        const project = action.project as GCProject;
        this.model = {
          address: project.address,
          anticipated_completion_date: project.anticipated_completion_date,
          auto_generated_project_id: project.auto_generated_project_id,
          city: project.city,
          location: project.location,
          state: project.state,
          id: action.project.id,
          status: action.project.status,
          title: action.project.title,
          start_date: action.project.start_date,
          custom_project_id:
            action.project.custom_project_id ?? action.project.auto_generated_project_id,
        };
        // can edit template if no commitments
        // this.hasCommitments = action.project.has_commitments_or_mods;
        this.originalCustomId = action.project.auto_generated_project_id;
        this.hasCustomId =
          action.project.custom_project_id !== action.project.auto_generated_project_id;
        if (this.hasCustomId && !this.hasCommitments) {
          this.customIdEditable = true;
        }
      });
  }

  updateProjectId({ year }: { year?: number }) {
    if (!year) {
      return;
    }

    this.projectService.getNextProjectId({ property_id: null, year }).subscribe((response) => {
      this.model.custom_project_id = response.next_custom_id;
      this.originalCustomId = response.next_custom_id;
    });
  }

  startDateChanged(date: string) {
    if (!this.isEdit) {
      this.updateProjectId({ year: dayjs(date).year() });
    }
  }
}
