@switch (currentPage) {
  @case (1) {
    <app-change-action-general
      (next)="incrementPage()"
      (back)="decrementPage()"
      (updateForm)="updateForm()"
      [(model)]="model.generalData"
      (scopeChange)="clearLines()"
    ></app-change-action-general>
  }
  @case (2) {
    @if (model.generalData.type === 'Potential Change Order') {
      <app-potential-change-order-sidebar
        [name]="model.generalData.number"
        [scope]="model.generalData.scope"
        (saveForm)="savePCO()"
        (back)="decrementPage()"
        [(lineItems)]="model.lineItems"
      ></app-potential-change-order-sidebar>
    } @else {
      <app-change-order-request-sidebar
        [name]="model.generalData.number"
        (saveForm)="saveCOR()"
        (back)="decrementPage()"
        [isEdit]="data?.isEdit"
        [CORId]="model.generalData?.id ?? null"
        [(selectedPCOIds)]="model.selectedPCOIds"
      >
      </app-change-order-request-sidebar>
    }
  }
}
