import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  repeat,
  switchMap,
  tap,
} from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import * as uuid from 'uuid';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { NotificationsService } from '../../services/notifications.service';
import { PresignedFileUploadService } from '../../services/presigned-file-upload.service';
import { PrimeCommitmentsApiService } from '../../services/prime-commitments-api.service';
import { viewProjectSelectors } from '../view-project/view-project.selectors';
import { DeepCopyService } from '../../services/deep-copy.service';
import { viewProjectActions } from '../view-project/view-project.actions';
import {
  primeChangesActions,
  primePCODetailsSidebarActions,
  primeSidebarActions,
  primeSidebarFailActions,
} from './prime-commitments.actions';
import { PCODetail } from './prime-commitments.types';
import { primeCommitmentsDetailsSelectors } from './prime-commtiments.selectors';

@Injectable()
export class PrimeCommitmentsEffects {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  private readonly notif = inject(NotificationsService);
  private readonly presignedFileUploadService = inject(PresignedFileUploadService);
  private readonly primeApiService = inject(PrimeCommitmentsApiService);
  private readonly interactionBar = inject(InteractionBarStateService);

  onPrimeSidebarOpen = createEffect(() => {
    return this.actions.pipe(
      ofType(
        primeSidebarActions.onPrimeContractSidebarOpen,
        primeChangesActions.onChangeActionSidebarOpen, // TODO: CHECK IF THIS IS NEEDED
        primePCODetailsSidebarActions.onPCODetailsAccessed, //TODO: CHECK IF THIS IS NEEDED
      ),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([_, projectId]) => {
        return this.primeApiService.getTemplateSublines(projectId);
      }),
      map((lineItems) =>
        primeSidebarActions.lineItemsLoaded({
          markupLineItems: lineItems.filter((item) => !!item.is_co_markup),
          nonMarkupLineItems: lineItems.filter((item) => !item.is_co_markup),
        }),
      ),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitments',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitments!");
        }
        return of(primeSidebarFailActions.onFailedToLoadLineItems());
      }),
      repeat(),
    );
  });

  onChangesSidebarOpen = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onChangeActionSidebarOpen),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([_, projectId]) => {
        const pcoNumber = this.primeApiService.getNextPCONumber(projectId);
        const corNumber = this.primeApiService.getNextCORNumber(projectId);
        return forkJoin([pcoNumber, corNumber]);
      }),
      map(([pcoNumberRequest, corNumberRequest]) =>
        primeChangesActions.nextPCOCORNumbersLoaded({
          nextPCONumber: pcoNumberRequest.next_id,
          nextCORNumber: corNumberRequest.next_id,
        }),
      ),
      repeat(),
    );
  });

  onChangesSidebarOpenLoadPrimeContract = createEffect(() => {
    return this.actions.pipe(
      ofType(
        primeChangesActions.onChangeActionSidebarOpen,
        primeSidebarActions.primeContractSaved,
        primePCODetailsSidebarActions.onPCODetailsAccessed,
      ),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([_, projectId]) => {
        return this.primeApiService.loadPrimeContractByProjectId(projectId);
      }),
      map((primeContract) =>
        primeChangesActions.onPrimeContractLoaded({
          response: primeContract,
        }),
      ),
      catchError((_) => {
        this.notif.showError('This project does not have a prime contract.');
        return of(
          primeChangesActions.onPrimeContractLoaded({
            response: null,
          }),
        );
      }),
      repeat(),
    );
  });

  onRequestSCO = createEffect(() => {
    return this.actions.pipe(
      ofType(primePCODetailsSidebarActions.onRequestSCO),
      switchMap((action) => {
        return this.primeApiService.requestSCO(action.contractId, action.costId);
      }),
      delay(1000),
      map((response) => {
        console.warn(response);
        this.notif.showSuccess('SCO Request Created and Sent to Subcontractor for Review');
        return primePCODetailsSidebarActions.onSCORequested();
      }),
      catchError((error) => {
        this.notif.showError(error?.error?.message || 'An error occurred while deleting PCO');
        return of(primeSidebarFailActions.onFailedToSavePCO());
      }),
      repeat(),
    );
  });

  onPCOLogDelete = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onDeletePCO),
      switchMap((action) => {
        return this.primeApiService.deletePCOLogItem(action.id);
      }),
      tap(() => {
        this.notif.showSuccess('Successfully deleted');
      }),
      delay(1000),
      map(() => {
        this.interactionBar.close();
        return primePCODetailsSidebarActions.onPCOLogDeletedSuccessfully();
      }),
      catchError((error) => {
        this.notif.showError(error?.error?.message || 'An error occurred while deleting PCO');
        return of(primeSidebarFailActions.onFailedToSavePCO());
      }),
      repeat(),
    );
  });

  onCORLogDelete = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onDeleteCOR),
      switchMap((action) => {
        return this.primeApiService.deleteCORLogItem(action.id);
      }),
      tap(() => {
        this.notif.showSuccess('Successfully deleted');
      }),
      delay(1000),
      map(() => {
        this.interactionBar.close();
        return primePCODetailsSidebarActions.onPCOLogDeletedSuccessfully();
      }),
      catchError((error) => {
        this.notif.showError(error?.error?.message || 'An error occurred while deleting PCO');
        return of(primeSidebarFailActions.onFailedToSavePCO());
      }),
      repeat(),
    );
  });

  onPCONumberChange = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onPCONumberChange),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        return this.primeApiService.isPCONumberUnique(projectId, action.number);
      }),
      map((_) => primeChangesActions.PCONumberValidated({ isValid: true })),
      catchError((_) => {
        return of(primeChangesActions.PCONumberValidated({ isValid: false }));
      }),
      repeat(),
    );
  });

  onCORNumberChange = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onCORNumberChange),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        return this.primeApiService.isCORNumberUnique(projectId, action.number);
      }),
      map((_) => primeChangesActions.CORNumberValidated({ isValid: true })),
      catchError((_) => {
        return of(primeChangesActions.CORNumberValidated({ isValid: false }));
      }),
      repeat(),
    );
  });

  onPrimeContractSaved = createEffect(
    () => {
      return this.actions.pipe(
        ofType(primeSidebarActions.primeContractSaved),
        tap(() => {
          this.notif.showSuccess('Prime contract saved successfully');
        }),
        delay(500),
        tap(() => {
          this.interactionBar.close();
        }),
        delay(2000),
        concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
        tap(([data, projectID]) => {
          console.warn(data);
          this.interactionBar.editPrimeContract(projectID);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  onPrimeContractSubmit = createEffect(() => {
    return this.actions.pipe(
      ofType(primeSidebarActions.onPrimeContractSubmit, primeSidebarActions.onPrimeContractUpdate),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        if (action.type === primeSidebarActions.onPrimeContractUpdate.type) {
          return this.primeApiService.updatePrimeContract(projectId, action.primeContract);
        }
        return this.primeApiService.createPrimeContract(projectId, action.primeContract);
      }),
      map((response) => primeSidebarActions.primeContractSaved(response)),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while submitting prime contract',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't submit prime contract!");
        }
        return of(primeSidebarFailActions.onFailedToSubmitPrimeContract());
      }),
      repeat(),
    );
  });

  onPCOSubmit = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onPCOSubmit),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) =>
        this.primeApiService.createPCO(projectId, action.model).pipe(
          map(() => {
            this.notif.showSuccess('Potential change order saved successfully');
            this.interactionBar.close();
            return primeChangesActions.onPCOSaved();
          }),
          catchError((error) => {
            console.error(error);
            const errorMessage = error?.error?.message || 'An error occurred while submitting';
            this.notif.showError(errorMessage);
            return of(primeSidebarFailActions.onFailedToSaveCOR());
          }),
        ),
      ),
    );
  });

  onCORSubmit = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onCORSubmit),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        const request$ = action.model.generalData.id
          ? this.primeApiService.updateCOR(projectId, action.model)
          : this.primeApiService.createCOR(projectId, action.model);
        return request$.pipe(
          map(() => {
            this.notif.showSuccess(
              `Change order ${action.model.generalData.id ? 'updated' : 'saved'} successfully`,
            );
            this.interactionBar.close();
            return primeChangesActions.onCORSaved();
          }),
          catchError((error) => {
            console.error(error);
            const errorMessage =
              error?.error?.message || 'An error occurred while submitting potential change order';
            this.notif.showError(errorMessage);
            return of(primeSidebarFailActions.onFailedToSubmitPrimeContract());
          }),
        );
      }),
    );
  });

  PCOListingLoad = createEffect(() => {
    return this.actions.pipe(
      ofType(
        // viewProjectActions.selectedProjectChanged,
        primeChangesActions.onCORListingAccess, // PCOs have to load because they are used in COR edit sidebar 2nd page
        primeChangesActions.onPCOListingAccess,
        primePCODetailsSidebarActions.onPCODetailsSidebarClosed,
        primeChangesActions.onPCOSaved,
        primePCODetailsSidebarActions.onPCOLogDeletedSuccessfully,
        primePCODetailsSidebarActions.onPCOChangeActionFirstPageSynced,
      ),
      filter((action) => {
        // if editChangeActionTriggered is true, then don't load PCOs because an another interaction bar was opened
        return !(
          action.type === primePCODetailsSidebarActions.onPCODetailsSidebarClosed.type &&
          action.editChangeActionTriggered
        );
      }),
      debounceTime(500),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([_, projectId]) => {
        return this.primeApiService.getAllPCOs(projectId).pipe(
          map((response) => primeChangesActions.PCOListingLogsLoaded({ PCOs: response })),
          catchError((error) => {
            this.notif.showError(error?.error?.message || 'An error occurred while loading PCOs');
            return of(primeSidebarFailActions.onFailedToLoadPCOLogs());
          }),
        );
      }),
    );
  });

  onProjectChanged = createEffect(() => {
    return this.actions.pipe(
      ofType(viewProjectActions.selectedProjectChanged),
      map(() => primeChangesActions.onSelectedProjectChanged()),
    );
  });

  CORListingLoad = createEffect(() => {
    return this.actions.pipe(
      ofType(
        primeChangesActions.onCORListingAccess,
        // viewProjectActions.selectedProjectChanged,
        primeChangesActions.onCORDetailsSidebarClosed,
        primeChangesActions.onCORSaved,
        primeChangesActions.onCORLogDeletedSuccessfully,
      ),
      filter((action) => {
        // if editChangeActionTriggered is true, then don't load PCOs because an another interaction bar was opened
        return !(
          action.type === primeChangesActions.onCORDetailsSidebarClosed.type &&
          action.editChangeActionTriggered
        );
      }),
      debounceTime(500),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([_, projectId]) => {
        return this.primeApiService.getAllCORs(projectId).pipe(
          map((response) => primeChangesActions.CORListingLogsLoaded({ CORs: response })),
          catchError((error) => {
            this.notif.showError(error?.error?.message || 'An error occurred while loading CORs');
            return of(primeSidebarFailActions.onFailedToLoadCORLogs());
          }),
        );
      }),
    );
  });

  onPCODetailsUpdated = createEffect(() => {
    return this.actions.pipe(
      ofType(primePCODetailsSidebarActions.onPCODetailsUpdated),
      map((action) => {
        return primePCODetailsSidebarActions.PCODetailsLoaded({ response: action.response });
      }),
    );
  });

  onLoadPCODetails = createEffect(() => {
    return this.actions.pipe(
      ofType(
        primePCODetailsSidebarActions.onPCODetailsAccessed,
        primePCODetailsSidebarActions.onSCORequested,
      ),
      concatLatestFrom(() =>
        this.store.select(primeCommitmentsDetailsSelectors.selectPCODetailsSidebar),
      ),
      switchMap(([action, sidebar]) => {
        if (action.type === primePCODetailsSidebarActions.onSCORequested.type) {
          return this.primeApiService.loadPCODetails(sidebar.id);
        }
        return this.primeApiService.loadPCODetails(action.id);
      }),
      map((response) => primePCODetailsSidebarActions.PCODetailsLoaded({ response: response })),
      catchError((error) => {
        this.notif.showError(
          error?.error?.message || 'An error occurred while loading PCO details',
        );
        return of(primeSidebarFailActions.onFailedToLoadPCODetails());
      }),
      repeat(),
    );
  });

  onLoadCORDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(primeChangesActions.onCORDetailsAccess),
      switchMap((action) => {
        return this.primeApiService.loadCORDetails(action.id);
      }),
      map((response) => primeChangesActions.CORDetailsLoaded({ response: response })),
      catchError((error) => {
        this.notif.showError(
          error?.error?.message || 'An error occurred while loading COR details',
        );
        return of(primeSidebarFailActions.onFailedToLoadCORDetails());
      }),
      repeat(),
    );
  });

  onPCODetailsSyncTriggered = createEffect(() => {
    return this.actions.pipe(
      ofType(
        primePCODetailsSidebarActions.onSyncPCODetailsTriggered,
        primePCODetailsSidebarActions.onPCODetailsLocalStateUpdate,
        primePCODetailsSidebarActions.onPCOLineItemAdded,
      ),
      debounceTime(2500),
      switchMap((action) => {
        // allow request to go through only if it should be saved to backend, otherwise cancel it
        if (action.type !== primePCODetailsSidebarActions.onSyncPCODetailsTriggered.type) {
          return EMPTY;
        }
        return of(primePCODetailsSidebarActions.onSyncPCODetails({ PCO: action.PCO }));
      }),
    );
  });

  onPCODetailsSyncBackend = createEffect(() => {
    return this.actions.pipe(
      ofType(primePCODetailsSidebarActions.onSyncPCODetails),
      switchMap((action) => {
        const pco = removeLocalIds(action.PCO);
        return this.primeApiService.updatePCODetails(pco);
      }),
      map((response: PCODetail) => {
        return primePCODetailsSidebarActions.onPCODetailsUpdated({ response: response });
      }),
      catchError((error) => {
        this.notif.showError(error?.error?.message || 'An error occurred while saving PCO');
        return of(primeSidebarFailActions.onFailedToSavePCODetails());
      }),
      repeat(),
    );
  });

  onUpdatePCOChangeActionSync = createEffect(() => {
    return this.actions.pipe(
      ofType(primePCODetailsSidebarActions.onPCOChangeActionFirstPageSync),
      debounceTime(500),
      switchMap((action) => {
        return this.primeApiService.updatePCODetails(action.PCO);
      }),
      map((response: PCODetail) => {
        this.interactionBar.close();
        return primePCODetailsSidebarActions.onPCOChangeActionFirstPageSynced({
          response: response,
        });
      }),
      catchError((error) => {
        this.notif.showError(error?.error?.message || 'An error occurred while saving');
        return of(primeSidebarFailActions.onFailedToUpdatePCOChangeAction());
      }),
      repeat(),
    );
  });
}

const removeLocalIds = (pco: PCODetail) => {
  pco = DeepCopyService.deepCopy(pco);
  pco.pco_items.forEach((item) => {
    if (uuid.validate(item.id)) {
      delete item.id;
    }

    item.pco_item_costs.forEach((cost) => {
      if (uuid.validate(cost.id)) {
        delete cost.id;
      }
    });
  });
  return pco;
};
