@if ((lineItemOptions$ | async).length === 0) {
  <div class="flex flex-col items-center justify-center h-20">
    <span class="font-bold">No available Line Items.</span>
    <span class="font-bold">Try to set scope change in the previous page to New Scope.</span>
  </div>
} @else {
  <app-dropdown
    required
    [optionLabel]="null"
    [optionValue]="'id'"
    [model]="lineItem.id"
    (modelChange)="lineItemChange($event)"
    [placeholder]="'Line Item' + index"
    [label]="'Line Item ' + index"
    [name]="'line_item_' + tempUUID"
    class="w-full"
    [options]="lineItemOptions$ | async"
  >
    <ng-template #selectedContent #optionContent let-option>
      <div class="flex flex-col">
        <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
        <div class="capitalize text-color_primary font-normal">{{ option?.division }} {{ option?.cost_type }}</div>
      </div>
    </ng-template>
  </app-dropdown>

  @for (cost of lineItem.costs; track costIndex; let costIndex = $index) {
    <div class="flex gap-2 w-full items-center">
      <app-dropdown
        required
        class="w-full"
        [optionLabel]="null"
        [optionValue]="'id'"
        [model]="cost.id"
        (modelChange)="costChange($event, costIndex)"
        [placeholder]="'Spend Description ' + costIndex"
        [label]="'Spend Description ' + costIndex"
        [name]="'Spend Description_' + index + '_' + costIndex"
        [options]="(lineIdToCosts$ | async)?.get(lineItem.id)"
      >
        <ng-template #selectedContent #optionContent let-option>
          <div>{{ option.name }}</div>
        </ng-template>
      </app-dropdown>

      <app-add-remove-buttons class="mt-3.5" (removeItem)="removeCost(costIndex)" (addItem)="addCost(costIndex)">
      </app-add-remove-buttons>
    </div>
  }
}
