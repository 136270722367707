import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { NgScrollbar } from 'ngx-scrollbar';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe, DecimalPipe, NgClass, TitleCasePipe } from '@angular/common';
import * as uuid from 'uuid';
import { FormsModule, NgForm } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownComponent } from '../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { viewProjectSelectors } from '../../store/view-project/view-project.selectors';
import { SimpleButtonComponent } from '../buttons/simple-medium-button/simple-button.component';
import { GeneralSidebarHeaderComponent } from '../general-sidebar-header/general-sidebar-header.component';
import { ProjectSpendService } from '../../services/project-spend.service';
import { NotificationsService } from '../../services/notifications.service';
import { PageLoadingComponent } from '../page-loading/page-loading.component';
import { viewProjectActions } from '../../store/view-project/view-project.actions';
import {
  MANAGE_PROJECT_TABS,
  VIEW_PROJECT_REFRESH_CAUSE,
} from '../constants/view-project.constants';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { DeepCopyService } from '../../services/deep-copy.service';
import { selectAllLineItems } from '../../store/spend/spend.selectors';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { BorderedDropdownIconComponent } from '../bordered-dropdown-icon/bordered-dropdown-icon.component';
import { INTERACTION_BAR_STATES } from '../constants/interaction-bar.constants';

export interface IAnticipatedCost {
  item_id: number;
  modifications: {
    note: string;
    value: number;
    id: number | string;
  }[];
}

@Component({
  selector: 'app-anticipated-costs',
  standalone: true,
  imports: [
    GeneralSidebarHeaderComponent,
    NgScrollbar,
    SimpleButtonComponent,
    FloatingInputComponent,
    DropdownComponent,
    DecimalPipe,
    NgClass,
    FormsModule,
    PaginatorModule,
    AsyncPipe,
    PageLoadingComponent,
    MoneyPipe,
    BorderedDropdownIconComponent,
    TitleCasePipe,
  ],
  templateUrl: './anticipated-costs.component.html',
  styleUrl: './anticipated-costs.component.scss',
})
export class AnticipatedCostsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('anticipatedCostsForm') anticipatedCostsForm: NgForm;

  forecast_modifications: IAnticipatedCost[] = [];
  lineItemOptions: { name: string; id: number }[] = [];

  isDestroyed$ = new Subject<boolean>();
  spends$ = this.store.select(viewProjectSelectors.getSpends);
  lineItems$ = this.store.select(selectAllLineItems);
  isLoading$ = this.store.select(viewProjectSelectors.isLoadingGeneral);

  constructor(
    private store: Store,
    private projectSpendService: ProjectSpendService,
    private notif: NotificationsService,
    private interactionBar: InteractionBarStateService,
  ) {}

  ngAfterViewInit() {}

  ngOnInit() {
    combineLatest([this.lineItems$, this.spends$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([lineItems, spends]) => {
        // Set lineItems regardless of the content of spends
        this.lineItemOptions = lineItems.map((spend) => ({
          name: spend.name,
          id: spend.id,
        }));

        // Process spends only if it has items
        this.forecast_modifications = spends
          ?.filter((spend) => spend.forecast_modifications.length > 0)
          .map((spend) => ({
            item_id: spend.id,
            name: spend.name,
            modifications: spend.forecast_modifications.map((mod) => ({
              id: mod.id,
              note: mod.note,
              value: mod.value,
            })),
          }));
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  save() {
    this.anticipatedCostsForm.form.markAllAsTouched();

    if (!this.anticipatedCostsForm.form.valid) {
      return;
    }

    this.modifyForecast(this.forecast_modifications);
  }

  modifyForecast(anticipatedCosts: IAnticipatedCost[]) {
    anticipatedCosts = DeepCopyService.deepCopy(anticipatedCosts);
    anticipatedCosts
      .flatMap((item) => item.modifications)
      .filter((mod) => uuid.validate(mod.id))
      .forEach((mod) => delete mod.id);
    // all forecast modifications need to be sent to backend
    const allUnmodifiedItems = this.lineItemOptions.filter(
      (item) => !anticipatedCosts.some((anticipatedCost) => anticipatedCost.item_id === item.id),
    );
    const body: { forecast_modifications: IAnticipatedCost[] } = {
      forecast_modifications: [
        ...anticipatedCosts.map((item) => ({
          item_id: item.item_id,
          modifications: item.modifications,
        })),
        ...allUnmodifiedItems.map((item) => ({ item_id: item.id, modifications: [] })),
      ],
    };

    this.projectSpendService.modifyForecast(body).then(
      (_) => {
        this.notif.showSuccess('Input saved!');
        // set timeout to allow the success notification to show...
        setTimeout(() => {
          this.store.dispatch(
            viewProjectActions.refreshNeeded({
              cause: VIEW_PROJECT_REFRESH_CAUSE.INTERACTION_BAR_CLOSED,
            }),
          );
          this.interactionBar.close();
        }, 500);
      },
      (err) => {
        console.warn('Forecast error', err);
        this.notif.showError(err);
      },
    );
  }

  deleteItem(index: number, itemIndex: number) {
    this.forecast_modifications[index].modifications.splice(itemIndex, 1);
  }

  insertItem(index: number, itemIndex: number) {
    this.forecast_modifications[index].modifications.splice(itemIndex + 1, 0, {
      note: '',
      value: 0,
      id: uuid.v4(),
    });
  }

  addBudgetLine() {
    this.forecast_modifications.push({
      item_id: null,
      modifications: [{ note: '', value: 0, id: uuid.v4() }],
    });
  }

  removeBudgetLine(index: number) {
    this.notif
      .showPopup(`Are you sure you want to clear cost group ${index + 1}?`)
      .then((deleteGroup) => {
        if (deleteGroup) {
          this.forecast_modifications.splice(index, 1);
        }
      });
  }

  clearAllCosts() {
    this.notif.showPopup('Are you sure you want to clear all cost entries?').then((shouldClear) => {
      if (shouldClear) {
        this.forecast_modifications = [];
      }
    });
  }

  get totals() {
    return this.forecast_modifications.reduce((acc, curr) => {
      return (
        Number(acc) + curr.modifications.reduce((acc, curr) => Number(acc) + Number(curr.value), 0)
      );
    }, 0);
  }

  get allIds() {
    return this.forecast_modifications.map((item) => item.item_id);
  }

  switchViewToManageProject() {
    this.interactionBar.openInteractionBar(INTERACTION_BAR_STATES.MANAGE_PROJECT, {
      tab: MANAGE_PROJECT_TABS.BUDGET_CASHFLOW,
    });
  }
}
