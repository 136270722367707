import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import {
  defaultSpendLineSummary,
  DISTRIBUTION_TYPES,
  SPEND_DISTRIBUTION_STATE,
  SPEND_TYPES,
} from '../../framework/constants/spend.constants';
import { FiscalService } from '../../services/fiscal.service';
import { DeepCopyService } from '../../services/deep-copy.service';
import {
  IBudget,
  ICommittedItem,
  ILineItem,
  ISpendLineItemSummary,
  ISpendStoreUpdate,
  ISubitem,
  SPEND_ERROR_TYPES,
  SpendStoreUpdateTypes,
} from './spend.interfaces';
import { getAllPossibleYearsFunction, getLineItemPossibleYearsFunction } from './spend.selectors';
import { spendActionTypes } from './spend.actions';

export const spendFeatureKey = 'spend';

export interface SpendState extends EntityState<ILineItem> {
  selectedYear: number;
  projectStartDate: string;
  selectedSpendType: SPEND_TYPES;
  spendDistributionState: SPEND_DISTRIBUTION_STATE;
  selectedProjectId: number;
  newLineItems: Set<number>; // these exist only locally and should be saved to backend
  modifiedLineItems: Set<number>; // these exist only locally and should be saved to backend
  modifiedCommittedItems: Set<number>; // these exist only locally and should be saved to backend
  modifiedSubitems: Set<number>; // these exist only locally and should be saved to backend
  deletedLineItems: Set<number>; // these exist only locally and should be saved to backend
  lastStoreUpdate: ISpendStoreUpdate; // it is used in Spend component to update only one item at a time
  isLoading: boolean; // shows the loading notification if true
  // the user is able to add more years to the selection list.
  // These are the newly added years which are not present in lineItem budget data.
  newYears: number[];
  // set for add projects, if project has templates then user can't modify budget line item name, and can't add/delete line items
  hasTemplates: boolean;
  spendLineItemSummary: ISpendLineItemSummary;
}

export const spendAdapter: EntityAdapter<ILineItem> = createEntityAdapter<ILineItem>();

function getSelectedYearFunc(lineItems: ILineItem[], currentlySelectedYear: number): number {
  const possibleYears = getLineItemPossibleYearsFunction(lineItems);
  if (possibleYears[0] === undefined) {
    return currentlySelectedYear;
  }
  let selectedYear;
  if (!possibleYears.includes(currentlySelectedYear)) {
    if (possibleYears.includes(FiscalService.fiscalYear)) {
      selectedYear = FiscalService.fiscalYear;
    } else {
      selectedYear = possibleYears[0];
    }
  } else {
    selectedYear = currentlySelectedYear;
  }
  return selectedYear;
}

export const spendInitialState: SpendState = spendAdapter.getInitialState({
  selectedYear: new Date().getFullYear(), // todo: it should be current fiscal year
  projectStartDate: undefined,
  selectedSpendType: SPEND_TYPES.BUDGET,
  selectedProjectId: null,
  spendDistributionState: SPEND_DISTRIBUTION_STATE.DEFAULT,
  newLineItems: new Set(),
  modifiedLineItems: new Set(),
  modifiedCommittedItems: new Set(),
  modifiedSubitems: new Set(),
  deletedLineItems: new Set(),
  lastStoreUpdate: { type: SpendStoreUpdateTypes.NONE, lineId: null },
  isLoading: false,
  newYears: [],
  hasTemplates: false,
  spendLineItemSummary: { ...defaultSpendLineSummary },
});

export const spendReducer = createReducer(
  spendInitialState,
  on(spendActionTypes.setLineItems, (state, action) => {
    const selectedYear = getSelectedYearFunc(Object.values(action.lineItems), state.selectedYear);

    return spendAdapter.setAll(action.lineItems, {
      ...state,
      selectedYear,
      newLineItems: new Set<number>(),
      modifiedLineItems: new Set<number>(action.modifiedItems.lineIds),
      modifiedCommittedItems: new Set<number>(action.modifiedItems.committedItemIds),
      modifiedSubitems: new Set<number>(action.modifiedItems.subitemIds),
      deletedLineItems: new Set<number>(),
      selectedProjectId: action.projectId,
      lastStoreUpdate: { type: SpendStoreUpdateTypes.SET_ALL, lineId: null },
      isLoading: false,
      spendDistributionState: SPEND_DISTRIBUTION_STATE.DEFAULT,
      newYears: [],
    });
  }),

  on(spendActionTypes.addLineItem, (state, action) => {
    let newSelectedYear;
    const lineItem = { ...action.lineItem };
    if (Object.keys(state.entities).length === 0) {
      newSelectedYear = getSelectedYearFunc([lineItem], state.selectedYear);
    }
    const id = Object.values(state.entities).reduce((acc, curr) => {
      if (curr.id > acc) {
        return curr.id;
      }
      return acc;
    }, 0);
    // the new id will be bigger than the biggest so order is kept
    lineItem.id = id + 1; // it will be removed when saved to backend - only for local use
    const newLineItems = new Set([...state.newLineItems]);
    newLineItems.add(lineItem.id);
    const newState: SpendState = {
      ...state,
      newLineItems,
      selectedYear: newSelectedYear ? newSelectedYear : state.selectedYear,
      lastStoreUpdate: { type: SpendStoreUpdateTypes.ADD, lineId: lineItem.id },
      isLoading: false,
    };
    return spendAdapter.addOne(lineItem, newState);
  }),

  on(spendActionTypes.updateLineItem, (state, action) => {
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    if (!state.newLineItems.has(action.lineItem.id)) {
      modifiedLineItems.add(action.lineItem.id);
    }

    const newState: SpendState = {
      ...state,
      modifiedLineItems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.UPDATE,
        lineId: action.lineItem.id,
      },
      isLoading: false,
    };
    const lineItem = {
      ...action.lineItem,
      committed_items: [...state.entities[action.lineItem.id].committed_items],
    };

    const stateAfterUpdate = spendAdapter.updateOne(
      { id: lineItem.id, changes: lineItem },
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );

    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.updateLineItemsBulk, (state, action) => {
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    const modifiedSubitems = new Set([...state.modifiedSubitems]);
    const lineItems: ILineItem[] = DeepCopyService.deepCopy(action.lineItems);
    for (const lineItem of lineItems) {
      if (!state.newLineItems.has(lineItem.id)) {
        modifiedLineItems.add(lineItem.id);
      }
      lineItem.committed_items = [...state.entities[lineItem.id].committed_items];

      for (const subitem of lineItem.subitems) {
        modifiedSubitems.add(subitem.id);
      }
    }

    const newState: SpendState = {
      ...state,
      modifiedLineItems,
      modifiedSubitems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.SET_ALL,
        lineId: null,
      },
      isLoading: false,
    };

    const stateAfterUpdate = spendAdapter.updateMany(
      lineItems.map((lineItem) => ({
        id: lineItem.id,
        changes: lineItem,
      })),
      newState,
    );
    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );

    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.updateCommittedLineItem, (state, action) => {
    const modifiedCommittedItems = new Set([...state.modifiedCommittedItems]);
    modifiedCommittedItems.add(action.committedLineItem.id);
    const newState: SpendState = {
      ...state,
      modifiedCommittedItems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.UPDATE,
        lineId: action.committedLineItem.item_id,
      },
      isLoading: false,
    };

    const committedLineItem: ICommittedItem = { ...action.committedLineItem };

    // parent item of the committed item (it can be a subitem too)
    let committedParentItem: ILineItem | ISubitem;
    for (const lineItem of Object.values(state.entities)) {
      if (lineItem.id === committedLineItem.item_id) {
        committedParentItem = lineItem;
        break;
      }
      for (const subitem of lineItem.subitems) {
        if (subitem.id === committedLineItem.item_id) {
          committedParentItem = subitem;
          break;
        }
      }
    }
    if (!committedParentItem) {
      console.error('Item not found for committed line item', committedLineItem);
      return state;
    }

    committedParentItem = {
      ...committedParentItem,
      committed_items: committedParentItem.committed_items.map((item, index) =>
        item.id === committedLineItem.id ? { ...item, ...committedLineItem } : item,
      ),
    };

    let lineItem = committedParentItem;
    if ((committedParentItem as ISubitem).is_subitem) {
      const item = state.entities[(committedParentItem as ISubitem).parent_id];
      lineItem = {
        ...item,
        subitems: item.subitems.map((subitem) => {
          if (subitem.id === committedParentItem.id) {
            return committedParentItem as ISubitem;
          }
          return subitem;
        }),
      };
    }
    if (!lineItem) {
      console.error('Parent item not found for committed line item', committedLineItem);
      return state;
    }

    console.log(
      'updated line item',
      lineItem,
      'committed item',
      committedLineItem,
      'parent',
      committedParentItem,
    );
    const stateAfterUpdate = spendAdapter.updateOne(
      {
        id: lineItem.id,
        changes: lineItem,
      },
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );
    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.updateCommittedLineItemsBulk, (state, action) => {
    const modifiedCommittedItems = new Set([...state.modifiedCommittedItems]);
    const lineItems: ILineItem[] = cloneDeep(Object.values(state.entities));
    const lineItemsWithSubitems = lineItems.flatMap((item) => [item, ...item.subitems]);

    for (const committedItem of action.committedLineItems) {
      modifiedCommittedItems.add(committedItem.id);

      const parentOfCommitted = lineItemsWithSubitems.find(
        (item) => item.id === committedItem.item_id,
      );
      if (!parentOfCommitted) {
        console.warn(
          'Parent not found in updateCommittedLineItemsBulk, could not update state.',
          committedItem,
        );
        return state;
      }

      parentOfCommitted.committed_items = parentOfCommitted.committed_items.map((item) => {
        if (item.id === committedItem.id) {
          return { ...item, ...committedItem };
        }
        return item;
      });
    }

    const newState: SpendState = {
      ...state,
      modifiedCommittedItems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.SET_ALL,
        lineId: null,
      },
      isLoading: false,
    };

    const stateAfterUpdate = spendAdapter.updateMany(
      lineItems.map((lineItem) => ({
        id: lineItem.id,
        changes: lineItem,
      })),
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );
    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.updateSubitem, (state, action): SpendState => {
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    const modifiedSubitems = new Set([...state.modifiedSubitems]);
    modifiedLineItems.add(action.subitem.parent_id);
    modifiedSubitems.add(action.subitem.id);

    const newState: SpendState = {
      ...state,
      modifiedLineItems,
      modifiedSubitems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.UPDATE_SUBITEM,
        lineId: action.subitem.parent_id,
        subitemId: action.subitem.id,
      },
      isLoading: false,
    };

    const updatedSubitem = { ...action.subitem };
    const parentItem = state.entities[updatedSubitem.parent_id];
    if (!parentItem) {
      console.error('Parent item not found for subitem', updatedSubitem);
      return state;
    }

    const lineItem = updateLineItemWithSubitem(parentItem, updatedSubitem);

    console.log('updated line item', lineItem);

    const stateAfterUpdate = spendAdapter.updateOne(
      {
        id: updatedSubitem.parent_id,
        changes: lineItem,
      },
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );
    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.updateSubitemsBulk, (state, action) => {
    const modifiedSubitems = new Set([...state.modifiedSubitems]);
    const lineItems: ILineItem[] = cloneDeep(Object.values(state.entities));

    for (const subitem of action.subitems) {
      modifiedSubitems.add(subitem.id);

      const lineItem = lineItems.find((item) => item.id === subitem.parent_id);
      lineItem.subitems = lineItem.subitems.map((item) => {
        if (item.id === subitem.id) {
          return {
            ...item,
            ...subitem,
          };
        }
        return item;
      });
    }

    const newState: SpendState = {
      ...state,
      modifiedSubitems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.SET_ALL,
        lineId: null,
      },
      isLoading: false,
    };

    const stateAfterUpdate = spendAdapter.updateMany(
      lineItems.map((lineItem) => ({
        id: lineItem.id,
        changes: lineItem,
      })),
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );
    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.deleteLineItem, (state, action) => {
    // if an item is going to be deleted, it's useless to create/update it
    const newLineItems = new Set([...state.newLineItems]);
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    const deletedLineItems = new Set([...state.deletedLineItems]);

    if (newLineItems.has(action.id)) {
      newLineItems.delete(action.id);
    } else {
      deletedLineItems.add(action.id);
    }

    if (modifiedLineItems.has(action.id)) {
      modifiedLineItems.delete(action.id);
    }

    const selectedYear = getSelectedYearFunc(Object.values(state.entities), state.selectedYear);

    const newState: SpendState = {
      ...state,
      deletedLineItems,
      newLineItems,
      modifiedLineItems,
      selectedYear,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.DELETE,
        lineId: action.id,
      },
      isLoading: false,
    };
    return spendAdapter.removeOne(action.id, newState);
  }),

  on(spendActionTypes.setSelectedYear, (state, action) => {
    return {
      ...state,
      selectedYear: action.year,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.FILTER_CHANGE,
        lineId: null,
      },
    };
  }),

  on(spendActionTypes.setSelectedSpendType, (state, action) => {
    return {
      ...state,
      selectedSpendType: action.spendType,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.FILTER_CHANGE,
        lineId: null,
      },
    };
  }),
  on(spendActionTypes.setIsLoading, (state, action) => {
    const isLoading = action.isLoading;
    if (isLoading !== !!isLoading) {
      // when isLoading is null/undefined
      return state;
    }
    return { ...state, isLoading };
  }),

  on(spendActionTypes.setProjectStartDate, (state, action) => {
    return {
      ...state,
      projectStartDate: action.projectStartDate,
    };
  }),

  on(spendActionTypes.setSelectedProjectIdFromAddEditProject, (state, action) => {
    return {
      ...state,
      selectedProjectId: action.id,
    };
  }),

  on(spendActionTypes.setSpendDistributionState, (state, action) => {
    return {
      ...state,
      spendDistributionState: action.spendDistributionState,
    };
  }),
  on(spendActionTypes.clearAfterSave, (state, action) => {
    const newState = { ...state };
    newState.isLoading = false;
    newState.newLineItems.clear();
    newState.modifiedLineItems.clear();
    newState.modifiedCommittedItems.clear();
    newState.deletedLineItems.clear();
    // add id's to store again with http errors
    action.errors.forEach((err) => {
      if (err.id) {
        switch (err.errorType) {
          case SPEND_ERROR_TYPES.NEW:
            newState.newLineItems.add(err.id);
            break;
          case SPEND_ERROR_TYPES.MODIFIED:
            newState.modifiedLineItems.add(err.id);
            break;
          case SPEND_ERROR_TYPES.DELETED:
            newState.deletedLineItems.add(err.id);
            break;
          case SPEND_ERROR_TYPES.FAILED_TO_UPDATE_COMMITTED_LINE:
            newState.modifiedCommittedItems.add(err.id);
            break;
          case SPEND_ERROR_TYPES.FAILED_TO_UPDATE_SUBITEM:
            newState.modifiedSubitems.add(err.id);
            break;
        }
      }
    });

    newState.lastStoreUpdate = {
      type: SpendStoreUpdateTypes.SAVE_BACKEND,
      lineId: null,
    };
    return newState;
  }),

  on(spendActionTypes.addNewYear, (state, action) => {
    // adds a year to the selectable list
    const allYears = getAllPossibleYearsFunction(Object.values(state.entities), state.newYears);
    const newYears = [...state.newYears];
    newYears.push(allYears[allYears.length - 1] + 1);
    return {
      ...state,
      selectedYear: newYears ? newYears[newYears.length - 1] : state.selectedYear,
      newYears,
    };
  }),

  on(
    spendActionTypes.setHasProjectTemplateFromAddEditProject,
    spendActionTypes.setHasProjectTemplateFromViewProject,
    (state, action) => {
      return {
        ...state,
        hasTemplates: action.hasTemplates,
      };
    },
  ),
  on(spendActionTypes.loadSpendLineItemSummary, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...defaultSpendLineSummary, isLoaded: false },
    };
  }),
  on(spendActionTypes.spendLineItemSummaryLoaded, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...action.summary, isLoaded: true },
    };
  }),
  on(spendActionTypes.clearSpendLineItemSummary, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...defaultSpendLineSummary },
    };
  }),
);

export const { selectAll, selectIds, selectEntities } = spendAdapter.getSelectors();

function updateLineItemWithSubitem(parentItem: ILineItem, updatedSubitem: Partial<ISubitem>) {
  const lineItem = {
    ...parentItem,
    distribution: DISTRIBUTION_TYPES.MANUAL,
    subitems: parentItem.subitems.map((subitem) => {
      if (subitem.id === updatedSubitem.id) {
        return {
          ...subitem,
          ...updatedSubitem,
        };
      }
      return subitem;
    }),
  };

  const budget: IBudget[] = [];
  let startDate = null;
  let endDate = null;

  for (const subitem of lineItem.subitems) {
    if (!startDate || subitem.start_date < startDate) {
      startDate = subitem.start_date;
    }
    const subitemEndDate = dayjs(subitem.start_date, 'YYYY-MM-DD')
      .add(subitem.duration, 'month')
      .format('YYYY-MM-DD');
    if (!endDate || subitemEndDate > endDate) {
      endDate = subitemEndDate;
    }

    for (const subitemBudget of subitem.budget) {
      updateBudgetWithSubitemBudget(subitemBudget, budget);
    }
  }

  const end = dayjs(endDate, 'YYYY-MM-DD');
  const start = dayjs(startDate, 'YYYY-MM-DD');
  lineItem.budget = budget;
  lineItem.start_date = startDate;
  lineItem.duration = end.year() * 12 + end.month() - (start.year() * 12 + start.month()) + 1;

  return lineItem;
}

function updateBudgetWithSubitemBudget(subitemBudget: IBudget, budget: IBudget[]) {
  const year = subitemBudget.year;
  const yearlyBudget = budget.find((el) => el.year === year);
  if (!yearlyBudget) {
    budget.push({
      year,
      monthly_budget: { ...subitemBudget.monthly_budget },
    });
    return;
  }

  for (const [monthIndex, value] of Object.entries(yearlyBudget.monthly_budget)) {
    yearlyBudget.monthly_budget[monthIndex] = value + subitemBudget.monthly_budget[monthIndex];
  }
}
