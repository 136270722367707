import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  TemplateViews,
} from '../../../../../../store/templates/templates.types';
import { OVERLAY_TYPES } from '../../../../../../pipes/framework/overlay-options.pipe';
import { templatesActions } from '../../../../../../store/templates/templates.actions';
import { Store } from '@ngrx/store';
import { OptionsPermissionsPipe } from '../../../../../../pipes/framework/options-permissions.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { UnStyledOptionsListComponent } from '../../../../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { OPTIONS } from '../../../../../../framework/constants/options-list.constants';
import { NotificationsService } from '../../../../../../services/notifications.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { templateSelectors } from '../../../../../../store/templates/templates.selectors';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FloatingInputComponent } from '../../../../../../framework/inputs/floating-input/floating-input.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-template-input',
  templateUrl: './template-input.component.html',
  styleUrls: ['./template-input.component.scss'],
  standalone: true,
  imports: [
    UnStyledOptionsListComponent,
    OptionsPermissionsPipe,
    ReactiveFormsModule,
    FormsModule,
    AsyncPipe,
    FloatingInputComponent,
    NgClass,
    TooltipModule,
  ],
})
export class TemplateInputComponent implements OnInit, OnDestroy {
  _templateId: number;
  @Input({ required: true }) set templateId(value: number) {
    this._templateId = value;
  }
  get templateId() {
    return this._templateId;
  }
  @Input({ required: true }) templateView: TemplateViews;
  @Output() unselectTemplate = new EventEmitter();
  @ViewChild('inputElement') inputElement: FloatingInputComponent;
  selectedTemplate$: Observable<IBudgetTemplate | IBudgetTagTemplate>;

  OVERLAY_TYPES = OVERLAY_TYPES;

  template$: Observable<IBudgetTemplate | IBudgetTagTemplate>;

  isDestroyed$ = new Subject<boolean>();
  constructor(
    private store: Store,
    private notif: NotificationsService,
  ) {}

  ngOnInit() {
    this.template$ = this.store.select(
      templateSelectors.getTemplate(this.templateId, this.templateView),
    );

    if (this.templateView === 'budget') {
      this.selectedTemplate$ = this.store.select(templateSelectors.getSelectedBudgetTemplate);
    } else if (this.templateView === 'budget-tag') {
      this.selectedTemplate$ = this.store.select(templateSelectors.getSelectedBudgetTagTemplate);
    }

    this.handleSelectedTemplateChange();
  }

  registerOption(event: OPTIONS) {
    if (event === 'Delete') {
      this.deleteTemplate(this.templateId);
    }
    if (event === 'Edit') {
      this.setEditMode(true);
      this.focusInput();
    }
  }

  async deleteTemplate(id: number) {
    const answer = await this.notif.showPopup('Are you sure you want to delete this template?');
    if (!answer) {
      return;
    }

    switch (this.templateView) {
      case 'budget':
        this.store.dispatch(templatesActions.deleteBudgetTemplate({ templateId: id }));
        break;
      case 'budget-tag':
        this.store.dispatch(templatesActions.deleteBudgetTagTemplate({ templateId: id }));
        break;
      default:
        break;
    }
    this.unselectTemplate.emit();
  }

  onInputChange(newTemplateName: string) {
    switch (this.templateView) {
      case 'budget':
        this.store.dispatch(
          templatesActions.updateBudgetTemplate({
            templateId: this.templateId,
            template: {
              name: newTemplateName,
            },
          }),
        );
        break;
      case 'budget-tag':
        this.store.dispatch(
          templatesActions.updateBudgetTagTemplate({
            templateId: this.templateId,
            template: {
              name: newTemplateName,
            },
          }),
        );
        break;
    }
  }

  setEditMode(isEdit: boolean) {
    if (this.templateView === 'budget') {
      this.store.dispatch(
        templatesActions.updateBudgetTemplate({
          templateId: this.templateId,
          template: { is_edit: isEdit },
        }),
      );
    } else if (this.templateView === 'budget-tag') {
      this.store.dispatch(
        templatesActions.updateBudgetTagTemplate({
          templateId: this.templateId,
          template: { is_edit: isEdit },
        }),
      );
    }
  }

  focusInput() {
    setTimeout(() => {
      if (!this.inputElement?.inputElement?.nativeElement) {
        return;
      }
      this.inputElement?.inputElement?.nativeElement.focus();
    }, 50);
  }

  handleSelectedTemplateChange() {
    this.selectedTemplate$
      .pipe(takeUntil(this.isDestroyed$), debounceTime(300))
      .subscribe((selectedTemplate) => {
        if (selectedTemplate.id === this.templateId) {
          this.focusInput();
        }
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
