<div class="spend-wrapper">
  <div class="spend-distribution-wrapper">
    <div *ngIf="(spendState$ | async) === SPEND_DISTRIBUTION_STATE.LOADING" class="loading-screen">
      <span class="icon-refresh animate-spin"></span>
    </div>

    <app-spend-distribution-header
      (exportBudgetClicked)="exportBudget()"
      (importBudgetClicked)="importBudget($event)"
    ></app-spend-distribution-header>

    @if (isBudgetMissing$ | async) {
      <app-splash-screen class="h-full" title="Please select a project template"></app-splash-screen>
    } @else {
      <app-budget-line-item-table></app-budget-line-item-table>
    }
  </div>
</div>

<div class="action-buttons">
  <app-simple-button
    size="large"
    buttonStyle="color_secondary_filled"
    (click)="closeClicked()"
    data-cy="close-spend-distribution"
    >Close
  </app-simple-button>
  <app-simple-button
    size="large"
    (click)="saveClicked()"
    buttonStyle="color_accent_filled"
    data-cy="save-spend-distribution"
    >Save</app-simple-button
  >
</div>
