import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { DropdownComponent } from '../../../../../../../framework/inputs/dropdown/dropdown.component';
import { InputCalendarComponent } from '../../../../../../../framework/inputs/input-calendar/input-calendar.component';
import { SpendCustomInputComponent } from '../../../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { TooltipModule } from 'primeng/tooltip';
import { CurrentUserService } from '../../../../../../../services/current-user.service';
import { Store } from '@ngrx/store';
import { SpendDistributionService } from '../../../../../../../services/spend-distribution.service';
import {
  defaultLineItemsTotal,
  defaultMonthlyData,
  DISTRIBUTION_TYPES,
  distributionTypes,
  MONTHS_KEYS,
  SPEND_TYPES,
} from '../../../../../../../framework/constants/spend.constants';
import {
  ICommittedItemExtended,
  ILineItem,
  ISpendDistribution,
} from '../../../../../../../store/spend/spend.interfaces';
import { Subject } from 'rxjs';
import { getSelectedYear } from '../../../../../../../store/spend/spend.selectors';
import { takeUntil } from 'rxjs/operators';
import {
  updateCommittedLineItem,
  updateForecastDistribution,
} from '../../../../../../../store/spend/spend.actions';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

@Component({
  selector: 'app-committed-line-item',
  standalone: true,
  imports: [
    CdkDragHandle,
    DropdownComponent,
    InputCalendarComponent,
    SpendCustomInputComponent,
    NgClass,
    TooltipModule,
    NgForOf,
  ],
  templateUrl: './committed-line-item.component.html',
  styleUrls: ['./committed-line-item.component.scss'],
})
export class CommittedLineItemComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly userService = inject(CurrentUserService);
  protected readonly store = inject(Store);
  protected readonly spendDistributionService = inject(SpendDistributionService);

  protected readonly distributionTypes = distributionTypes;
  protected readonly MONTHS_KEYS = MONTHS_KEYS;
  protected readonly DISTRIBUTION_TYPES = DISTRIBUTION_TYPES;
  protected readonly SPEND_TYPES = SPEND_TYPES;
  protected readonly totals = defaultLineItemsTotal;
  private readonly DATE_FORMAT = 'YYYY-MM-DD';

  @Input({ required: true }) minStartDate: string;

  private _committedItem: ICommittedItemExtended;
  @Input({ required: true }) set committedItem(value: ICommittedItemExtended) {
    this._committedItem = value;
    this.setDisabledMonth(value);
  }
  get committedItem(): ICommittedItemExtended {
    return this._committedItem;
  }

  private _lineItem: ILineItem;
  @Input({ required: true }) set lineItem(value: ILineItem) {
    this._lineItem = value;
  }
  get lineItem(): ILineItem {
    return this._lineItem;
  }

  isDestroyed$: Subject<boolean> = new Subject<boolean>();
  selectedYear$ = this.store.select(getSelectedYear);

  selectedYear = 0;

  durationMonths = Array(72)
    .fill(0)
    .map((_, i) => i + 1)
    .map((i) => {
      return {
        label: i === 1 ? `${i} month` : `${i} months`,
        value: i,
      };
    });

  ngOnInit(): void {
    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
      this.setDisabledMonth();
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  setDisabledMonth(item: ICommittedItemExtended = this.committedItem) {
    // todo for getDisabledMonths: update distribution type when backend has refactored from 3 types to 1, remove typeToStartDateProp & typeToDurationProp & typeToProp
    const availableMonths = this.spendDistributionService.getDisabledMonths(
      this.selectedYear,
      item,
      this.selectedYear,
      false,
    );

    // disable all months that should be non-editable
    Object.keys(item.monthly_data).forEach((monthKey, index) => {
      item.monthly_disable[monthKey] = !availableMonths[index]; // key starts with 1
    });
  }

  registerChange(inputValue: number, item: ICommittedItemExtended, month: string) {
    const newValue = inputValue;
    const lineItem: ICommittedItemExtended = cloneDeep(item);
    let budget = lineItem.budget.find((el) => el.year === this.selectedYear);
    if (!budget) {
      budget = {
        year: this.selectedYear,
        monthly_forecast: { ...defaultMonthlyData },
      };
      lineItem.budget.push(budget);
    }

    if (!isNaN(newValue)) {
      budget.monthly_forecast[month] = newValue;
    }

    lineItem.distribution = DISTRIBUTION_TYPES.MANUAL;

    this.store.dispatch(
      updateCommittedLineItem({
        committedLineItem: this.spendDistributionService.extendedCommitmentItemToOriginal(lineItem),
      }),
    );
  }

  setItemTotal(total: string, item: ICommittedItemExtended) {
    const value = parseFloat(total);
    item.project_total = isNaN(value) ? 0 : value;
  }

  setStartDate(date: string, item: ICommittedItemExtended) {
    item.start_date = date;
  }

  setItemDuration(duration: number, item: ICommittedItemExtended) {
    item.duration = duration;
  }

  setItemDistribution(distribution: DISTRIBUTION_TYPES, item: ICommittedItemExtended) {
    item.distribution = distribution;
  }

  calcDistribution(item: ICommittedItemExtended) {
    const distribution: ISpendDistribution = {
      distribution: item.distribution,
      start_date: moment(item.start_date).format(this.DATE_FORMAT),
      duration: item.duration,
      field: 'monthly_forecast',
      budget: item.project_total,
    };
    this.setDisabledMonth();
    this.store.dispatch(
      updateForecastDistribution({ lineId: item.id, parentId: item.item_id, distribution }),
    );
  }

  trackByIndex = (index: number) => index;
}
