import { Component } from '@angular/core';
import moment from 'moment/moment';
import { BudgetLineItemComponent } from '../budget-line-item.component';
import { NgClass } from '@angular/common';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { DropdownComponent } from '../../../../../../../framework/inputs/dropdown/dropdown.component';
import { InputCalendarComponent } from '../../../../../../../framework/inputs/input-calendar/input-calendar.component';
import { SpendCustomInputComponent } from '../../../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { TooltipModule } from 'primeng/tooltip';
import {
  ILineItemExtended,
  ISpendDistribution,
  ISubitemExtended,
} from '../../../../../../../store/spend/spend.interfaces';
import {
  spendActionTypes,
  updateDistribution,
} from '../../../../../../../store/spend/spend.actions';

@Component({
  selector: 'app-prime-line-item',
  standalone: true,
  imports: [
    CdkDragHandle,
    DropdownComponent,
    InputCalendarComponent,
    SpendCustomInputComponent,
    NgClass,
    TooltipModule,
  ],
  templateUrl: './prime-line-item.component.html',
  styleUrl: './prime-line-item.component.scss',
})
export class PrimeLineItemComponent extends BudgetLineItemComponent {
  // who likes code repetition? Not me!

  calcDistribution(item: ILineItemExtended | ISubitemExtended) {
    const distribution: ISpendDistribution = {
      distribution: item.distribution,
      start_date: moment(item.start_date).format(this.DATE_FORMAT),
      field: 'monthly_prime',
      duration: item.duration,
      budget: item.project_total,
    };
    this.setDisabledMonth(this.item);

    if (this.isSubItem) {
      this.store.dispatch(
        spendActionTypes.updateSubitemDistribution({
          parentId: (item as ISubitemExtended).parent_id,
          lineId: item.id,
          distribution,
        }),
      );
    } else {
      this.store.dispatch(updateDistribution({ lineId: item.id, distribution }));
    }
  }
}
