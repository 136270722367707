import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, zip } from 'rxjs';
import { debounceTime, delay, filter, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { INTERACTION_BAR_STATES } from '../../constants/interaction-bar.constants';
import { AppState } from '../../../store/app-state';
import { itemTrackingActions } from '../../../store/item-tracking/item-tracking.actions';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { OPTIONS } from '../../constants/options-list.constants';
import {
  IActivityProgressItem,
  IItemTrackingChecklistItem,
  IItemTrackingFile,
} from '../../../store/item-tracking/item-tracking.reducer';
import { itemTrackingSelectors } from '../../../store/item-tracking/item-tracking.selectors';
import { DeepCopyService } from '../../../services/deep-copy.service';
import {
  IMetaData,
  PresignedFileUploadService,
} from '../../../services/presigned-file-upload.service';
import { DriveService } from '../../../services/drive.service';
import { NotificationsService } from '../../../services/notifications.service';
import { DynamicProjectedTableComponent } from '../../dynamic-projected-table/dynamic-projected-table.component';
import { Activity } from '../../../store/activities/activities.constants';
import { activitiesActions } from '../../../store/activities/activities.actions';
import { trackById } from '../../constants/dynamic-table.constants';
import { UploadWindowComponent } from '../../upload/upload-window/upload-window.component';
import { DaysCountComponent } from '../../progress-bar-projectable/days-count/days-count.component';
import { ProgressBarProjectableComponent } from '../../progress-bar-projectable/progress-bar-projectable.component';
import { ActivityCardAssignButtonComponent } from '../../../pages/webapp/activities/activity-card-assign-button/activity-card-assign-button.component';
import { UnStyledOptionsListComponent } from '../../overlays/un-styled-options-list/un-styled-options-list.component';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { ExpansionPanelComponent } from '../../expansion-panel/expansion-panel.component';
import { AddButtonComponent } from '../../buttons/add-button/add-button.component';
import { CheckboxDropdownComponent } from '../../checkbox-dropdown/checkbox-dropdown.component';
import { SplashScreenComponent } from '../../splash-screen/splash-screen.component';

@Component({
  selector: 'app-item-track-listing',
  templateUrl: './item-track-listing.component.html',
  styleUrl: './item-track-listing.component.scss',
  standalone: true,
  imports: [
    NgIf,
    SplashScreenComponent,
    DynamicProjectedTableComponent,
    CheckboxDropdownComponent,
    AddButtonComponent,
    NgFor,
    ExpansionPanelComponent,
    FadedTextComponent,
    UnStyledOptionsListComponent,
    ActivityCardAssignButtonComponent,
    ProgressBarProjectableComponent,
    DaysCountComponent,
    TooltipModule,
    NgClass,
    UploadWindowComponent,
    FormsModule,
    AsyncPipe,
    InputTextareaModule,
    CdkTextareaAutosize,
  ],
})
export class ItemTrackListingComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dynamicProjectedTable') dynamicTable: DynamicProjectedTableComponent;
  @ViewChildren('tableRow') tableRow: QueryList<ElementRef<HTMLDivElement>>;
  protected readonly Math = Math;
  itemOptions: OPTIONS[] = [OPTIONS.EDIT, OPTIONS.DELETE];
  fileOptions: OPTIONS[] = [OPTIONS.DOWNLOAD, OPTIONS.DELETE];
  tableGridStyle = {
    'grid-template-columns': '27% 50px 28% 80px 1fr',
  };
  progressItems: IActivityProgressItem[] = [];
  expandedItems = new Map<number, boolean>();
  currentlyUpdating = new Set<number>();
  isLoading = false;
  disableUpdates = false;
  wasDataLoadedOnce = false;

  updateData$ = new Subject<{ progressItem: IActivityProgressItem; reloadData: boolean }>();
  dataTable$: Observable<IActivityProgressItem[]> = this.store.select(
    itemTrackingSelectors.allProgressItemsList,
  );

  isLoading$ = this.store.select(itemTrackingSelectors.isLoading);
  getTotals$ = this.store.select(itemTrackingSelectors.getTotals);
  isAnyFilterActive$ = this.store.select(itemTrackingSelectors.isAnyFilterActive);

  trackById = trackById;
  isDestroyed$ = new Subject();

  constructor(
    private interactionBarService: InteractionBarStateService,
    private store: Store<AppState>,
    private presignedFileUploadService: PresignedFileUploadService,
    private driveService: DriveService,
    private notif: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private actions: Actions,
  ) {}

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.store.dispatch(itemTrackingActions.resetProgressItemsState());
    this.expandedItems.clear();
  }

  private handleIsLoading() {
    this.isLoading$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap(() => (this.wasDataLoadedOnce = true)),
      )
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  removeQueryParams(): void {
    const urlWithoutQueryParams = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {},
    });

    // Navigate to the new URL
    this.router.navigateByUrl(urlWithoutQueryParams.toString(), {
      replaceUrl: true,
      // to prevent the project view from navigating again to another page after removing the query params
      state: { disableProjectViewNavigation: true },
    });
  }

  private handleTablePopulation() {
    this.dataTable$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.progressItems = DeepCopyService.deepCopy(data);
      this.disableUpdates = false;
      this.currentlyUpdating.clear();
    });
  }

  ngAfterViewInit() {
    this.loadTrackingItems();
    this.handleTablePopulation();
    this.handleIsLoading();
    this.handleUpdateData();

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(activitiesActions.successfullyAssignedTeammate),
        delay(100),
      )
      .subscribe((action) => {
        this.loadTrackingItems();
      });

    // todo: make a function of zip stuff
    // zip together the table data and the query params - they emit at different times, but subscribe is called only once
    zip(
      this.dataTable$.pipe(
        filter((data) => data && data.length > 0),
        debounceTime(500), // to skip multiple emissions in a short time,
        take(1),
      ),
      this.route.queryParams,
    )
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([data, params]) => {
        const id = Number(params.trackingId);

        this.expandedItems.clear();
        data.forEach((item) => {
          this.expandedItems.set(item.id, false);
        });
        // expand the item
        if (params.trackingId && this.expandedItems.has(id)) {
          this.expandedItems.set(id, true);
        }

        this.removeQueryParams();

        this.scrollToItem(id, data);
      });
  }

  private handleUpdateData() {
    this.updateData$
      .pipe(
        tap(() => this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: true }))),
        takeUntil(this.isDestroyed$),
        debounceTime(1500),
        tap((data) => (this.disableUpdates = data.reloadData)),
      )
      .subscribe((event: { progressItem: IActivityProgressItem }) => {
        const updateItems = this.progressItems.filter((item) =>
          item.checklist.items.some((checklistItem) =>
            this.currentlyUpdating.has(checklistItem.id),
          ),
        );
        updateItems.forEach((item) => {
          this.store.dispatch(
            itemTrackingActions.saveProgressItemFromList({
              trackingItem: DeepCopyService.deepCopy(item),
              reloadData: true,
            }),
          );
        });
      });
  }

  scrollToItem(id: number, data: IActivityProgressItem[]) {
    console.log('scroll to item');
    if (!this.dynamicTable?.scrollbar) {
      console.warn('scrollbar not found');
      return;
    }
    // scroll to the item
    // todo: what do we do if we'll have infinite scroll?
    const itemIndex = data.findIndex((item) => item.id === id);
    if (itemIndex < 0) {
      console.warn('item not found to scroll to');
      return;
    }
    // get one table row's height
    const itemHeight = this.tableRow?.get(0)?.nativeElement?.clientHeight ?? 61; // black f@cking magic number

    if (!itemHeight) {
      // we just need to wait for the table to render
      setTimeout(() => this.scrollToItem(id, data), 250);
      return;
    }

    const scrollToTop = itemHeight * itemIndex;
    this.dynamicTable.scrollbar.scrollTo({ top: scrollToTop });
  }

  openTrackItemSidebar() {
    this.interactionBarService.openInteractionBar(INTERACTION_BAR_STATES.PROJECT_PROGRESS);
  }

  editTrackItemSidebar(id: number) {
    this.interactionBarService.openInteractionBar(INTERACTION_BAR_STATES.PROJECT_PROGRESS);
    this.store.dispatch(itemTrackingActions.editTrackingItemSidebar({ id }));
  }

  async registerItemOption(selectedOption: OPTIONS, item: Partial<IActivityProgressItem>) {
    switch (selectedOption) {
      case OPTIONS.EDIT: {
        this.editTrackItemSidebar(item.id);
        break;
      }
      case OPTIONS.DELETE: {
        const response = await this.notif.showPopup('Are you sure you want to delete?');
        if (response) {
          this.store.dispatch(itemTrackingActions.deleteProgressItem({ id: item.id }));
        }
        break;
      }
    }
  }

  registerFileSelected($event: any, file: IItemTrackingFile) {
    switch ($event) {
      case OPTIONS.DOWNLOAD: {
        this.driveService.downloadSingleFileS3Url(file);
        break;
      }
      case OPTIONS.DELETE: {
        this.driveService.deleteFile(file.id).then(
          (res) => {
            this.notif.showSuccess(res.message);
            this.loadTrackingItems();
          },
          (error) => {
            console.log('error', error);
          },
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * @description modifies the note of a checklist item without reloading
   * Modifies local progress items while a request is being made to the server
   * @param progressItem
   * @param checklistItemIndex
   * @param note
   */
  updateItemNote(progressItem: IActivityProgressItem, checklistItemIndex: number, note: string) {
    this.progressItems.find((item) => item.id === progressItem.id).checklist.items[
      checklistItemIndex
    ].note = note;
    this.store.dispatch(
      itemTrackingActions.saveProgressItemFromList({
        trackingItem: DeepCopyService.deepCopy(progressItem),
        reloadData: false,
      }),
    );
  }

  toggleItemCompletion(progressItem: IActivityProgressItem, index: number) {
    if (this.currentlyUpdating.has(progressItem.id) || this.disableUpdates) {
      return;
    }
    const progressItemIndex = this.progressItems.findIndex((item) => item.id === progressItem.id);
    this.progressItems[progressItemIndex].checklist.items[index].completed =
      !this.progressItems[progressItemIndex].checklist.items[index].completed;
    this.currentlyUpdating.add(this.progressItems[progressItemIndex].checklist.items[index].id);
    this.updateData$.next({
      progressItem: this.progressItems[progressItemIndex],
      reloadData: true,
    });
  }

  registerFileUpload(files: File[], id: number) {
    const metadata: Partial<IMetaData>[] = files.map((_) => ({
      progress_item_checklist_item_id: id,
    }));

    this.presignedFileUploadService
      .uploadMultipleFilesToS3(files, metadata)
      .pipe(delay(500))
      .subscribe({
        next: (_) => {
          this.loadTrackingItems();
        },
        error: (error) => {
          console.warn('error', error);
        },
      });
  }

  loadTrackingItems() {
    this.store.dispatch(itemTrackingActions.loadAllProgressItems());
  }

  filterItemsByProgressType($event: Record<string, boolean>) {
    this.store.dispatch(
      itemTrackingActions.setProgressItemsLoadFilter({ filter: DeepCopyService.deepCopy($event) }),
    );
    this.loadTrackingItems();
  }

  itemActivity(progressItem: IActivityProgressItem): Partial<Activity> {
    return {
      ...progressItem.activity,
      project: {
        id: progressItem.project_id,
        title: null,
        start_date: null,
      },
    };
  }

  checklistItemActivity(
    progressItem: IActivityProgressItem,
    checklistItem: IItemTrackingChecklistItem,
  ) {
    return {
      ...checklistItem.activity,
      project: {
        id: progressItem.project_id,
        title: null,
        start_date: null,
      },
    };
  }
}
