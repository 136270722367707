import {
  IBudgetTagItem,
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
} from '../../../../store/templates/templates.types';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { utils, writeFile } from 'xlsx';

export type IGeneralTemplate = IBudgetTagTemplate | IBudgetTemplate;
export type IGeneralTemplateItem = IBudgetTemplateItem | IBudgetTagItem;

const getIndex = (names: string[], baseName: string, index: number): number => {
  const newName = `${baseName}(${index})`;
  for (const savedName of names) {
    if (savedName === newName) {
      return getIndex(names, baseName, index + 1);
    }
  }
  return index;
};

/**
 * Excel sheet names can't be longer than 31 characters, and they must be unique.
 * @param originalTemplates
 */
export const getTemplatesWithSlicedAndUniqueNames = <T extends IGeneralTemplate>(
  originalTemplates: T[],
): T[] => {
  const templates: T[] = DeepCopyService.deepCopy(originalTemplates);

  const templateNames = new Set<string>();
  for (const template of templates) {
    const slicedName = template.name.slice(0, 27);
    template.name = slicedName;

    if (templateNames.has(slicedName)) {
      template.name = `${slicedName}(${getIndex(Array.from(templateNames), slicedName, 2)})`;
    }
    templateNames.add(template.name);
  }

  return templates;
};

export const exportOneTemplate = <T>(sheetData: T[], sheetName: string, fileName: string): void => {
  const workbook = utils.book_new();
  const sheet = utils.json_to_sheet(sheetData);
  utils.book_append_sheet(workbook, sheet, sheetName.slice(0, 31));
  writeFile(workbook, fileName);
};

export const exportTemplates = <T extends Partial<IGeneralTemplateItem>>(
  sheetData: T[][],
  sheetNames: string[],
  fileName: string,
): void => {
  const workbook = utils.book_new();
  for (const [index, template] of sheetData.entries()) {
    const sheet = utils.json_to_sheet(template);
    utils.book_append_sheet(workbook, sheet, sheetNames[index]);
  }
  writeFile(workbook, fileName);
};
