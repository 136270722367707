<main class="page-content">
  <!--  // todo: move into a component  -->
  <app-general-header title="Projects">
    <div class="header-inputs">
      <app-dropdown
        [multiple]="true"
        class="input statuses"
        name="projects"
        [options]="allProjectStatus$ | async"
        [(model)]="projectStatusIds"
        optionLabel="name"
        optionValue="id"
        label="Status"
        placeholder="Status"
        (modelChange)="onStatusChange($event)"
      >
        <ng-template #selectedContent let-option>
          {{ option.name | titlecase }}
        </ng-template>
        <ng-template #optionContent let-option>
          <div>
            {{ option.name | titlecase }}
          </div>
        </ng-template>
      </app-dropdown>

      <app-dropdown
        [multiple]="true"
        class="input properties"
        name="properties"
        [options]="properties$ | async"
        [(model)]="filterByPropertyIds"
        optionLabel="name"
        optionValue="id"
        label="Properties"
        placeholder="Filter Properties"
        (modelChange)="onPropertySelect($event)"
      >
        <ng-template #selectedContent let-option>
          {{ option.name | titlecase }}
        </ng-template>
        <ng-template #optionContent let-option>
          <div>
            {{ option.name | titlecase }}
          </div>
        </ng-template>
      </app-dropdown>

      <app-search-input
        (searchText)="onSearch($event)"
        class="input min-w-40 w-60"
        placeholder="Search Projects..."
      ></app-search-input>
    </div>
  </app-general-header>
  <app-dynamic-projected-table
    #dynamicProjectedTable
    [isLoading]="(isLoading$ | async) || (isFetching$ | async)"
    [isEmpty]="!(projects$ | async)?.length"
  >
    <div tableHeader class="grid-table">
      <div class="grid-row header-row">
        <div (click)="sortProjectsBy('project_id')" class="item">
          Project ID
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'project_id'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'project_id'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.project_id < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('title')" class="item">
          Project Name
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'title'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'title'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.title < 0"
          ></app-sort-arrow>
        </div>

        <div (click)="sortProjectsBy('current_budget')" class="item justify-center">
          <span pTooltip="The current or most updated project budget." tooltipPosition="bottom"> Budget </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'current_budget'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'current_budget'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.current_budget < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('total_committed')" class="item justify-center">
          <span
            pTooltip="Total amounts between contracts, change orders, and miscellaneous costs."
            tooltipPosition="bottom"
          >
            Committed
          </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'total_committed'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'total_committed'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.total_committed < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('forecast_modifications')" class="item justify-center">
          <span pTooltip="Total of anticipated costs." tooltipPosition="bottom"> Anticipated </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'forecast_modifications'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'forecast_modifications'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.forecast_modifications < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('forecasts_to_complete')" class="item justify-center">
          <span pTooltip="The balance between Budget and Committments." tooltipPosition="bottom">
            <span class="text-color_red_negative no-wrap">(Over)</span>/Under
          </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'forecasts_to_complete'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'forecasts_to_complete'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.forecasts_to_complete < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('total_invoiced')" class="item justify-center">
          <span
            pTooltip="The total amount of paid or unpaid invoices and miscellaneous costs."
            tooltipPosition="bottom"
          >
            Invoiced
          </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'total_invoiced'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'total_invoiced'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.total_invoiced < 0"
          ></app-sort-arrow>
        </div>
        <div (click)="sortProjectsBy('actuals')" class="item justify-center">
          <span pTooltip="The total amount of paid invoices and miscellaneous costs." tooltipPosition="bottom">
            Paid
          </span>
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'actuals'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'actuals'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.actuals < 0"
          ></app-sort-arrow>
        </div>
        <div class="item default-cursor pr-0">
          <span pTooltip="Indicators that show the progress of physical work and work paid." tooltipPosition="bottom"
            >Progress</span
          >
        </div>

        <div (click)="sortProjectsBy('project_status')" class="item p-0 col-span-3 pl-2 mx-2 xl:mx-4 2xl:mx-6">
          Status
          <app-sort-arrow
            *ngIf="dynamicProjectedTable.lastSortedKey === 'project_status'"
            [hide]="dynamicProjectedTable.lastSortedKey !== 'project_status'"
            [isDesc]="dynamicProjectedTable.lastSortedOrder?.project_status < 0"
          ></app-sort-arrow>
        </div>
      </div>
    </div>

    <div projectedTable class="grid-table">
      @for (project of projects; track project.id; let index = $index) {
        <div class="grid-row content-row">
          <div class="item font-semibold cursor-pointer" (click)="navigateToProject(project)">
            <div>{{ project.project_id }}</div>
            <div class="invisible">easter egg</div>
          </div>
          <div (click)="navigateToProject(project)" class="item cursor-pointer">
            <app-faded-text class="text-color_primary font-bold text-sm">{{ project.title }}</app-faded-text>
            <app-faded-text class="text-color_primary font-normal text-size_xs">{{
              project.project_property.name
            }}</app-faded-text>
          </div>
          <div
            [tooltipStyleClass]="project.current_budget < 0 ? 'negative' : ''"
            [pTooltip]="project.current_budget | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ 'text-negative': project.current_budget < 0 }"
            class="item text-center"
          >
            {{ project.current_budget | money: 'shortWithParentheses' }}
          </div>
          <div
            [tooltipStyleClass]="project.total_committed < 0 ? 'negative' : ''"
            [pTooltip]="project.total_committed | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ 'text-negative': project.total_committed < 0 }"
            class="item text-center"
          >
            {{ project.total_committed | money: 'shortWithParentheses' }}
          </div>
          <div
            [tooltipStyleClass]="project.forecast_modifications < 0 ? 'negative' : ''"
            [pTooltip]="project.forecast_modifications | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ 'text-negative': project.forecast_modifications < 0 }"
            class="item text-center"
          >
            {{ project.forecast_modifications | money: 'shortWithParentheses' }}
          </div>
          <div
            [ngClass]="{
              'text-negative': project.forecasts_to_complete < 0,
              'text-color_primary': project.forecasts_to_complete >= 0
            }"
            [tooltipStyleClass]="project.forecasts_to_complete < 0 ? 'negative' : ''"
            [pTooltip]="project.forecasts_to_complete | money: 'positiveComa'"
            tooltipPosition="bottom"
            class="item text-center flex items-center justify-center"
          >
            <app-simple-small-button wrapperClass="font-semibold bg-color_secondary/10">
              {{ project.forecasts_to_complete | money: 'shortWithParentheses' }}
            </app-simple-small-button>
          </div>
          <div
            [tooltipStyleClass]="project.total_invoiced < 0 ? 'negative' : ''"
            [pTooltip]="project.total_invoiced | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ 'text-negative': project.total_invoiced < 0 }"
            class="item text-center"
          >
            <span>
              {{ project.total_invoiced | money: 'shortWithParentheses' }}
            </span>
          </div>
          <div
            [tooltipStyleClass]="project.actuals < 0 ? 'negative' : ''"
            [pTooltip]="project.actuals | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ 'text-negative': project.actuals < 0 }"
            class="item text-center"
          >
            <span>
              {{ project.actuals | money: 'shortWithParentheses' }}
            </span>
          </div>
          <div class="item flex flex-col gap-1 pr-0">
            <app-progress-bar-projectable [percent]="project.work_percentage" fillClass="bg-shade_1" text="WORK">
            </app-progress-bar-projectable>

            <app-progress-bar-projectable
              [percent]="project.complete < 0 ? 100 : project.complete"
              [isNegative]="project.complete < 0"
              [allowBgColorChange]="project.complete >= 0"
              fillClass="bg-color_accent"
              text="PAID"
            >
              @if (project.complete < 0) {
                <div
                  class="text-color_red_negative negative no-leading font-bold flex gap-0 min-w-6 text-[8px]"
                  after-content
                >
                  {{ Math.abs(project.complete) }}%
                </div>
              }
            </app-progress-bar-projectable>
          </div>

          <app-status-dropdown
            class="flex items-center justify-center"
            [dropdownOptions]="allProjectStatuses"
            [selectedOption]="project.project_status"
            (selectedOptionsChange)="updateProjectStatus(project.id, $event)"
          >
          </app-status-dropdown>

          <div class="item flex items-center justify-center p-0">
            <i
              class="icon-lightning-rectangular text-shade_1"
              [pTooltip]="tooltipContent"
              tooltipStyleClass="latest-tooltip"
              tooltipPosition="bottom"
            ></i>
            <ng-template #tooltipContent>
              <div class="text-size_m min-w-40">
                <span class="font-bold text-xs">Latest Update</span>
                <div class="flex gap-2 items-start justify-start font-normal mt-3">
                  {{ project?.description?.short_description ?? 'Project is missing short summary.' }}
                </div>
              </div>
            </ng-template>
          </div>

          <div
            [pTooltip]="project.tags.length > 0 ? project.tags.join(', ') : 'Project is missing tags.'"
            tooltipPosition="left"
            class="item flex items-center justify-center p-0"
          >
            <i class="icon-triangle-dots"></i>
          </div>
        </div>
      }
    </div>

    <div tableFooter class="grid-table totals">
      <div class="grid-row">
        <div class="item">Totals</div>
        <div class="item">{{ (projectTotals$ | async).total_projects }}</div>
        <div
          [ngClass]="{ 'text-negative': (projectTotals$ | async)?.current_budget < 0 }"
          [tooltipStyleClass]="(projectTotals$ | async)?.current_budget < 0 ? 'negative' : ''"
          [pTooltip]="(projectTotals$ | async)?.current_budget | money: 'positiveComa'"
          tooltipPosition="top"
          class="item text-center"
        >
          {{ (projectTotals$ | async)?.current_budget | money: 'shortWithParentheses' }}
        </div>
        <div
          [ngClass]="{ 'text-negative': (projectTotals$ | async)?.total_committed < 0 }"
          [tooltipStyleClass]="(projectTotals$ | async)?.total_committed < 0 ? 'negative' : ''"
          [pTooltip]="(projectTotals$ | async)?.total_committed | money: 'positiveComa'"
          tooltipPosition="top"
          class="item text-center"
        >
          {{ (projectTotals$ | async)?.total_committed | money: 'shortWithParentheses' }}
        </div>
        <div
          [ngClass]="{ 'text-negative': (projectTotals$ | async)?.total_committed_vs_current_budget < 0 }"
          [tooltipStyleClass]="(projectTotals$ | async)?.total_committed_vs_current_budget < 0 ? 'negative' : ''"
          [pTooltip]="(projectTotals$ | async)?.total_committed_vs_current_budget | money: 'positiveComa'"
          tooltipPosition="top"
          class="item text-center flex justify-center"
        >
<<<<<<< HEAD:src/app/pages/webapp/contractors-project-listing/contractors-project-listing.component.html
          <app-simple-small-button wrapperClass="font-semibold bg-color_secondary/10">
            {{ (projectTotals$ | async)?.total_committed_vs_current_budget | money: 'shortWithParentheses' }}
          </app-simple-small-button>
=======
          {{ (projectTotals$ | async)?.forecast_modifications | money: 'shortWithParentheses' }}
>>>>>>> origin/develop:src/app/pages/webapp/rollups/project-listing/project-listing.component.html
        </div>
        <div
          [ngClass]="{ 'text-negative': (projectTotals$ | async)?.actuals < 0 }"
          class="item text-center"
          [tooltipStyleClass]="(projectTotals$ | async)?.actuals < 0 ? 'negative' : ''"
          [pTooltip]="(projectTotals$ | async)?.actuals | money: 'positiveComa'"
          tooltipPosition="bottom"
        >
          {{ (projectTotals$ | async)?.actuals | money: 'shortWithParentheses' }}
        </div>
      </div>
    </div>
  </app-dynamic-projected-table>
</main>
