import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { take } from 'rxjs';
import * as uuid from 'uuid';
import { FloatingInputComponent } from '@app/framework/inputs/floating-input/floating-input.component';
import { AddRemoveButtonsComponent } from '@app/framework/buttons/add-remove-buttons/add-remove-buttons.component';
import { NewScopeLineItem } from '@app/store/prime-commitments/prime-commitments.types';
import { DropdownComponent } from '@app/framework/inputs/dropdown/dropdown.component';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';

@Component({
  selector: 'app-pco-new-scope-entry',
  standalone: true,
  imports: [FloatingInputComponent, AddRemoveButtonsComponent, DropdownComponent, AsyncPipe],
  templateUrl: './pco-new-scope-entry.component.html',
  styleUrl: './pco-new-scope-entry.component.scss',
})
export class PcoNewScopeEntryComponent {
  private readonly store = inject(Store);
  protected readonly tempUUID = uuid.v4();
  @Input({ required: true }) index: number;
  @Input({ required: true }) lineItem: NewScopeLineItem;
  @Output() lineItemsChange = new EventEmitter<NewScopeLineItem>();

  lineItemOptions$ = this.store.select(primeCommitmentsSelectors.selectAllNonMarkupLineItems);

  descriptionChange($event: string, index: number) {
    this.lineItem.descriptions[index] = $event;
    this.lineItemsChange.emit(this.lineItem);
  }

  removeDescription(index: number) {
    this.lineItem.descriptions.splice(index, 1);
    this.lineItemsChange.emit(this.lineItem);
  }

  addDescription(index: number) {
    this.lineItem.descriptions.splice(index + 1, 0, '');
    this.lineItemsChange.emit(this.lineItem);
  }

  lineItemChange(id: number) {
    this.lineItemOptions$.pipe(take(1)).subscribe((nonMarkupLines) => {
      const lineItem = nonMarkupLines.find((line) => line.id === id);
      this.lineItemsChange.emit({
        ...lineItem,
        descriptions: this.lineItem.descriptions,
      });
    });
  }
}
