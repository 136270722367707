<div class="sidebar-wrapper">
  <app-sidebar-header (backButtonClicked)="goBack()" [showBackButton]="true">
    <div smallTitle>{{ isEdit ? 'Edit ' : 'Add ' }} Change Action</div>
    <div mainTitle>General Information</div>
  </app-sidebar-header>

  <form #PCOForm="ngForm" role="form" class="main-content flex flex-col">
    <app-search-input
      (searchText)="onSearch($event)"
      placeholder="Search PCOs by name or ID..."
      label=""
    ></app-search-input>

    @if ((openPCOs$ | async).length > 0) {
      <div class="flex items-center gap-6 px-2 min-h-14">
        <p-checkbox
          [name]="'pco_checkbox_all'"
          [ngModel]="(selectedPCOIds$ | async).length === (openPCOs$ | async).length"
          [binary]="true"
          (click)="toggleAllPCOs()"
        ></p-checkbox>
        <div class="text-color_primary font-bold text-base">PCO Title</div>
        <div class="text-color_primary font-bold ml-auto flex justify-center w-20">Estimated</div>
      </div>
      <div class="border-b border-b-solid border-shade_5"></div>
    }

    <ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
      @for (pco of filteredOpenPCOs$ | async; track pco.id + '_' + index; let index = $index) {
        <div class="flex items-center gap-6 my-2 px-2">
          <p-checkbox
            [name]="'pco_checkbox_' + pco.id"
            [value]="pco.id"
            (ngModelChange)="togglePCO(pco)"
            [ngModel]="selectedPCOIds$ | async"
            [inputId]="String(pco.id)"
          ></p-checkbox>
          <app-faded-text [displayGrid]="true">
            <div class="flex max-w-72 flex-col text-color_primary font-semibold text-sm">
              <span>{{ pco?.number ?? '-' }}</span>
              <span>{{ pco.title }}</span>
            </div>
          </app-faded-text>
          <div
            [class.negative]="pco.estimated_dollars < 0"
            class="ml-auto flex justify-center min-w-20 text-color_primary font-semibold text-sm text-center"
          >
            {{ pco.estimated_dollars | money: 'positiveComa' }}
          </div>
        </div>
        <div class="border-b border-b-solid border-shade_5"></div>
      } @empty {
        <div class="flex items-center justify-center h-20">
          <span class="font-bold">No available PCOs.</span>
        </div>
      }
    </ng-scrollbar>
  </form>
  <div class="mt-auto flex flex-col mx-8 mb-1">
    <div class="border-b border-b-solid border-shade_5"></div>

    <div class="flex justify-between my-5">
      <span class="text-color_primary font-bold text-sm">Total PCOs Selected</span>
      <span class="text-color_primary font-bold text-sm" [class.negative]="totalPCOs < 0">
        {{ totalPCOs | money: 'positiveComa' }}</span
      >

      <app-simple-button (click)="submitForm()" buttonStyle="color_accent_filled">
        {{ isEdit ? 'Update' : 'Save' }}
      </app-simple-button>
    </div>
  </div>
</div>
