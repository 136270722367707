<app-hidden-upload-input
  #hiddenFileInput
  (filesChange)="registerFileAdded($event)"
  (filesChangeRaw)="registerFileAddedRaw($event)"
  [allowMultipleFiles]="allowMultipleFiles"
  [allowSameFileUpload]="allowSameFileUpload"
  [acceptFileTypes]="acceptFileTypes"
></app-hidden-upload-input>

<ng-content>
  <div
    aria-hidden="true"
    (click)="hiddenFileInput.openFileUploadWindow()"
    [ngClass]="{ 'disabled color-cancel': disabled }"
    [pTooltip]="tooltipText"
    tooltipPosition="bottom"
    class="flex align-baseline justify-center cursor-pointer text-color_secondary max-h-[30px]"
  >
    <span [ngClass]="[iconClass]"></span>
  </div>
</ng-content>
