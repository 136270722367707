import { Component, inject, Input } from '@angular/core';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import {
  IBudgetTemplate,
  IBudgetTemplateItem,
} from '../../../../../store/templates/templates.types';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { Store } from '@ngrx/store';
import { CurrentUserService } from '../../../../../services/current-user.service';
import {
  isGCTemplateItem,
  isManagerTemplateItem,
} from '../../../../../store/templates/templates.constants';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-template-item-project-budget',
  standalone: true,
  imports: [FloatingInputComponent, CdkDragHandle, NgClass],
  templateUrl: './template-item-project-budget.component.html',
  styleUrl: './template-item-project-budget.component.scss',
})
export class TemplateItemProjectBudgetComponent {
  @Input({ required: true }) template: IBudgetTemplate;
  @Input({ required: true }) templateItem: IBudgetTemplateItem;

  readonly isManagerTemplate = isManagerTemplateItem;
  protected readonly isGCTemplateItem = isGCTemplateItem;
  readonly divisionTooltipText =
    'The parent line of the group is the only line that appears when creating a Prime Contract on the project. The sub-lines within the group are the only lines that appear when creating Commitments.';

  store = inject(Store);
  userService = inject(CurrentUserService);

  minusClicked() {
    this.store.dispatch(
      templatesActions.removeBudgetTemplateItem({ itemId: this.templateItem.id }),
    );
  }

  plusClicked() {
    if (this.userService.isManager) {
      this.onAddItem();
      return;
    }

    this.onAddSubitem();
  }

  fieldChanged(field: 'account_code' | 'division' | 'cost_type' | 'description', value: string) {
    this.store.dispatch(
      templatesActions.updateBudgetTemplateItem({
        templateItemId: this.templateItem.id,
        templateItem: { [field]: value },
      }),
    );
  }

  onAddItem() {
    this.store.dispatch(
      templatesActions.addEmptyBudgetTemplateItem({ isManager: this.userService.isManager }),
    );
  }

  onAddSubitem() {
    this.store.dispatch(
      templatesActions.addEmptySubitemToBudgetTemplateItem({ itemId: this.templateItem.id }),
    );
  }
}
