import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import {
  CHANGE_ORDER_REQUEST,
  ITEMS_SUMMARY,
  POTENTIAL_CHANGE_ORDER,
  PRIME_CONTRACTS,
  REST_PROJECTS,
} from '../restApi/RestRoutes';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  ChangeOrderRequestPayload,
  ChangeOrderRequestStatuses,
  CORDetail,
  CORLogItem,
  IPrimeContractModel,
  IPrimeContractModelResponse,
  IPrimeLineItemResponse,
  PCODetail,
  PCOItem,
  PCOItemCost,
  PCOLogItem,
  PCSpendDescriptionItem,
  PotentialChangeOrderModel,
  PotentialChangeOrderPayload,
  PotentialChangeOrderStatuses,
  PrimeContractPayload,
} from '../store/prime-commitments/prime-commitments.types';

@Injectable({
  providedIn: 'root',
})
export class PrimeCommitmentsApiService {
  private readonly rest = inject(RestRequestService);

  /**
   * Load all Potential Change Orders
   * @param projectId
   */
  getAllPCOs(projectId: number): Observable<PCOLogItem[]> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/pco`);
  }

  // todo: maybe use simple too? {{url}}/v1/projects/757/items/simple?onlySublines=1
  /**
   * Load all prime line item sub-lines
   * This should give sub-lines of chosen template for each group
   */
  getTemplateSublines(projectId: number): Observable<IPrimeLineItemResponse[]> {
    return this.rest.getWithObservable(
      `${REST_PROJECTS}/${projectId}/${ITEMS_SUMMARY}`,
      {},
      {
        onlySublines: 1,
      },
    );
  }

  /**
   * Load all prime line items
   * This should give only group items
   */
  getLineItemGroups(projectId: number): Observable<IPrimeLineItemResponse[]> {
    return this.rest.getWithObservable(
      `${REST_PROJECTS}/${projectId}/${ITEMS_SUMMARY}`,
      {},
      { onlyPrime: 1 },
    );
  }

  /**
   * Load all line items for prime contracts
   */
  getMarkupLineItems(projectId: number): Observable<IPrimeLineItemResponse[]> {
    return this.rest.getWithObservable(
      `${REST_PROJECTS}/${projectId}/${ITEMS_SUMMARY}`,
      {},
      { onlyPrime: 1, is_co_markup: 1 },
    );
  }

  /**
   * Load all line items for prime contracts
   */
  getNonMarkupLineItems(projectId: number): Observable<IPrimeLineItemResponse[]> {
    return this.rest.getWithObservable(
      `${REST_PROJECTS}/${projectId}/${ITEMS_SUMMARY}`,
      {},
      { onlyPrime: 1, is_co_markup: 0 },
    );
  }

  /**
   * Load the next PCO number in the sequence
   */
  getNextPCONumber(projectId: number): Observable<{ next_id: string }> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/pco/next-id`, {}, {});
  }

  /**
   * Load the next COR number in the sequence
   */
  getNextCORNumber(projectId: number): Observable<{ next_id: string }> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/cor/next-id`, {}, {});
  }

  isPCONumberUnique(projectId: number, pcoNumber: string): Observable<{ message: string }> {
    return this.rest.postWithObservable(
      `${REST_PROJECTS}/${projectId}/pco/validate-id`,
      {
        number: pcoNumber,
      },
      {},
    );
  }

  isCORNumberUnique(projectId: number, number: string): Observable<{ message: string }> {
    return this.rest.postWithObservable(
      `${REST_PROJECTS}/${projectId}/cor/validate-id`,
      {
        number,
      },
      {},
    );
  }

  /**
   * @param projectId
   * @param primeContract
   * Create a prime contract
   */
  createPrimeContract(
    projectId: number,
    primeContract: IPrimeContractModel,
  ): Observable<PrimeContractPayload> {
    const body = this.convertToPrimeContractPayload(projectId, primeContract);
    const strippedBody = this.stripFrontendIds(body);
    return this.rest.postWithObservable(`${PRIME_CONTRACTS}`, strippedBody);
  }

  /**
   * Update a prime contract
   * @param projectId
   * @param primeContract
   */
  updatePrimeContract(projectId: number, primeContract: IPrimeContractModel) {
    const body = this.convertToPrimeContractPayload(projectId, primeContract);
    const strippedBody = this.stripFrontendIds(body);
    return this.rest.putWithObservable(`${PRIME_CONTRACTS}/${primeContract.id}`, strippedBody);
  }

  /**
   * Delete spend entry UUIDs created from the body as these are used only in the FE
   * @param body
   */
  stripFrontendIds(body: PrimeContractPayload) {
    body.spend_entries.forEach((entry) => {
      if (uuid.validate(entry.id) || entry.id === null) {
        delete entry.id;
      }
    });
    return body;
  }

  /**
   * Convert the prime contract model to a payload, which can be sent to the server
   * @param projectId
   * @param primeContract
   */
  convertToPrimeContractPayload(
    projectId: number,
    primeContract: IPrimeContractModel,
  ): PrimeContractPayload {
    return {
      project_id: projectId,
      title: primeContract.title,
      owner_name: primeContract.owner_name,
      date: primeContract.date,
      status: primeContract.status,
      description: primeContract.description,
      spend_entries: primeContract.spendEntries.map((item) => {
        return {
          id: item.id,
          item_id: item.item_id,
          index: item.index,
          spend_descriptions: item.spend_descriptions.map((description) => {
            return {
              id: description.id,
              index: description.index,
              description: description.description,
              value: Number(description.value),
            };
          }),
        };
      }),
      invoice_retainage: {
        default_rate: Number(primeContract.defaultInvoiceRetainageRate),
        exceptions: primeContract.exceptions,
      },
      markup_groups: primeContract.markupGroups,
    };
  }

  /**
   * Loads the prime contract associated with a project.
   * @param projectId
   */
  loadPrimeContractByProjectId(projectId: number): Observable<IPrimeContractModelResponse> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/prime-contracts`);
  }

  /**
   * Delete a prime contract
   * @param primeContractId
   */
  deletePrimeContract(primeContractId: number) {
    return this.rest.delWithObservable(`${PRIME_CONTRACTS}/${primeContractId}`);
  }

  /**
   * @param projectId
   * @param pco - Potential Change Order
   * Create a potential change order
   */
  createPCO(projectId: number, pco: PotentialChangeOrderModel) {
    const body = this.convertToPCOPayload(projectId, pco);
    return this.rest.postWithObservable(`${POTENTIAL_CHANGE_ORDER}`, body);
  }

  /**
   * Create a change order request
   * @param projectId
   * @param pco
   */
  createCOR(projectId: number, pco: PotentialChangeOrderModel) {
    const body = this.convertToCORPayload(projectId, pco);
    return this.rest.postWithObservable(`${CHANGE_ORDER_REQUEST}`, body);
  }

  updateCOR(projectId: number, pco: PotentialChangeOrderModel) {
    const body = this.convertToCORPayload(projectId, pco);
    return this.rest.putWithObservable(`${CHANGE_ORDER_REQUEST}/${pco.generalData.id}`, body);
  }

  convertToCORPayload(
    projectId: number,
    model: PotentialChangeOrderModel,
  ): ChangeOrderRequestPayload {
    return {
      project_id: projectId,
      title: model.generalData.title,
      duration_extension: model.generalData.duration_extension,
      number: model.generalData.number,
      date: model.generalData.date,
      status: model.generalData.status as ChangeOrderRequestStatuses,
      description: model.generalData.description,
      pcos: model.selectedPCOIds.map((id): { id: number } => {
        return {
          id,
        };
      }),
    };
  }

  convertToPCOPayload(
    projectId: number,
    model: PotentialChangeOrderModel,
  ): PotentialChangeOrderPayload {
    return {
      project_id: projectId,
      title: model.generalData.title,
      duration_extension: model.generalData.duration_extension,
      number: model.generalData.number,
      date: model.generalData.date,
      status: model.generalData.status as PotentialChangeOrderStatuses,
      scope: model.generalData.scope,
      description: model.generalData.description,
      pco_items: model.lineItems.map((item): PCOItem => {
        return {
          item_id: item.id,
          budget_dollars: 0,
          commitment_dollars: 0,
          pco_item_costs: (model.generalData.scope === 'new' ? item.descriptions : item.costs).map(
            (value: PCSpendDescriptionItem | string): PCOItemCost => {
              return {
                pc_spend_description_id:
                  model.generalData.scope === 'existing'
                    ? (value as PCSpendDescriptionItem).id
                    : null,
                spend_description:
                  model.generalData.scope === 'existing'
                    ? (value as PCSpendDescriptionItem).name
                    : (value as string),
                budget_dollars: 0,
                commitment_dollars: 0,
                cco_id: null,
              };
            },
          ),
        };
      }),
    };
  }

  /**
   * Load a potential change order by id
   * @param id
   */
  loadPCODetails(id: number): Observable<PCODetail> {
    return this.rest.getWithObservable(`${POTENTIAL_CHANGE_ORDER}/${id}`);
  }

  loadCORDetails(id: number): Observable<CORDetail> {
    return this.rest.getWithObservable(`${CHANGE_ORDER_REQUEST}/${id}`);
  }

  /**
   * Update a potential change order
   * @param PCO
   */
  updatePCODetails(PCO: PCODetail) {
    return this.rest.putWithObservable(`${POTENTIAL_CHANGE_ORDER}/${PCO.id}`, PCO);
  }

  /**
   * Delete a potential change order by id
   * @param id
   */
  deletePCOLogItem(id: number) {
    return this.rest.delWithObservable(`${POTENTIAL_CHANGE_ORDER}/${id}`);
  }

  deleteCORLogItem(id: number) {
    return this.rest.delWithObservable(`${CHANGE_ORDER_REQUEST}/${id}`);
  }

  requestSCO(contractId: number, costID: number | string) {
    return this.rest.postWithObservable(`${POTENTIAL_CHANGE_ORDER}/request-cco`, {
      pco_item_cost_id: costID,
      contract_id: contractId,
    });
  }

  /**
   * Load all Potential Change Orders
   * @param projectId
   */
  getAllCORs(projectId: number): Observable<CORLogItem[]> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/cor`);
  }
}
