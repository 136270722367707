import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import { BehaviorSubject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownComponent } from '@app/framework/inputs/dropdown/dropdown.component';
import {
  ExistingScopeLineItem,
  PCSpendDescriptionItem,
} from '@app/store/prime-commitments/prime-commitments.types';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { AddRemoveButtonsComponent } from '@app/framework/buttons/add-remove-buttons/add-remove-buttons.component';

@Component({
  selector: 'app-pco-existing-scope-entry',
  standalone: true,
  imports: [AsyncPipe, DropdownComponent, AddRemoveButtonsComponent],
  templateUrl: './pco-existing-scope-entry.component.html',
  styleUrl: './pco-existing-scope-entry.component.scss',
})
export class PcoExistingScopeEntryComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  protected readonly tempUUID = uuid.v4();
  @Input({ required: true }) index: number;
  @Input({ required: true }) lineItem: ExistingScopeLineItem;
  @Output() lineItemsChange = new EventEmitter<ExistingScopeLineItem>();

  lineItemOptions$ = this.store.select(
    primeCommitmentsSelectors.selectNonMarkupLineItemsWithCostsFromPrimeContract,
  );
  selectPCLineItemToCostMap$ = this.store.select(
    primeCommitmentsSelectors.selectPCLineItemToCostMap,
  );
  lineIdToCosts$ = new BehaviorSubject<Map<number, PCSpendDescriptionItem[]>>(new Map());
  isDestroyed$ = new EventEmitter<void>();

  ngOnInit() {
    this.selectPCLineItemToCostMap$.pipe(takeUntil(this.isDestroyed$)).subscribe((map) => {
      this.lineIdToCosts$.next(map);
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.emit();
    this.isDestroyed$.complete();
  }

  lineItemChange(id: number) {
    this.lineItemOptions$.pipe(take(1)).subscribe((nonMarkupLines) => {
      const lineItem = nonMarkupLines.find((line) => line.id === id);
      this.lineItemsChange.emit({
        ...lineItem,
        costs: this.lineItem.costs,
      } as ExistingScopeLineItem);
    });
  }

  removeCost(index: number) {
    this.lineItem.costs.splice(index, 1);
    this.lineItemsChange.emit(this.lineItem);
  }

  addCost(index: number) {
    this.lineItem.costs.splice(index + 1, 0, { name: '', id: null });
    this.lineItemsChange.emit(this.lineItem);
  }

  costChange($event: string, costIndex: number) {
    this.lineItem.costs[costIndex] = this.lineIdToCosts$
      .getValue()
      .get(this.lineItem.id)
      .find((i) => i.id === $event);
    this.lineItemsChange.emit(this.lineItem);
  }
}
