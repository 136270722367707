import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { Store } from '@ngrx/store';
import { CheckboxModule } from 'primeng/checkbox';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SimpleButtonComponent } from '../../../../buttons/simple-medium-button/simple-button.component';
import { SidebarHeaderComponent } from '../../../sidebar-header/sidebar-header.component';
import { SearchInputComponent } from '../../../../search-input/search-input.component';
import { FadedTextComponent } from '../../../../faded-text/faded-text.component';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { MoneyPipe } from '@app/pipes/framework/money-short.pipe';
import { PCOLogItem } from '@app/store/prime-commitments/prime-commitments.types';
import { primeChangesActions } from '@app/store/prime-commitments/prime-commitments.actions';

@Component({
  selector: 'app-change-order-request-sidebar',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NgScrollbar,
    SimpleButtonComponent,
    SidebarHeaderComponent,
    CheckboxModule,
    MoneyPipe,
    SearchInputComponent,
    FadedTextComponent,
  ],
  templateUrl: './change-order-request-sidebar.component.html',
  styleUrl: './change-order-request-sidebar.component.scss',
})
export class ChangeOrderRequestSidebarComponent implements OnInit, OnDestroy {
  protected readonly String = String;
  private readonly store = inject(Store);

  @Input({ required: true }) name: string;
  @Input({ required: true }) selectedPCOIds: number[] = [];
  @Input({ required: true }) isEdit = false;
  @Input({ required: true }) CORId: number;
  @Output() selectedPCOIdsChange = new EventEmitter<number[]>();
  @Output() back = new EventEmitter<void>();
  @Output() saveForm = new EventEmitter<void>();

  openPCOs$ = this.store
    .select(primeCommitmentsSelectors.selectOpenPCOs)
    .pipe(map((PCOs) => PCOs.filter((PCO) => PCO.cor?.id === this.CORId || PCO.cor === null)));
  filteredOpenPCOs$ = this.store
    .select(primeCommitmentsSelectors.selectFilteredOpenPCOs)
    .pipe(map((PCOs) => PCOs.filter((PCO) => PCO.cor?.id === this.CORId || PCO.cor === null)));
  selectedPCOIds$ = new BehaviorSubject<number[]>([]);
  isDestroyed$ = new Subject<void>();

  totalPCOs = 0;

  ngOnInit() {
    this.selectedPCOIds$.next(this.selectedPCOIds);
    combineLatest([this.openPCOs$, this.selectedPCOIds$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([PCOs, selectedPCOs]) => {
        this.totalPCOs = PCOs.filter((PCO) => selectedPCOs.includes(PCO.id)).reduce(
          (acc, PCO) => acc + PCO.estimated_dollars,
          0,
        );
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  submitForm() {
    this.selectedPCOIdsChange.emit(this.selectedPCOIds$.value);
    this.saveForm.emit();
  }

  goBack() {
    this.back.emit();
  }

  onSearch($event: string) {
    this.store.dispatch(primeChangesActions.onCORSidebarPCOSearchChange({ search: $event }));
  }

  toggleAllPCOs() {
    this.openPCOs$.pipe(take(1)).subscribe((openPCOs) => {
      const allSelected = this.selectedPCOIds$.value.length === openPCOs.length;
      this.selectedPCOIds$.next(allSelected ? [] : openPCOs.map((PCO) => PCO.id));
    });
  }

  togglePCO(PCO: PCOLogItem) {
    const alreadyIncluded = this.selectedPCOIds$.value.includes(PCO.id);
    this.selectedPCOIds$.next(
      alreadyIncluded
        ? this.selectedPCOIds$.value.filter((id) => id !== PCO.id)
        : [...this.selectedPCOIds$.value, PCO.id],
    );
  }
}
