import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { FormsModule, NgForm } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { TooltipModule } from 'primeng/tooltip';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { SimpleButtonComponent } from '../../buttons/simple-medium-button/simple-button.component';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import {
  changeOrderRequestStatuses,
  potentialChangeOrderStatuses,
} from '@app/store/prime-commitments/prime-commitments.constants';
import { PrimeChangesGeneral } from '@app/store/prime-commitments/prime-commitments.types';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { primeChangesActions } from '@app/store/prime-commitments/prime-commitments.actions';
import { NotificationsService } from '@app/services/notifications.service';

@Component({
  selector: 'app-change-action-general',
  standalone: true,
  imports: [
    NgScrollbar,
    FormsModule,
    FloatingInputComponent,
    InputCalendarComponent,
    DropdownComponent,
    SimpleButtonComponent,
    CdkTextareaAutosize,
    SidebarHeaderComponent,
    TooltipModule,
  ],
  templateUrl: './change-action-general.component.html',
  styleUrl: './change-action-general.component.scss',
})
export class ChangeActionGeneralComponent implements AfterViewInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly notif = inject(NotificationsService);

  @ViewChild('generalForm') generalForm: NgForm;

  @Input() model: PrimeChangesGeneral;
  @Output() modelChange = new EventEmitter<PrimeChangesGeneral>();
  @Output() next = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
  @Output() updateForm = new EventEmitter<void>();
  @Output() scopeChange = new EventEmitter<void>();

  statuses: typeof potentialChangeOrderStatuses | typeof changeOrderRequestStatuses =
    changeOrderRequestStatuses;
  PCOTypes = ['Potential Change Order', 'Change Order Request'];
  customIdDisabled = true;
  currentPage: 1 | 2 = 1;
  possibleDurationExtensions: string[] = Array(100)
    .fill(0, 0, 100)
    .map((_, i) => String(i));
  scopeOptions = [
    {
      label: 'Change Existing Scope',
      value: 'existing',
    },
    {
      label: 'Add New Scope',
      value: 'new',
    },
  ];

  isPCOValid$ = this.store.select(primeCommitmentsSelectors.selectIsPCONumberValid);
  isCORValid$ = this.store.select(primeCommitmentsSelectors.selectIsCORNumberValid);
  nextPCONumber$ = this.store.select(primeCommitmentsSelectors.selectNextPCONumber);
  nextCORNumber$ = this.store.select(primeCommitmentsSelectors.selectNextCORNumber);
  isDestroyed$ = new Subject<void>();

  ngAfterViewInit() {
    this.validateNumber();
    if (this.model.status) {
      this.setStatusOptions();
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  onNext() {
    this.generalForm.form.markAllAsTouched();

    if (this.generalForm.form.controls.number.errors?.pattern) {
      this.notif.showError('Number is already taken');
    }

    if (this.generalForm.form.invalid) {
      return;
    }

    this.next.emit();
  }

  onCustomIdIconClick() {
    if (!this.customIdDisabled) {
      this.setNextNumber();
    }

    this.customIdDisabled = !this.customIdDisabled;
  }

  onBack() {
    this.back.emit();
  }

  setNextNumber() {
    if (this.model.type === 'Potential Change Order') {
      this.setPCONumber();
      return;
    }
    this.setNextCORNumber();
  }

  private setNextCORNumber() {
    this.nextCORNumber$
      .pipe(
        filter((data) => data !== null),
        take(1),
      )
      .subscribe((nextNumber) => {
        this.model.number = nextNumber;
      });
  }

  private setPCONumber() {
    this.nextPCONumber$
      .pipe(
        filter((data) => data !== null),
        take(1),
      )
      .subscribe((nextPCONumber) => {
        this.model.number = nextPCONumber;
      });
  }

  /**
   * Validates the PCO number in the store if it's unique
   * @param number
   * @constructor
   */
  NumberChanged(number: string) {
    if (this.model.type === 'Change Order Request') {
      this.store.dispatch(primeChangesActions.onCORNumberChange({ number }));
      return;
    }
    this.store.dispatch(primeChangesActions.onPCONumberChange({ number }));
  }

  validateNumber() {
    combineLatest([this.isCORValid$, this.isPCOValid$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([isCORValid, isPCOValid]) => {
        if (
          !this.generalForm?.form?.controls?.number ||
          this.generalForm?.form?.controls?.number?.value === '' ||
          !this.model.type
        ) {
          return;
        }

        const isValid = this.model.type === 'Change Order Request' ? isCORValid : isPCOValid;

        if (isValid) {
          this.generalForm.form.controls.number.setErrors(null);
          return;
        }

        this.generalForm.form.controls.number.setErrors({ pattern: true });
      });
  }

  updateModel() {
    this.updateForm.emit();
  }

  /**
   * Sets the status options based on the selected type
   * Clear already selected status
   * update COR or PCO number ID from backend
   */
  actionTypeChanged() {
    this.setStatusOptions();
    this.model.status = '';
    this.setNextNumber();
  }

  setStatusOptions() {
    if (this.model.type === 'Change Order Request') {
      this.statuses = changeOrderRequestStatuses;
      return;
    }
    this.statuses = potentialChangeOrderStatuses;
  }

  onScopeChange() {
    this.scopeChange.emit();
  }
}
