import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { CommitmentSidebarComponent } from '../commitment-sidebar/commitment-sidebar.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app-state';
import { NotificationsService } from '../../../../services/notifications.service';
import { StorageService } from '../../../../services/storage.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import {
  defaultInvoice,
  defaultInvoiceCost,
} from '../../../../store/commitments/commitments.constants';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import {
  COMPANY_DROPDOWN_SELECT_TYPES,
  CompanyDropdownComponent,
  ICompanyDropdownSelectEvent,
} from '../company-dropdown/company-dropdown.component';
import { FormsModule, NgModel } from '@angular/forms';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { CostDescriptionComponent } from '../cost-description/cost-description.component';
import {
  COMMITMENTS_INTERACTION_BAR_TYPE,
  COMMITMENTS_TYPE,
} from '../../../constants/interaction-bar.constants';
import { IBudgetTagItem, IBudgetTagTemplate } from '../../../../store/templates/templates.types';
import { templateSelectors } from '../../../../store/templates/templates.selectors';
import { combineLatest } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { fadeInGeneral } from '../../../../../assets/styles/animations';
import {
  AllCommitmentsSummaryContracts,
  ISidebarInvoice,
  ISidebarInvoiceCost,
} from '../../../../store/commitments/commitments.types';
import { dropdownOverlayPositions } from '../../../overlays/option-list.constants';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { BudgetTagTemplatesDropdownComponent } from '../../budget-tag-templates-dropdown/budget-tag-templates-dropdown.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayGeneralComponent } from '../../../overlays/overlay-general/overlay-general.component';
import { ArrowButtonBoxComponent } from '../../../dropdown-button-box/arrow-button-box.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { InputTextModule } from 'primeng/inputtext';
import { UploadCommitmentsSidebarComponent } from '../upload-commitments-sidebar/upload-commitments-sidebar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { CommitmentSidebarTitleComponent } from '../commitment-sidebar-title/commitment-sidebar-title.component';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { FloatingTextareaComponent } from '../../../inputs/floating-textarea/floating-textarea.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';

@Component({
  selector: 'app-invoice-sidebar',
  templateUrl: './invoice-sidebar.component.html',
  styleUrls: [
    './invoice-sidebar.component.scss',
    '../commitment-sidebar/commitment-sidebar.component.scss',
  ],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [
    NgIf,
    CommitmentSidebarTitleComponent,
    CommitmentEntryTextComponent,
    NgScrollbar,
    FormsModule,
    UploadCommitmentsSidebarComponent,
    InputTextModule,
    CdkOverlayOrigin,
    NgClass,
    ArrowButtonBoxComponent,
    OverlayGeneralComponent,
    CompanyDropdownComponent,
    DropdownModule,
    InputCalendarComponent,
    InputTextareaModule,
    BudgetTagTemplatesDropdownComponent,
    NgFor,
    CostDescriptionComponent,
    AsyncPipe,
    DecimalPipe,
    MoneyPipe,
    DropdownComponent,
    FloatingInputComponent,
    FloatingTextareaComponent,
    SimpleButtonComponent,
  ],
})
export class InvoiceSidebarComponent extends CommitmentSidebarComponent implements AfterViewInit {
  @ViewChildren('costDescription') costDescriptionComponents: QueryList<CostDescriptionComponent>;

  totalInvoiceCost$ = this.store.select(commitmentsSelectors.totalInvoiceCostDescription);
  contracts$ = this.store.select(commitmentsSelectors.getAllContractsSummary);
  sidebarInvoice$ = this.store.select(commitmentsSelectors.getSidebarInvoice);
  costs$ = this.store.select(commitmentsSelectors.getInvoiceCosts);
  sidebarContract$ = this.store
    .select(commitmentsSelectors.getSidebarSelectedContractSummary)
    .pipe(filter((contractSummary) => !!contractSummary));
  budgetTags$ = this.store
    .select(templateSelectors.getBudgetTagTemplates)
    .pipe(filter((templates) => !!templates));
  selectableInvoiceCosts$ = this.store.select(commitmentsSelectors.getSelectableInvoiceCosts);
  allContractorsArray$ = this.store.select(commitmentsSelectors.allContractorsArray);

  filteredContractsByCompany: AllCommitmentsSummaryContracts[] = [];
  isDropdownShown = {
    contract_id: false,
    paid_status: false,
    service_provider: false,
    budget_tag: false,
  };
  model: Partial<ISidebarInvoice> = {
    ...defaultInvoice,
  };

  costs: ISidebarInvoiceCost[] = [{ ...defaultInvoiceCost }];

  commitmentType = COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES;
  budgetTagTemplates: IBudgetTagTemplate[];
  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;
  disableAddDescription = false;
  canAddMoreInvoiceCost$ = this.store.select(commitmentsSelectors.canAddMoreInvoiceCost);

  constructor(
    protected store: Store<AppState>,
    protected notif: NotificationsService,
    protected storage: StorageService,
    protected user: CurrentUserService,
    protected interactionBar: InteractionBarStateService,
  ) {
    super(store, notif, storage, user, interactionBar);
  }

  ngAfterViewInit() {
    this.sidebarInvoice$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      if (data) {
        this.model = DeepCopyService.deepCopy(data);

        if (!this.model.budget_tag) {
          this.model.budget_tag = {
            name: '',
            id: null,
          };
        }

        this.isEdit = !!data.id;
        this.filterContractsBySelectedCompany();
      }
    });

    this.costs$
      .pipe(takeUntil(this.isDestroyed$))
      .pipe(withLatestFrom(this.selectableInvoiceCosts$))
      .subscribe(([costs, selectableCosts]) => {
        this.costs = DeepCopyService.deepCopy(costs);
        this.disableAddDescription = this.costs.length >= selectableCosts.length;
      });

    combineLatest([this.sidebarContract$, this.budgetTags$])
      .pipe(
        takeUntil(this.isDestroyed$),
        map(([contractSummary, tagTemplates]) => {
          // filter all tags, display only those which are present in a contract or in a change order
          return tagTemplates
            .map((template) => {
              const templateCopy = cloneDeep(template);
              templateCopy.tags = templateCopy.tags.filter((tag) => {
                const tagInContract = !!contractSummary.contract.commitment_items.find(
                  (item) => item?.budget_tag?.id === tag.id,
                );
                let tagInChangeOrder = false;
                contractSummary.change_orders.forEach((changeOrder) => {
                  const commitmentItem = changeOrder.commitment_items.find(
                    (item) => item?.budget_tag?.id === tag.id,
                  );
                  if (!!commitmentItem) {
                    tagInChangeOrder = true;
                  }
                });

                return tagInContract || tagInChangeOrder;
              });
              return templateCopy;
            })
            .filter((template) => template.tags?.length > 0);
        }),
      )
      .subscribe((templates) => {
        this.budgetTagTemplates = templates;
      });
  }

  updateAddedAtDate(date: string) {
    this.store.dispatch(
      commitmentsActions.updateSelectedInvoice({
        invoice: { ...this.model, added_at: date },
      }),
    );
  }

  updatePaidDate(date: string) {
    this.store.dispatch(
      commitmentsActions.updateSelectedInvoice({
        invoice: { ...this.model, paid_date: date },
      }),
    );
  }

  updateInvoice() {
    this.store.dispatch(
      commitmentsActions.updateSelectedInvoice({
        invoice: this.model,
      }),
    );
  }

  selectedCompany(event: ICompanyDropdownSelectEvent, serviceProviderModel: NgModel) {
    switch (event.type) {
      case COMPANY_DROPDOWN_SELECT_TYPES.SELECT: {
        this.model.service_provider = {
          name: event.data.company_name,
        };
        if (event.isTemporaryContractor) {
          this.model.service_provider.temporary_service_provider_id = event.data.id;
        } else {
          this.model.service_provider.service_provider_user_id = event.data.id;
        }

        this.model.contract_id = null;
        this.formFirstPage?.form?.get('contract_id')?.markAsUntouched();
        this.formFirstPage?.form?.get('contract_id')?.markAsPristine();

        this.updateInvoice();
        break;
      }
      default: {
        console.warn('Action not handled', event);
        break;
      }
    }
    this.isDropdownShown.service_provider = false;
  }

  save() {
    const costs = Array.from(this.costDescriptionComponents);
    if (!costs?.length) {
      this.notif.showError('Please add at least one description.');
      return;
    }
    let isValid = true;

    costs.forEach((cost) => {
      cost.costDescriptionForm.form.markAllAsTouched();
      isValid = isValid && cost.costDescriptionForm.form.valid;
    });

    if (isValid) {
      this.saveOrModify();
    }
  }

  saveOrModify() {
    if (this.model.id) {
      this.notif.showLoading('Updating invoice...');
      this.store.dispatch(commitmentsActions.modifyInvoice());
      return;
    }
    this.notif.showLoading('Saving invoice...');
    return this.store.dispatch(commitmentsActions.saveInvoice());
  }

  loadContractSummaryDetails() {
    console.log(this.model.contract_id);
    this.store.dispatch(
      commitmentsActions.loadSidebarSelectedContractSummary({ contractId: this.model.contract_id }),
    );
  }

  addNewCost() {
    this.store.dispatch(commitmentsActions.addCostToInvoice());
  }

  async selectedBudgetTag(budgetTagItem: IBudgetTagItem) {
    this.isDropdownShown.budget_tag = false;

    let agreedToChange = true;
    if (this.model.costs?.length > 0 && this.model.costs[0]?.name) {
      agreedToChange = await this.notif.showPopup(
        'Changing the budget tag will reset all spend descriptions. Do you agree?',
      );
    }

    if (agreedToChange) {
      this.model.budget_tag = budgetTagItem;
      this.model.costs = [{ ...defaultInvoiceCost }];
      this.updateInvoice();
    }
  }

  unlinkTemplate() {
    this.selectedAllBudgetTags();
  }

  selectedAllBudgetTags() {
    this.model.budget_tag = { name: 'All tags', id: null };
    this.isDropdownShown.budget_tag = false;
    this.updateInvoice();
  }

  protected readonly COMMITMENTS_TYPE = COMMITMENTS_TYPE;
}
