<app-commitment-sidebar-title
  [showBackButton]="true"
  [title]="model.id ? 'Edit Prime Contract' : 'Add Prime Contract'"
  (goBack)="goBack()"
>
</app-commitment-sidebar-title>

<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex mb-2.5 flex-col flex-1">
    <form class="h-full" #COMarkups="ngForm" role="form">
      @for (group of model.markupGroups; track groupIndex; let groupIndex = $index; let isLast = $last) {
        <app-commitment-entry-text
          (removeClicked)="removeGroup(groupIndex)"
          [index]="groupIndex"
          text="Change Order Markup Group"
        ></app-commitment-entry-text>

        <div [class.mb-4]="isLast" class="mx-7">
          <!--          todo: disable already chosen options from other groups-->
          <!--          todo: do not allow to select option with same index, ex: group 2 cannot select subtotal + group 2 but it can select any other available option-->
          <app-dropdown
            required
            [(model)]="group.base_group_indexes"
            placeholder="'Select Markup Base"
            label="Select Markup Base"
            [name]="'markup_base_group_' + groupIndex"
            class="w-full"
            [options]="allMarkupGroupCombos"
          >
          </app-dropdown>
          @for (markup of group.markups; track markupIndex; let markupIndex = $index) {
            <div class="flex items-center gap-2">
              <app-dropdown
                required
                [optionLabel]="null"
                [optionValue]="'description_id'"
                [(model)]="markup.description_id"
                [placeholder]="'Schedule Of Value ' + (markupIndex + 1)"
                [label]="'Schedule Of Value ' + (markupIndex + 1)"
                [name]="'schedule_of_value_' + groupIndex + '_' + markupIndex"
                class="w-full"
                [options]="allScheduleOfValues"
              >
                <ng-template #selectedContent #optionContent let-option>
                  <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
                </ng-template>
              </app-dropdown>

              <app-floating-input
                required
                class="w-full max-w-32 overflow-hidden"
                [(model)]="markup.rate"
                [name]="'rate_' + groupIndex + '_' + markupIndex"
                label="Rate %"
                placeholder="Rate %"
                type="number"
              ></app-floating-input>

              <app-add-remove-buttons
                class="mt-3.5"
                (removeItem)="removeItem(groupIndex, markupIndex)"
                (addItem)="addItem(groupIndex, markupIndex)"
              >
              </app-add-remove-buttons>
            </div>
          }
        </div>
      }
      <app-simple-button
        class="mx-7 my-2 flex"
        size="large"
        [wrapperClass]="{ 'w-full': true }"
        buttonStyle="color_secondary_border"
        (click)="addGroup()"
        >Add Group
      </app-simple-button>
    </form>
  </div>
</ng-scrollbar>
<div class="flex my-6 mx-7 justify-end gap-4">
  <app-simple-button (click)="goToNextPage()">Next</app-simple-button>
</div>
