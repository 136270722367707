<app-hidden-upload-input
  #simpleUploadUiComponent
  (filesChange)="onFileInputChange($event)"
  [allowMultipleFiles]="allowMultipleFiles"
></app-hidden-upload-input>

<ng-content>
  <!-- Default content if no content projection from outside -->
  <app-default-upload-ui
    (fileDropped)="onFileDropped($event)"
    (openWindow)="simpleUploadUiComponent.openFileUploadWindow()"
    (removeFile)="onRemoveFile($event)"
    [disableUpload]="disableUpload"
    [files]="files"
    [hideRemoveFileIcon]="hideRemoveFileIcon"
    [label]="label"
    [placeholder]="placeholder"
  ></app-default-upload-ui>
</ng-content>
