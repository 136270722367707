import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Intercom } from '@supy-io/ngx-intercom';
import { Router } from '@angular/router';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { PricingBasicCardComponent } from '../../../framework/pricing/pricing-basic-card/pricing-basic-card.component';
import { PricingPremiumCardComponent } from '../../../framework/pricing/pricing-premium-card/pricing-premium-card.component';
import { PricingEnterpriseCardComponent } from '../../../framework/pricing/pricing-enterprise-card/pricing-enterprise-card.component';
import {
  getUserSubscription,
  hasActiveOwnSubscription,
  ISubscriptionPlan,
} from '../../../framework/constants/subscription.constants';
import { SubscriptionService } from '../../../services/subscription.service';
import { NotificationsService } from '../../../services/notifications.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { User } from '../../../framework/constants/user.constants';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    CommonModule,
    PricingBasicCardComponent,
    PricingPremiumCardComponent,
    PricingEnterpriseCardComponent,
    InputSwitchModule,
    FormsModule,
  ],
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit, OnDestroy {
  plans: ISubscriptionPlan[] = [];
  isYearly = false;
  basicPlan: ISubscriptionPlan;
  premiumPlan: ISubscriptionPlan;
  triggerAnimation = false;
  isLoading = true;

  buttonText = 'Free 30 day trial';
  user: User;
  hasEndedTrial = false;
  isUserInfoLoading = true;
  subscriptionEnded = false;
  hasTeamSubscription = false;

  private _canTrial = true;
  set canTrial(value: boolean) {
    this._canTrial = value;
    this.buttonText = value ? 'Free 30 day trial' : 'Subscribe';
  }
  get canTrial(): boolean {
    return this._canTrial;
  }

  isDestroyed$ = new Subject();

  constructor(
    private intercom: Intercom,
    private subscriptionService: SubscriptionService,
    private notif: NotificationsService,
    private userService: CurrentUserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.subscribeToUserUpdates();
    this.subscribeToGetPlans();
  }

  subscribeToUserUpdates() {
    this.isUserInfoLoading = true;
    this.notif.showLoading();
    this.userService.data$.pipe(takeUntil(this.isDestroyed$)).subscribe((user) => {
      this.isUserInfoLoading = false;
      this.notif.close();
      if (!user) {
        this.notif.showError('An error occurred: could not retrieve user data.');
        return;
      }
      this.user = user;

      this.hasTeamSubscription = !!getUserSubscription(user)?.isTeamSubscription;

      if (!user.subscription) {
        return;
      }

      if (hasActiveOwnSubscription(user)) {
        this.router.navigate(['webapp']);
        return;
      }

      const trialEnd = moment(user.subscription.trial_ends_at);
      if (user.subscription.status === 'trialing' && trialEnd.isBefore(moment())) {
        this.hasEndedTrial = true;
      }

      if (dayjs(user.subscription.ends_at).isBefore(dayjs())) {
        this.subscriptionEnded = true;
      }
    });
  }

  subscribeToGetPlans() {
    forkJoin([
      this.subscriptionService.getAllPlans().pipe(catchError((err) => of(null))),
      this.subscriptionService.getAllSubscriptions().pipe(catchError((err) => of(null))),
    ]).subscribe({
      next: ([plans, subscriptions]) => {
        if (!plans) {
          this.notif.showError('An error occurred: could not retrieve data.');
          return;
        }
        if (!subscriptions) {
          this.notif.showError('An error occurred: could not retrieve data.');
        }

        this.plans = plans;
        this.basicPlan = plans.find((plan) => plan.name.toLowerCase().includes('basic'));
        this.premiumPlan = plans.find((plan) => plan.name.toLowerCase().includes('premium'));
        // user can trial if they have no cancelled/unpaid etc subscriptions
        this.canTrial = !subscriptions.some((sub) => !['active', 'trialing'].includes(sub.status));
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  openIntercom() {
    this.intercom.show();
  }

  subscribeToPlan(planName: 'basic' | 'premium') {
    this.triggerAnimation = !this.triggerAnimation;
    this.notif.showLoading();

    const plan = this.plans.find((plan) => plan.name.toLowerCase().includes(planName));
    if (!plan) {
      this.notif.showError('An error occurred: Plan not found');
      return;
    }

    this.subscriptionService
      .subscribeToPlan(plan.id, this.isYearly ? 'yearly' : 'monthly')
      .subscribe({
        next: (res) => {
          if (!res.url) {
            this.notif.showError('An error occurred: could not find the subscription URL.');
            return;
          }
          window.location.href = res.url;
        },
        error: (err) => {
          this.notif.showError(
            `An error occurred: ${err.error?.message}. Please try again later or contact support for help.`,
          );
        },
      });
  }

  goBack() {
    this.router.navigate(['webapp']);
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['auth', 'login']);
  }

  manageSubscription() {
    this.subscriptionService.openCustomerPortal();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
