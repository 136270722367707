<div class="sidebar-wrapper">
  <app-sidebar-header (backButtonClicked)="onBack()" [showBackButton]="false">
    <div smallTitle>{{ model.id ? 'Edit' : 'Add' }} Change Action</div>
    <div mainTitle>General Information</div>
  </app-sidebar-header>
  <div class="main-content m-0 flex flex-col">
    <ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
      <div class="flex my-2.5 mx-7 flex-col flex-1">
        <form class="flex flex-col h-full" #generalForm="ngForm" role="form">
          <app-dropdown
            required
            [optionLabel]="null"
            [optionValue]="null"
            [disabled]="!!model.id"
            placeholder="Change Action Type"
            label="Change Action Type"
            name="Change Action Type"
            [(model)]="model.type"
            [options]="PCOTypes"
            (modelChange)="actionTypeChanged()"
            class="w-full"
          >
            <ng-template #selectedContent #optionContent let-option>
              <div class="capitalize text-color_primary">{{ option }}</div>
            </ng-template>
          </app-dropdown>

          @if (model.type === 'Potential Change Order') {
            <app-dropdown
              required
              placeholder="Scope Change"
              label="Scope Change"
              name="scope"
              [disabled]="!!model.id"
              [(model)]="model.scope"
              (modelChange)="onScopeChange()"
              [options]="scopeOptions"
              class="w-full"
            ></app-dropdown>
          }

          <app-floating-input
            required
            [(model)]="model.title"
            name="title"
            label="Title"
            placeholder="Title"
          ></app-floating-input>

          <div class="flex gap-4">
            <app-input-calendar
              required
              [(date)]="model.date"
              class="w-full"
              name="date"
              placeholder="Date"
            ></app-input-calendar>

            @if (model.type === 'Potential Change Order') {
              <app-dropdown
                required
                [optionLabel]="null"
                [optionValue]="null"
                placeholder="Duration Extension"
                label="Duration Extension"
                name="duration"
                [(model)]="model.duration_extension"
                [options]="possibleDurationExtensions"
                class="w-full"
              >
                <ng-template #selectedContent #optionContent let-option>
                  <div class="capitalize text-color_primary">{{ option }} Days</div>
                </ng-template>
              </app-dropdown>
            }
          </div>

          <div class="flex gap-4">
            <app-floating-input
              [(model)]="model.number"
              (modelChange)="NumberChanged($event)"
              label="Number"
              placeholder="Number"
              name="number"
              required
              [minlength]="1"
              [maxlength]="10"
              [disabled]="customIdDisabled"
              patternErrorMessage="Already taken"
              [iconClass]="{
                position: 'p-input-icon-right',
                icon: customIdDisabled ? 'icon-text-edit' : 'icon-text-revert'
              }"
              (iconClick)="onCustomIdIconClick()"
              class="w-full"
            ></app-floating-input>

            <app-dropdown
              required
              [optionLabel]="null"
              [optionValue]="null"
              [required]="true"
              placeholder="Status"
              label="Status"
              name="status"
              [(model)]="model.status"
              [options]="statuses"
              [disabled]="!model.type"
              [pTooltip]="!model.type ? 'Change Action Type must be selected first.' : ''"
              tooltipPosition="bottom"
              class="w-full"
            >
              <ng-template #selectedContent #optionContent let-option>
                <div class="capitalize text-color_primary">{{ option }}</div>
              </ng-template>
            </app-dropdown>
          </div>

          <label class="flex gap-1 text-shade_2 text-xs mt-2 ml-1 mb-0.5">
            <span class="font-semibold"> Description</span>
            <span class="font-normal">(optional)</span>
          </label>
          <div (click)="textAreaElement.focus()" class="textarea-wrapper cursor-text">
            <textarea
              class="flex"
              #textAreaElement
              cdkTextareaAutosize
              name="note"
              [(ngModel)]="model.description"
            ></textarea>
          </div>
        </form>
      </div>
    </ng-scrollbar>
    <div class="my-6 mx-7 flex justify-end gap-4">
      @if (model.id && model.type === 'Potential Change Order') {
        <app-simple-button (click)="updateModel()" buttonStyle="color_accent_filled"> Update </app-simple-button>
      } @else {
        <app-simple-button (click)="onNext()">Next</app-simple-button>
      }
    </div>
  </div>
</div>
