<div class="left">
  <div class="form column titles-column box-border flex items-center justify-start pl-4 ml-px">
    <div class="pr-[15px] w-full box-border flex items-center justify-start gap-1">
      <div
        cdkDragHandle
        [ngClass]="{
          'text-shade_5 cursor-no-drop': committedItem.type === 'forecast_modification'
        }"
        class="icon-draggable flex items-center justify-center text-color_secondary cursor-grab"
      ></div>
      <div
        class="flex justify-center flex-col flex-1 bg-color_accent_shade_4 rounded px-2 py-0.5 overflow-hidden border border-solid border-shade_5 min-h-8 cursor-default"
      >
        @if (committedItem.type === 'commitment') {
          <span class="font-semibold text-sm h-[18px] overflow-ellipsis whitespace-nowrap overflow-hidden">
            {{ committedItem.commitment_name }}
          </span>
          <span class="font-normal text-xs overflow-ellipsis whitespace-nowrap overflow-hidden">{{
            committedItem.commitment_title
          }}</span>
        } @else {
          <span class="text-color_primary text-sm font-semibold cursor-default"> Anticipated Cost </span>
          <span class="font-normal text-xs overflow-ellipsis whitespace-nowrap overflow-hidden">{{
            lineItem.name
          }}</span>
        }
      </div>
    </div>
  </div>
  <div class="form column budget-area">
    <div class="input total-year">
      <app-spend-custom-input
        class="app-custom-input commitment-input"
        [disabled]="true"
        [allowNegatives]="true"
        [value]="committedItem.project_total"
        (valueChange)="setItemTotal($event, committedItem); calcDistribution(committedItem)"
        [ngClass]="{
          'totals-match-error':
            committedItem.project_total !== committedItem.original_budget_total &&
            committedItem.distribution === DISTRIBUTION_TYPES.MANUAL
        }"
        [pTooltip]="
          committedItem.project_total !== committedItem.original_budget_total &&
          committedItem.distribution === DISTRIBUTION_TYPES.MANUAL
            ? 'For manual distribution with contract, the total must be equal to the initial total which is ' +
              (committedItem.original_budget_total >= 0
                ? '$' + committedItem.original_budget_total
                : '($' + committedItem.original_budget_total * -1 + ')')
            : ''
        "
      >
      </app-spend-custom-input>
    </div>
  </div>
</div>
<div class="right">
  <div class="distribution-type-area">
    <div class="input column">
      <app-input-calendar
        [minStartDate]="minStartDate"
        [date]="committedItem.start_date"
        (dateChanged)="setStartDate($event, committedItem); calcDistribution(committedItem)"
        [showLabel]="false"
        [name]="'start_date_' + committedItem.id"
        topPosition="top-2"
        class="prime-calendar-spend commitment-input"
        [isDisabled]="true"
        readonly
      ></app-input-calendar>
    </div>

    <div class="input select-with-border">
      <app-dropdown
        (modelChange)="setItemDuration($event, committedItem); calcDistribution(committedItem)"
        [disabled]="true"
        [model]="committedItem.duration"
        [name]="'duration' + committedItem.id"
        [ngModelOptions]="{ standalone: true }"
        [options]="durationMonths"
        [showLabel]="false"
        [showError]="false"
        appendTo="body"
        class="spend-app-dropdown commitment-input"
      ></app-dropdown>
    </div>
    <div class="input select-with-border">
      <app-dropdown
        optionValue="key"
        optionLabel="name"
        (modelChange)="setItemDistribution($event, committedItem); calcDistribution(committedItem)"
        [model]="committedItem.distribution"
        [name]="'distribution_type_' + committedItem.id"
        [ngModelOptions]="{ standalone: true }"
        [options]="distributionTypes"
        [showLabel]="false"
        [showError]="false"
        appendTo="body"
        class="spend-app-dropdown"
        [disabled]="
          committedItem.project_total !== committedItem.original_budget_total &&
          committedItem.distribution === DISTRIBUTION_TYPES.MANUAL
        "
        [pTooltip]="
          committedItem.project_total !== committedItem.original_budget_total &&
          committedItem.distribution === DISTRIBUTION_TYPES.MANUAL
            ? 'Please fix the total value mismatch first.'
            : null
        "
      ></app-dropdown>
    </div>
  </div>

  <div class="months">
    <div class="input-wrapper month-input column">
      <div class="input">
        <app-spend-custom-input
          [disabled]="true"
          [allowNegatives]="true"
          class="app-custom-input commitment-input"
          [value]="committedItem.year_total"
        >
        </app-spend-custom-input>
      </div>
    </div>
    <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
      <div class="input">
        <app-spend-custom-input
          [allowNegatives]="true"
          (valueChange)="registerChange($event, committedItem, month)"
          class="app-custom-input commitment-input"
          [ngClass]="{
            'project-activity':
              !committedItem.monthly_disable[month] && committedItem.distribution !== DISTRIBUTION_TYPES.MANUAL
          }"
          [disabled]="committedItem.monthly_disable[month] || committedItem.distribution !== DISTRIBUTION_TYPES.MANUAL"
          [value]="committedItem.monthly_data[month]"
          [pTooltip]="
            (!committedItem.monthly_disable[month] && committedItem.distribution !== DISTRIBUTION_TYPES.MANUAL) ||
            committedItem.monthly_disable[month]
              ? tooltipContent
              : ''
          "
          tooltipPosition="bottom"
        >
        </app-spend-custom-input>
        <ng-template #tooltipContent>
          @if (!committedItem.monthly_disable[month] && committedItem.distribution !== DISTRIBUTION_TYPES.MANUAL) {
            Input field cannot be edited, as it is being distributed based on project activity budget.
          } @else if (committedItem.monthly_disable[month]) {
            Input field cannot be edited outside project activity.
          }
        </ng-template>
      </div>
    </div>
  </div>
</div>
