<div class="contents">
  <div class="index-box">{{ templateItem.order }}.{{ templateSubitem.order }}</div>
  <span
    cdkDragHandle
    [ngClass]="{ 'disabled text-shade_4': !template?.permissions?.can_edit || templateItem.subitems.length < 2 }"
    class="icon-draggable text-color_secondary text-[20px] cursor-pointer"
  ></span>
  <app-floating-input
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'division' + templateSubitem.id"
    [maxlength]="10"
    (modelChange)="fieldChanged('division', $event)"
    [model]="templateSubitem.division"
    [verticalSpacing]="false"
    data-cy="division-input-child"
  ></app-floating-input>
  <app-floating-input
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'cost_type' + templateSubitem.id"
    [maxlength]="20"
    (modelChange)="fieldChanged('cost_type', $event)"
    [model]="templateSubitem.cost_type"
    [verticalSpacing]="false"
    data-cy="cost-type-input-child"
  ></app-floating-input>
  <app-floating-input
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'description' + templateSubitem.id"
    [maxlength]="40"
    (modelChange)="fieldChanged('description', $event)"
    [model]="templateSubitem.description"
    [verticalSpacing]="false"
    data-cy="description-input-child"
  ></app-floating-input>
  <span
    [class.disabled]="templateItem?.subitems?.length < 2"
    (click)="minusClicked()"
    class="icon-minus-box add-remove-btn"
  ></span>
  <!--  <span (click)="plusClicked()" class="icon-plus-box text-color_secondary add-remove-btn"></span>-->
</div>
