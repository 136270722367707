@if ((lineItemOptions$ | async).length === 0) {
  <div class="flex flex-col items-center justify-center h-20">
    <span class="font-bold">No available Line Items.</span>
    <span class="font-bold">Make sure line items are present in the prime contract. (As non markup line item)</span>
  </div>
}

<app-dropdown
  required
  [optionLabel]="null"
  [optionValue]="'id'"
  [model]="lineItem.id"
  (modelChange)="lineItemChange($event)"
  [placeholder]="'Line Item' + index"
  [label]="'Line Item ' + index"
  [name]="'line_item_' + tempUUID"
  class="w-full"
  [options]="lineItemOptions$ | async"
>
  <ng-template #selectedContent #optionContent let-option>
    <div class="flex flex-col">
      <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
      <div class="capitalize text-color_primary font-normal">{{ option?.division }} {{ option?.cost_type }}</div>
    </div>
  </ng-template>
</app-dropdown>

@for (description of lineItem.descriptions; track descriptionIndex; let descriptionIndex = $index) {
  <div class="flex gap-2 w-full items-center">
    <app-floating-input
      required
      class="w-full"
      [model]="description"
      (modelChange)="descriptionChange($event, descriptionIndex)"
      [name]="'description_' + index + '_' + descriptionIndex"
      [label]="'Spend Description ' + descriptionIndex"
      [placeholder]="'Spend Description ' + descriptionIndex"
    ></app-floating-input>

    <app-add-remove-buttons
      class="mt-3.5"
      (removeItem)="removeDescription(descriptionIndex)"
      (addItem)="addDescription(descriptionIndex)"
    >
    </app-add-remove-buttons>
  </div>
}
