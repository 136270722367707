import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { TooltipModule } from 'primeng/tooltip';
import { AsyncPipe } from '@angular/common';
import { Subject, take } from 'rxjs';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import { PrimeContractGeneralPageComponent } from './prime-contract-general-page/prime-contract-general-page.component';
import { PrimeContractValuesComponent } from './prime-contract-values/prime-contract-values.component';
import { PrimeChangeOrderMarkupsComponent } from './prime-change-order-markups/prime-change-order-markups.component';
import { PrimeInvoiceRetainageComponent } from './prime-invoice-retainage/prime-invoice-retainage.component';
import { PrimeContractUploadTemplateComponent } from './prime-contract-upload-template/prime-contract-upload-template.component';
import { primeSidebarActions } from '@app/store/prime-commitments/prime-commitments.actions';
import {
  IPrimeContractModel,
  IPrimeLineItem,
} from '@app/store/prime-commitments/prime-commitments.types';
import { DeepCopyService } from '@app/services/deep-copy.service';
import { primeCommitmentsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { NotificationsService } from '@app/services/notifications.service';
import { primeContractStatuses } from '@app/store/prime-commitments/prime-commitments.constants';

@Component({
  selector: 'app-prime-contract-sidebar',
  standalone: true,
  imports: [
    SidebarHeaderComponent,
    FormsModule,
    PrimeContractGeneralPageComponent,
    PrimeContractValuesComponent,
    PrimeChangeOrderMarkupsComponent,
    PrimeInvoiceRetainageComponent,
    TooltipModule,
    PrimeContractUploadTemplateComponent,
    AsyncPipe,
  ],
  templateUrl: './prime-contract-sidebar.component.html',
})
export class PrimeContractSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly notif = inject(NotificationsService);
  private readonly isDestroyed$ = new Subject<void>();

  @Input() projectId: number;

  statuses = primeContractStatuses;
  model: IPrimeContractModel = {
    files: [],
    title: '',
    owner_name: '',
    date: '',
    status: '',
    description: '',
    defaultInvoiceRetainageRate: '0',
    spendEntries: [],
    markupGroups: [],
    exceptions: [],
  };

  primeContractModel$ = this.store.select(primeCommitmentsSelectors.selectPrimeContractModel);
  currentPage$ = this.store.select(primeCommitmentsSelectors.selectPrimeContractSidebarPage);

  showPrimeTemplateUploadPage = false;

  ngOnInit() {
    this.store.dispatch(primeSidebarActions.onPrimeContractSidebarOpen());
  }

  ngAfterViewInit() {
    this.primeContractModel$
      .pipe(
        filter((data) => !!data),
        take(1),
      )
      .subscribe({
        next: (data) => {
          this.model = DeepCopyService.deepCopy(data);
        },
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  saveContract() {
    if (this.model.id) {
      this.store.dispatch(
        primeSidebarActions.onPrimeContractUpdate({
          primeContract: DeepCopyService.deepCopy(this.model),
        }),
      );
      return;
    }

    this.store.dispatch(
      primeSidebarActions.onPrimeContractSubmit({
        primeContract: DeepCopyService.deepCopy(this.model),
      }),
    );
  }

  incrementPage() {
    this.store.dispatch(primeSidebarActions.onClickNext());
  }

  goToPreviousPage() {
    if (this.showPrimeTemplateUploadPage) {
      this.showPrimeTemplateUploadPage = false;
      return;
    }

    this.store.dispatch(primeSidebarActions.onPrimeContactViewPreviousPage());
  }

  /**
   * When Nic will want the upload page uncomment this and adapt to new requirements in replaceDataWithTemplate
   */
  changeViewToUploadPage() {
    this.showPrimeTemplateUploadPage = true;
  }

  // this was removed in the latest design update along with the template upload page
  replaceDataWithTemplate(templateValues: IPrimeLineItem[]) {
    if (!templateValues.length) {
      this.notif.showError('Could not identify any template.');
      return;
    }
    // this.model.lineItems = templateValues; // todo, see how this is supposed to work
    this.showPrimeTemplateUploadPage = false;
  }
}
