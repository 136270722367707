import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AsyncPipe, NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { read, utils } from 'xlsx';
import { SpendDistributionService } from '../../../../../services/spend-distribution.service';
import { viewProjectSelectors } from '../../../../../store/view-project/view-project.selectors';
import { exportOneTemplate } from '../../../settings/templates/templates-export-import-utilities';
import { Project } from '../../projects.interface';
import { getSpreadSheetData } from '../spend/spend.converter';
import {
  DISTRIBUTION_TYPES,
  SPEND_ACTIONS,
  SPEND_DISTRIBUTION_STATE,
  SPEND_TYPES,
} from '../../../../../framework/constants/spend.constants';
import {
  ICommittedItemExtended,
  ILineItemExtended,
  ISubitem,
  SpendExportRow,
  SpreadSheetInputData,
} from '../../../../../store/spend/spend.interfaces';
import {
  getIsLoading,
  getSelectableYears,
  getSelectedSpendType,
  getSelectedYear,
  selectLineItemsExtended,
} from '../../../../../store/spend/spend.selectors';
import { AppState } from '../../../../../store/app-state';
import { NotificationsService } from '../../../../../services/notifications.service';
import { SpendViewProjectStateService } from '../../../../../services/spend-view-project-state.service';
import { saveToBackend, spendActionTypes } from '../../../../../store/spend/spend.actions';
import { SimpleButtonComponent } from '../../../../../framework/buttons/simple-medium-button/simple-button.component';
import { viewProjectActions } from '../../../../../store/view-project/view-project.actions';
import { PROJECT_VIEWS } from '../../../../../framework/constants/view-project.constants';
import { BudgetLineItemTableComponent } from './budget-line-item-table/budget-line-item-table.component';
import { SpendDistributionHeaderComponent } from './spend-distribution-header/spend-distribution-header.component';
import { SplashScreenComponent } from 'src/app/framework/splash-screen/splash-screen.component';

@Component({
  selector: 'app-spend-distribution',
  templateUrl: './spend-distribution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./spend-distribution.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    TooltipModule,
    SpendDistributionHeaderComponent,
    BudgetLineItemTableComponent,
    SplashScreenComponent,
    SimpleButtonComponent,
  ],
})
export class SpendDistributionComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly notif = inject(NotificationsService);
  private readonly state = inject(SpendViewProjectStateService);
  private readonly store = inject(Store<AppState>);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly spendDistributionService = inject(SpendDistributionService);

  syncedLineItems: ILineItemExtended[] = [];
  spreadsheetData: SpreadSheetInputData;
  SPEND_TYPES = SPEND_TYPES;
  SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE;
  selectedSpendType: SPEND_TYPES = SPEND_TYPES.BUDGET;
  selectedYear = 0;
  project: Project;

  selectedBudgetType$ = this.store.select(getSelectedSpendType);
  possibleYears$ = this.store.select(getSelectableYears);
  isLoading$ = this.store.select(getIsLoading);
  selectedYear$ = this.store.select(getSelectedYear);
  spendState$ = this.state.currentState;
  currentProject$ = this.store.select(viewProjectSelectors.getSelectedProject);
  isBudgetMissing$ = this.store.select(viewProjectSelectors.spendAllocationBudgetMissing);

  loadingHash: undefined | string;

  protected readonly Number = Number;
  isDestroyed$ = new Subject<void>();

  ngOnInit() {
    this.store
      .select(selectLineItemsExtended)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => !!value),
      )
      .subscribe((lineItems) => {
        this.syncedLineItems = cloneDeep(lineItems);
      });

    this.isLoading$.pipe(takeUntil(this.isDestroyed$)).subscribe((isLoading) => {
      if (isLoading) {
        this.loadingHash = this.notif.showLoading();
      } else {
        this.notif.close(this.loadingHash);
      }
    });

    this.selectedBudgetType$.pipe(takeUntil(this.isDestroyed$)).subscribe((type) => {
      this.selectedSpendType = type;
    });

    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
    });

    this.currentProject$.pipe(takeUntil(this.isDestroyed$)).subscribe((project) => {
      this.project = project;
    });
  }

  ngAfterViewInit() {
    this.state.currentState.next(SPEND_DISTRIBUTION_STATE.DEFAULT);
  }

  /**
   * @deprecated, kept if needed in the future.
   * @param _
   */
  onOpenSpreadSheet = (_) => {
    this.possibleYears$.pipe(take(1)).subscribe((years) => {
      const isEditable = [];
      this.syncedLineItems.forEach((item) => {
        const isEditableItem = {};
        item.budget.forEach((budget) => {
          isEditableItem[budget.year] = this.spendDistributionService.getDisabledMonths(
            budget.year,
            item,
            this.selectedYear,
          );
        });
        isEditable.push(isEditableItem);
      });

      const items = [...getSpreadSheetData(this.syncedLineItems, years, this.selectedSpendType)];
      this.spreadsheetData = {
        items,
        isEditable,
        selectedYear: this.selectedYear,
      };
      console.log(
        'spreadsheet data',
        this.spreadsheetData,
        'selectedBudgetType',
        this.selectedSpendType,
      );
      this.changeDetectorRef.detectChanges(); // otherwise spreadsheet won't open
    });
  };

  exportBudget() {
    const sheetData: SpendExportRow[] = [];
    for (const parentItem of this.syncedLineItems) {
      sheetData.push({
        id: parentItem.id,
        parent_id: null,
        division: parentItem.division,
        cost_type: parentItem.cost_type,
        description: parentItem.name,
        dollar_amount: parentItem.project_total,
      });
      for (const subitem of parentItem.subitems) {
        sheetData.push({
          id: subitem.id,
          parent_id: parentItem.id,
          description: subitem.name,
          division: subitem.division,
          cost_type: subitem.cost_type,
          dollar_amount: subitem.project_total,
        });
      }
    }

    exportOneTemplate(sheetData, 'budget', `${this.project?.title ?? 'Project'}_budget.xlsx`);
  }

  async importBudget(file: File) {
    if (!file) {
      this.notif.showError("Error: couldn't read the file.");
    }

    const buffer = await file.arrayBuffer();
    const workbook = read(buffer);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const parsedData = utils.sheet_to_json<SpendExportRow>(sheet);

    let parentOrder = 0;
    let subitemOrder = 0;
    // transform parsed data to line items
    let lineItems: Partial<ISubitem>[] = parsedData.map((item) => {
      const isParent = !item.parent_id;
      if (isParent) {
        parentOrder++;
        subitemOrder = 1;
      } else {
        subitemOrder++;
      }

      return {
        id: item.id,
        name: item.description,
        project_id: this.project.id,
        // parent items have manual distribution, subitems have bell curve
        distribution: item.parent_id ? DISTRIBUTION_TYPES.BELL_CURVE : DISTRIBUTION_TYPES.MANUAL,
        duration: 1,
        start_date: this.project.start_date,
        budget: [],
        original_budget_total: item.dollar_amount,
        row_number: isParent ? parentOrder : subitemOrder,
        subitems: [],
        parent_id: item.parent_id,
      } as Partial<ISubitem>;
    });

    // update parent items with total of subitems
    lineItems = lineItems.map((item) => {
      if ((item as ISubitem).parent_id) {
        return item;
      }
      return {
        ...item,
        original_budget_total: lineItems
          .filter((subItem: ISubitem) => subItem.parent_id === item.id)
          .reduce((acc, subitem) => acc + subitem.original_budget_total, 0),
      };
    });

    this.store.dispatch(spendActionTypes.budgetImported({ lineItems, project: this.project }));
  }

  trackByIndex = (index: number) => index;

  /**
   * Check if the project total is equal to the initial line item total for manual distributions
   * only if the commitment start date is set
   */
  hasManualDistributionTotalMismatch() {
    const manualBudgetItems = this.syncedLineItems.filter(
      (item) =>
        item.distribution === DISTRIBUTION_TYPES.MANUAL &&
        !!item.commitment_start_date &&
        item.project_total !== item.original_budget_total,
    );

    const manualCommittedItems = this.syncedLineItems
      .flatMap((item) => item.committed_items)
      .filter((item) => item.distribution === DISTRIBUTION_TYPES.MANUAL);

    return (
      manualBudgetItems.some((item) => item.project_total !== item.original_budget_total) ||
      manualCommittedItems.some(
        (item: ICommittedItemExtended) => item.project_total !== item.original_budget_total,
      )
    );
  }

  closeClicked() {
    // SHOW_SPREADSHEET currently not used
    // if (this.state.currentState.value !== SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
    this.store.dispatch(viewProjectActions.viewChanged({ view: PROJECT_VIEWS.DEFAULT }));
    // }
    this.state.close(); // maybe the state isn't needed
  }

  saveClicked() {
    if (this.hasManualDistributionTotalMismatch()) {
      this.notif.showError(
        'For manual distributions, the project total must match the initial line item total.\n Hover on project total for more information.',
      );
      return;
    }
    this.state.stateEvents.next(SPEND_ACTIONS.REFRESH);
    this.store.dispatch(saveToBackend());
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
