import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import { AsyncPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DockModule } from 'primeng/dock';
import { UnStyledOptionsListComponent } from '../../../../overlays/un-styled-options-list/un-styled-options-list.component';
import { OPTIONS } from '../../../../constants/options-list.constants';
import { DynamicProjectedTableComponent } from '../../../../dynamic-projected-table/dynamic-projected-table.component';
import { ExpansionPanelComponent } from '../../../../expansion-panel/expansion-panel.component';
import { PageLoadingComponent } from '../../../../page-loading/page-loading.component';
import { INTERACTION_BAR_STATES } from '../../../../constants/interaction-bar.constants';
import { PrimePcoDetailsHeaderComponent } from './prime-pco-details-expansion-header/prime-pco-details-header.component';
import { PrimePcoDetailsExpansionContentComponent } from './prime-pco-details-expansion-content/prime-pco-details-expansion-content.component';
import { PcoDetailMarkupLineItemComponent } from './pco-detail-markup-line-item/pco-detail-markup-line-item.component';
import { MoneyPipe } from '@app/pipes/framework/money-short.pipe';
import {
  primeChangesActions,
  primePCODetailsSidebarActions,
} from '@app/store/prime-commitments/prime-commitments.actions';
import { primeCommitmentsDetailsSelectors } from '@app/store/prime-commitments/prime-commtiments.selectors';
import { DeepCopyService } from '@app/services/deep-copy.service';
import { InteractionBarStateService } from '@app/services/interaction-bar-state.service';
import { StatusBlockComponent } from '@app/pages/webapp/projects/view-project/rfi-listing/status-block/status-block.component';
import {
  PCODetail,
  PCODetailItem,
  PCODetailItemCost,
  PCOLogItem,
} from '@app/store/prime-commitments/prime-commitments.types';

@Component({
  selector: 'app-prime-pco-details',
  standalone: true,
  imports: [
    UnStyledOptionsListComponent,
    StatusBlockComponent,
    DynamicProjectedTableComponent,
    ExpansionPanelComponent,
    PrimePcoDetailsExpansionContentComponent,
    MoneyPipe,
    PageLoadingComponent,
    PcoDetailMarkupLineItemComponent,
    PrimePcoDetailsHeaderComponent,
    AsyncPipe,
    DockModule,
  ],
  templateUrl: './prime-pco-details.component.html',
  styleUrl: './prime-pco-details.common.scss',
})
export class PrimePcoDetailsComponent implements OnInit, OnDestroy {
  @Input() PCOLog: PCOLogItem;

  @ViewChildren('pcoDetailHeader') pcoDetailHeader: QueryList<PrimePcoDetailsHeaderComponent>;

  private readonly store = inject(Store);
  private readonly interactionBar = inject(InteractionBarStateService);

  protected isLoading$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectIsPCODetailLoading,
  );
  protected wasDataLoadedOnce$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectWasPCODataLoadedOnce,
  );
  protected readonly OPTIONS = OPTIONS;

  editOptions: OPTIONS[] = [OPTIONS.EDIT, OPTIONS.DELETE];
  PCO: PCODetail = null;
  expandedItems = new Map<number, boolean>();
  editChangeActionTriggered = false;

  PCO$ = this.store.select(primeCommitmentsDetailsSelectors.selectPCODetailsSidebar);
  combinedBudgetTotals$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectCombinedBudgetTotals,
  );
  combinedCommitmentTotals$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectCombinedCommitmentTotals,
  );
  isDestroyed$ = new Subject<void>();
  autoSave$ = this.store.select(primeCommitmentsDetailsSelectors.selectPCOSidebarAutoSave);

  ngOnInit() {
    this.store.dispatch(primePCODetailsSidebarActions.onPCODetailsAccessed({ id: this.PCOLog.id }));
    this.PCO$.pipe(
      takeUntil(this.isDestroyed$),
      filter((PCO) => !!PCO),
    ).subscribe((data) => {
      this.PCO = DeepCopyService.deepCopy(data);
      //todo: create selector
      if (this.PCO.status !== 'closed') {
        this.editOptions = [OPTIONS.EDIT, OPTIONS.MARK_CLOSE, OPTIONS.DELETE];
      } else {
        this.editOptions = [OPTIONS.EDIT, OPTIONS.DELETE];
      }
      this.PCO.pco_items.forEach((item, index) => {
        this.expandedItems.set(index, this.expandedItems.get(index) ?? false);
      });
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
    this.store.dispatch(
      primePCODetailsSidebarActions.onPCODetailsSidebarClosed({
        editChangeActionTriggered: this.editChangeActionTriggered,
      }),
    );
  }

  registerOption(option: OPTIONS) {
    switch (option) {
      case OPTIONS.EDIT:
        this.editChangeActionTriggered = true;
        this.interactionBar.openInteractionBar(INTERACTION_BAR_STATES.CHANGE_ACTION, {
          isEdit: true,
          changeActionType: 'Potential Change Order',
        });
        break;
      case OPTIONS.MARK_CLOSE:
        this.PCO.status = 'closed';
        this.savePCO();
        break;
      case OPTIONS.DELETE:
        this.store.dispatch(primeChangesActions.onDeletePCO({ id: this.PCO.id }));
    }
  }

  deleteUnmarkedLineItem(id: string) {
    const index = this.PCO.pco_items.findIndex((item) => item.id === id);
    if (index === -1) {
      return;
    }
    this.PCO.pco_items.splice(index, 1);
    this.savePCO();
  }

  deleteCost(item: PCODetailItem, costIndex: number) {
    item.pco_item_costs.splice(costIndex, 1);
    this.savePCO();
  }

  addItem(option: OPTIONS.ADD_BUDGET_LINE | OPTIONS.ADD_SPEND_DESCRIPTION, item: PCODetailItem) {
    switch (option) {
      case OPTIONS.ADD_SPEND_DESCRIPTION:
        item.pco_item_costs.push({
          id: uuid.v4(),
          pco_item_id: item.id,
          spend_description: 'Spend description cost ' + item.pco_item_costs.length + 1,
          budget_dollars: 0,
          commitment_dollars: 0,
          cco_id: null,
          created_at: '',
          updated_at: '',
          company_name: '',
          contract_title: '',
          pc_spend_description_id: null,
        });
        this.savePCO();
        break;
      case OPTIONS.ADD_BUDGET_LINE:
        this.addBudgetLine();
        break;
    }
  }

  addBudgetLine() {
    const id = uuid.v4();
    this.PCO.pco_items.push({
      id: id,
      pco_id: null,
      item_id: null,
      budget_dollars: 0,
      commitment_dollars: 0,
      created_at: '',
      updated_at: '',
      item_name: '',
      change_order_markup_rate: null,
      pco_item_costs: [],
      is_commitment_locked: false,
      is_budget_locked: false,
      division: '',
      cost_type: '',
    });

    this.expandedItems.set(this.PCO.pco_items.length - 1, true);

    this.store.dispatch(
      primePCODetailsSidebarActions.onPCOLineItemAdded({
        PCO: DeepCopyService.deepCopy(this.PCO),
      }),
    );
  }

  updatePCOCost(pcoItemCost: PCODetailItemCost, pcoItem: PCODetailItem, costIndex: number) {
    const index = this.PCO.pco_items.findIndex((item) => item.id === pcoItem.id);
    this.PCO.pco_items[index].pco_item_costs[costIndex] = pcoItemCost;
    this.PCO.pco_items[index].budget_dollars = this.PCO.pco_items[index].pco_item_costs.reduce(
      (acc, cost) => acc + cost.budget_dollars,
      0,
    );
    this.PCO.pco_items[index].commitment_dollars = this.PCO.pco_items[index].pco_item_costs.reduce(
      (acc, cost) => acc + cost.commitment_dollars,
      0,
    );

    this.savePCO();
  }

  isValid() {
    let allValid = true;
    Array.from(this.pcoDetailHeader).map((header) => {
      allValid = allValid && header.isValid();
    });

    if (this.PCO.scope === 'existing') {
      this.PCO.pco_items.forEach((item) => {
        item.pco_item_costs.forEach((cost) => {
          allValid = allValid && cost.pc_spend_description_id !== null;
        });
      });
    }

    return allValid;
  }

  savePCO() {
    this.updatePCOLocalState();

    setTimeout(() => {
      if (!this.isValid()) {
        this.store.dispatch(primePCODetailsSidebarActions.onPCOInvalid());
        return;
      }

      this.store.dispatch(
        primePCODetailsSidebarActions.onSyncPCODetailsTriggered({
          PCO: DeepCopyService.deepCopy(this.PCO),
        }),
      );
    }, 100);
  }

  updatePCOLocalState() {
    this.store.dispatch(
      primePCODetailsSidebarActions.onPCODetailsLocalStateUpdate({
        PCO: DeepCopyService.deepCopy(this.PCO),
      }),
    );
  }

  updateUnmarkedLineItem(pcoItem: PCODetailItem, index: number) {
    this.PCO.pco_items[index] = {
      ...pcoItem,
      pco_item_costs: this.PCO.pco_items[index].pco_item_costs,
    };
    this.savePCO();
  }

  updateMarkupLineItem(pcoItem: PCODetailItem, index: number) {
    this.PCO.pco_markup_items[index] = pcoItem;
    this.savePCO();
  }
}
