<div class="contents">
  <div class="index-box">{{ templateItem.order }}</div>
  <span
    [ngClass]="{ 'disabled text-shade_4': !template?.permissions?.can_edit || template.template_items.length < 2 }"
    cdkDragHandle
    class="icon-draggable text-color_secondary text-[20px] cursor-pointer"
  ></span>
  @if (isManagerTemplate(templateItem)) {
    <app-floating-input
      [name]="'account_code' + templateItem.id"
      [maxlength]="20"
      (modelChange)="fieldChanged('account_code', $event)"
      [model]="templateItem.account_code"
      [verticalSpacing]="false"
      [disabled]="!template?.permissions?.can_edit"
    ></app-floating-input>
  } @else {
    <app-floating-input
      [name]="'division' + templateItem.id"
      [maxlength]="10"
      (modelChange)="fieldChanged('division', $event)"
      [model]="templateItem.division"
      [verticalSpacing]="false"
      [disabled]="!template?.permissions?.can_edit"
      [tooltipText]="divisionTooltipText"
      data-cy="division-input-parent"
    ></app-floating-input>
    <app-floating-input
      [classes]="{
        input: 'bg-shade_7 opacity-100',
        wrapper: ''
      }"
      [disabled]="true"
      [name]="'cost_type' + templateItem.id"
      [maxlength]="20"
      (modelChange)="fieldChanged('cost_type', $event)"
      [model]="templateItem.cost_type"
      [verticalSpacing]="false"
      data-cy="cost-type-input-parent"
    ></app-floating-input>
  }
  <app-floating-input
    [name]="'description' + templateItem.id"
    [maxlength]="40"
    (modelChange)="fieldChanged('description', $event)"
    [model]="templateItem.description"
    [verticalSpacing]="false"
    [disabled]="!template?.permissions?.can_edit"
    data-cy="description-input-parent"
  ></app-floating-input>

  <span
    (click)="minusClicked()"
    [ngClass]="{
      'disabled hidden': !template.permissions.can_edit,
      disabled: template.template_items.length < 2
    }"
    class="icon-minus-box add-remove-btn"
  ></span>
  <span
    data-cy="add-subitem-button"
    (click)="plusClicked()"
    [ngClass]="{ 'disabled hidden': !template.permissions.can_edit }"
    class="icon-plus-box add-remove-btn"
  ></span>
</div>
