import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { Store } from '@ngrx/store';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import {
  IPrimeContractModel,
  PrimeDescriptionOption,
  primeRetainageRate,
} from 'src/app/store/prime-commitments/prime-commitments.types';
import { primeSidebarActions } from '@app/store/prime-commitments/prime-commitments.actions';
import { getDefaultMarkup } from '@app/store/prime-commitments/prime-commitments.constants';

@Component({
  selector: 'app-prime-invoice-retainage',
  standalone: true,
  imports: [
    DropdownComponent,
    FormsModule,
    FloatingInputComponent,
    SimpleButtonComponent,
    AddRemoveButtonsComponent,
    NgScrollbar,
  ],
  templateUrl: './prime-invoice-retainage.component.html',
  styleUrl: './prime-invoice-retainage.component.scss',
})
export class PrimeInvoiceRetainageComponent implements AfterViewInit {
  private readonly store = inject(Store);

  @ViewChild('retainageSettings') retainageSettingsForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();
  @Output() saveContract = new EventEmitter<void>();

  invoiceRetainageRates: primeRetainageRate[] = ['0', '5', '10', '15', '20'];
  allScheduleOfValues: PrimeDescriptionOption[] = [];

  ngAfterViewInit() {
    this.allScheduleOfValues = this.model.spendEntries.flatMap((entry) => {
      return entry.spend_descriptions.map((spendDescription): PrimeDescriptionOption => {
        return {
          description_id: spendDescription.id,
          name: spendDescription.description,
        };
      });
    });
  }
  removeItem(index: number) {
    this.model.exceptions.splice(index, 1);
    this.model.exceptions.map((exception, i) => {
      return {
        ...exception,
        index: i,
      };
    });
  }

  addItem(index: number) {
    this.model.exceptions.splice(index + 1, 0, getDefaultMarkup());
    this.model.exceptions.map((exception, i) => {
      return {
        ...exception,
        index: i,
      };
    });
  }

  isFormValid() {
    this.retainageSettingsForm.form.markAllAsTouched();
    return this.retainageSettingsForm.form.valid;
  }

  submitForm() {
    if (!this.isFormValid()) {
      return;
    }

    this.saveContract.emit();
  }

  goBack() {
    this.store.dispatch(primeSidebarActions.onPrimeContactViewPreviousPage());
  }
}
