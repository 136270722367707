<form #costDescriptionForm="ngForm" role="form" class="cost-description-container">
  @if (!model.is_dropdown) {
    <app-floating-input
      [required]="true"
      name="cost_description_{{ costIndex }}"
      [(model)]="model.name"
      (modelChange)="updateModel()"
      placeholder="Description {{ costIndex + 1 }}"
      label="Description {{ costIndex + 1 }}"
    ></app-floating-input>
  } @else {
    <div
      (click)="isDropdownShown.name[costIndex] = true"
      [ngClass]="{ 'p-disabled': model?.has_commitments }"
      class="p-float-label p-input-icon-right cursor-pointer mb-2 mt-6"
    >
      <input
        [required]="true"
        #costViewTrigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        readonly
        class="w-full cursor-pointer pr-8 overflow-hidden text-nowrap text-ellipsis"
        type="text"
        pInputText
        name="dropdownInput_{{ costIndex }}"
        #name="ngModel"
        [ngModel]="model.name"
        placeholder="Description {{ costIndex + 1 }}"
        [ngClass]="{
          'ng-invalid ng-dirty': name.invalid && name.touched
        }"
      />
      <label>Description {{ costIndex + 1 }}</label>
      <small
        [ngClass]="{
          hidden: name.valid || name.untouched
        }"
        class="p-error text-uppercase"
        >REQUIRED</small
      >

      <i
        class="-mt-[4px]"
        [ngClass]="isDropdownShown.name[costIndex] ? 'dropdown-icon opened' : 'dropdown-icon closed'"
      ></i>
    </div>

    <app-overlay-general
      [(show)]="isDropdownShown.name[costIndex]"
      [trigger]="costViewTrigger"
      [overlayPositions]="dropdownAlignRight"
      [ngClass]="{ 'p-disabled': model?.has_commitments }"
    >
      <app-cost-view-table-dropdown
        [costIndex]="costIndex"
        [spendEntryIndex]="spendEntryIndex"
        [commitmentType]="commitmentType"
        (closeMe)="isDropdownShown.name[costIndex] = false"
        [disableAdd]="model?.is_disabled && commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER"
        [disableDelete]="model.is_disabled && commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER"
      >
      </app-cost-view-table-dropdown>
    </app-overlay-general>
  }

  <app-floating-input
    [required]="true"
    name="value_{{ costIndex }}"
    [(model)]="model.value"
    (change)="updateModel()"
    [autoComplete]="false"
    placeholder="Value ($)"
    label="Value ($)"
    [formatMoney]="true"
  >
  </app-floating-input>

  <app-add-remove-buttons
    class="mt-3.5"
    [disabledRemove]="model?.has_commitments || model?.is_disabled || totalCostCount === 1"
    [disabledAdd]="
      (!(canAddInvoiceCosts$ | async) && commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES) ||
      model?.is_disabled
    "
    (removeItem)="askRemoveSubItem()"
    (addItem)="addCost()"
  >
  </app-add-remove-buttons>
</form>
