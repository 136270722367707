<div class="left">
  <div class="form column titles-column">
    <div class="pr-[15px] box-border flex gap-1">
      <div cdkDragHandle class="icon-draggable flex items-center justify-center text-color_secondary cursor-grab"></div>
      <div class="spend-name-input-gc">
        <div class="name">{{ item.name }}</div>
        <div class="other-info">{{ item.division }}</div>
      </div>
    </div>
  </div>
  <div class="form column budget-area">
    <div class="input total-year">
      <app-spend-custom-input
        [disabled]="true"
        (valueChange)="setItemTotal($event, item); calcDistribution(item)"
        class="app-custom-input prime-spend-input"
        [value]="item.project_total"
        [ngClass]="{
          'totals-match-error':
            item.project_total !== item.original_prime_total && item.distribution === DISTRIBUTION_TYPES.MANUAL
        }"
        [pTooltip]="
          item.project_total !== item.original_prime_total && item.distribution === DISTRIBUTION_TYPES.MANUAL
            ? 'For manual distribution with contract, the total must be equal to the initial total which is ' +
              (item.original_prime_total >= 0
                ? '$' + item.original_prime_total
                : '($' + item.original_prime_total * -1 + ')')
            : ''
        "
      >
      </app-spend-custom-input>
    </div>
  </div>
</div>
<div class="right">
  <div class="distribution-type-area">
    <div class="input column">
      <app-input-calendar
        [minStartDate]="minStartDate"
        [date]="item.start_date"
        (dateChanged)="setStartDate($event, item); calcDistribution(item)"
        [showLabel]="false"
        [name]="'start_date_' + item.id"
        topPosition="top-2"
        class="prime-calendar-spend"
        readonly
      ></app-input-calendar>
    </div>

    <div class="input select-with-border">
      <app-dropdown
        (modelChange)="setItemDuration($event, item); calcDistribution(item)"
        [model]="item.duration"
        [name]="'duration' + item.id"
        [ngModelOptions]="{ standalone: true }"
        [options]="durationMonths"
        [showLabel]="false"
        [showError]="false"
        appendTo="body"
        class="spend-app-dropdown"
      ></app-dropdown>
    </div>
    <!--    <div *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS" class="input disabled"></div>-->

    <div class="input select-with-border">
      <app-dropdown
        optionValue="key"
        optionLabel="name"
        (modelChange)="setItemDistribution($event, item); calcDistribution(item)"
        [model]="item.distribution"
        [name]="'distribution_type_' + item.id"
        [ngModelOptions]="{ standalone: true }"
        [options]="distributionTypes"
        [showLabel]="false"
        [showError]="false"
        appendTo="body"
        class="spend-app-dropdown"
        [disabled]="item.project_total !== item.original_prime_total && item.distribution === DISTRIBUTION_TYPES.MANUAL"
        [pTooltip]="
          item.project_total !== item.original_prime_total && item.distribution === DISTRIBUTION_TYPES.MANUAL
            ? 'Please fix the total value mismatch first.'
            : null
        "
      ></app-dropdown>
    </div>
  </div>

  <div class="months">
    <div class="input-wrapper month-input column">
      <div class="input">
        <app-spend-custom-input [disabled]="true" class="app-custom-input prime-spend-input" [value]="item.year_total">
        </app-spend-custom-input>
      </div>
    </div>
    @for (month of MONTHS_KEYS; track $index) {
      <div class="input-wrapper month-input column">
        <div class="input">
          <app-spend-custom-input
            (valueChange)="registerChange($event, item, month, 'monthly_prime')"
            class="app-custom-input prime-spend-input"
            [ngClass]="{
              'project-activity': !item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL
            }"
            [disabled]="item.monthly_disable[month] || item.distribution !== DISTRIBUTION_TYPES.MANUAL"
            [value]="item.monthly_data[month]"
            [pTooltip]="
              (!item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL) ||
              item.monthly_disable[month]
                ? tooltipContent
                : ''
            "
            tooltipPosition="bottom"
          >
          </app-spend-custom-input>
          <ng-template #tooltipContent>
            @if (!item.monthly_disable[month] && item.distribution !== DISTRIBUTION_TYPES.MANUAL) {
              Input field cannot be edited, as it is being distributed based on project activity budget.
            } @else if (item.monthly_disable[month]) {
              Input field cannot be edited outside project activity.
            }
          </ng-template>
        </div>
      </div>
    }
  </div>
</div>
