import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-commitment-entry-text',
  templateUrl: './commitment-entry-text.component.html',
  styleUrls: ['./commitment-entry-text.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CommitmentEntryTextComponent {
  @Input() text: string;
  @Input() entryType: 'General' | 'Invoice' | 'GeneralInfo' = 'General';
  @Input() index = null;
  // todo: check functionality and when to disable description rules, tooltips etc and move out of this component
  @Input() disableAddDescription = false;
  @Input() disabledAddDescriptionTooltip = 'Select a line item first';
  @Input() disableRemovedEntry = false;

  // todo: check, and remove
  @Output() addClicked = new EventEmitter<void>();
  @Output() removeClicked = new EventEmitter<void>();

  removeEntry() {
    this.removeClicked.emit();
  }
}
