import { Injectable, OnDestroy } from '@angular/core';
import { CurrentUserService } from './current-user.service';
import moment from 'moment/moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MONTH_IS_AFTER_YEAR, MONTH_IS_BEFORE_YEAR } from '../framework/constants/spend.constants';

/*
 * A service for handling fiscal year data.
 * Getters and variables which contain the word 'index' return 0 based index data. Ex.: 2 refers to March
 * Getters and variables which do NOT contain the word 'index' return 1-based index data. Ex.: 2 refers to February
 * Getters with an '$' symbol return observables which change their value if the user settings change.
 * Note that this service is generally asynchronous - it depends on the userservice and has to wait for it to get data.
 * The static variables are needed in places where dependency injection could not be reached, like ngrx selectors/reducers.
 * 'Calendar Month Index' refers to the month's actual index in the calendar we use. (starts in January)
 * 'Fiscal Month Index' refers to the month's index in the fiscal year. (start in FiscalYearStart)
 * */
@Injectable({
  providedIn: 'root',
})
export class FiscalService implements OnDestroy {
  // the static variant is not advised,
  // but it is needed in ngrx selectors/reducers which can not inject this service
  static fiscalYear: number;
  private _currentFiscalYear: number;
  private _fiscalYear$ = new BehaviorSubject<number>(null);

  private _fiscalYearStart = 1;
  private _fiscalYearStart$ = new BehaviorSubject<number>(null);

  private _currentMonthIndex = moment().month();
  private _currentMonth = moment().month() + 1;
  private _currentYear = moment().year();

  private _dataReady$ = new BehaviorSubject(false);

  private _subs = new Subscription();

  constructor(private userService: CurrentUserService) {
    this.setFiscalYearStart();
  }

  private setFiscalYearStart() {
    const sub = this.userService.data$.subscribe((userData) => {
      this._fiscalYearStart = userData.fiscal_year_start;
      this._fiscalYearStart$.next(this._fiscalYearStart);

      this._currentFiscalYear = this.getCurrentFiscalYear();
      FiscalService.fiscalYear = this._currentFiscalYear;
      this._fiscalYear$.next(this._currentFiscalYear);

      this._dataReady$.next(true);
    });
    this._subs.add(sub);
  }

  private getCurrentFiscalYear() {
    if (this._fiscalYearStart > 1) {
      if (this._fiscalYearStart > this._currentMonth) {
        return this._currentYear;
      }
      return this._currentYear + 1;
    }
    return this._currentYear;
  }

  isDataReady$() {
    return this._dataReady$.asObservable().pipe(filter((isReady) => isReady));
  }

  get fiscalYear() {
    return this._currentFiscalYear;
  }

  get fiscalYearStart() {
    return this._fiscalYearStart;
  }

  get fiscalYearStart$() {
    return this._fiscalYearStart$.asObservable().pipe(filter((value) => !!value));
  }

  get fiscalYear$() {
    return this._fiscalYear$.asObservable().pipe(filter((value) => !!value));
  }

  get currentMonth() {
    return this._currentMonth;
  }

  get currentYear() {
    return this._currentYear;
  }

  isCurrentMonth(month: number, year: number) {
    return month === this._currentMonth && year === this._currentYear;
  }

  // if not sure why this is needed, see: https://stackoverflow.com/a/4467559/6146963
  modulo = (num, divider) => {
    return ((num % divider) + divider) % divider;
  };

  /**
   * Get the calendar month's 1-based index in the current fiscal year
   * @param month - 1-based calendar month index
   * */
  calendarMonthToFiscalMonth(month) {
    // the +1 at the end is for the 1-based index
    return this.calendarMonthIndexToFiscalMonthIndex(month - 1) + 1;
  }

  /**
   * Get the calendar month's 0-based index in the current fiscal year
   * @param monthIndex - 0-based calendar month index
   */
  calendarMonthIndexToFiscalMonthIndex(monthIndex) {
    return this.modulo(monthIndex - (this.fiscalYearStart - 1), 12);
  }

  /**
   * get the fiscalMonthIndex's month index in the calendar
   * ex. if fiscalYearStart = 3 then the fiscalMonthIndexToCalendarMonthIndex(0) returns 2 (March is the 0th month)
   * @param fiscalMonthIndex represents the index of the month in a fiscal year
   */
  fiscalMonthIndexToCalendarMonthIndex(fiscalMonthIndex) {
    return (fiscalMonthIndex + this.fiscalYearStart - 1) % 12;
  }

  isBeforeCurrentMonth(month, year) {
    return month <= this._currentMonth && year <= this._currentYear;
  }

  /**
   * returns the first month (1-based index) which is forecast month
   * returns a value < 0 if the year is smaller than the current fiscal year
   * returns a value > 12 if the year is bigger than the current fiscal year
   * */
  firstForecastMonth(fiscalYear): number {
    if (this.fiscalYear === fiscalYear) {
      // return the next month's fiscal index
      if (this._currentMonth === 12) {
        return this.calendarMonthToFiscalMonth(1);
      }
      return this.calendarMonthToFiscalMonth(this._currentMonth + 1);
    }
    if (this.fiscalYear > fiscalYear) {
      return MONTH_IS_AFTER_YEAR;
    }
    return MONTH_IS_BEFORE_YEAR;
  }

  /**
   * returns the last month (1-based index) which is actuals month (current month)
   * returns a value < 0 if the year is smaller than the current fiscal year
   * returns a value > 12 if the year is bigger than the current fiscal year
   * */
  lastActualsMonth(year: number): number {
    if (this.fiscalYear === year) {
      return this.calendarMonthToFiscalMonth(this._currentMonth);
    }
    if (this.fiscalYear > year) {
      return MONTH_IS_AFTER_YEAR;
    }
    return MONTH_IS_BEFORE_YEAR;
  }

  isForecastMonth(monthIndex: number, fiscalYear: number) {
    // monthIndex starts from 0
    // monthIndex == 0 => the first month in the cashflow table (fiscal year start month)
    const totalMonths = 12;
    let changingMonth;
    if (this._fiscalYearStart <= this._currentMonth) {
      changingMonth = this._currentMonth - this._fiscalYearStart + 1;
    } else {
      changingMonth = totalMonths - this._fiscalYearStart + 1 + this._currentMonth;
    }
    if (fiscalYear < this._currentFiscalYear) {
      return false;
    } else if (fiscalYear === this._currentFiscalYear) {
      if (changingMonth === 0) {
        return false;
      }
      return monthIndex >= changingMonth;
    }
    return true;
  }

  /**
   * Here extended refers to the fact that current month could be forecast or actuals month too.
   * If the actuals equals 0 then it should display forecast value.
   * Note: it only changes current month, other months are decided by isForecastMonth
   * @param fiscalMonthIndex represents the index of the month in a fiscal year
   * @param fiscalYear
   * @param actualValue - optional parameter, if omitted acts like normal isForecastMonth
   */
  isForecastMonthExtended(
    fiscalMonthIndex: number,
    fiscalYear: number,
    actualValue: number = null,
  ) {
    const isRegularForecastMonth = this.isForecastMonth(fiscalMonthIndex, fiscalYear);
    if (isRegularForecastMonth) {
      return true;
    }
    const lastActualsMonth = this.lastActualsMonth(fiscalYear);
    if (lastActualsMonth > MONTH_IS_AFTER_YEAR) {
      return true;
    }
    if (lastActualsMonth < MONTH_IS_BEFORE_YEAR) {
      return false;
    }
    return fiscalMonthIndex + 1 === lastActualsMonth && actualValue === 0;
  }

  /**
   * checks if month's number can be a valid month.
   * True if it is between the boundaries of MONTH_IS_BEFORE_YEAR and MONTH_IS_AFTER_YEAR
   * which represent invalid month numbers like -1 and 13;
   * @param month - can be a zero or 1-based indexed month number
   */
  isMonthNumberValid(month: number) {
    return month > MONTH_IS_BEFORE_YEAR && month < MONTH_IS_AFTER_YEAR;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
