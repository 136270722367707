import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CORLogItem } from '../../../../../store/prime-commitments/prime-commitments.types';
import { UnStyledOptionsListComponent } from '../../../../overlays/un-styled-options-list/un-styled-options-list.component';
import { OPTIONS } from '../../../../constants/options-list.constants';
import { StatusBlockComponent } from '../../../../../pages/webapp/projects/view-project/rfi-listing/status-block/status-block.component';
import { DynamicProjectedTableComponent } from '../../../../dynamic-projected-table/dynamic-projected-table.component';
import { ExpansionPanelComponent } from '../../../../expansion-panel/expansion-panel.component';
import { PageLoadingComponent } from '../../../../page-loading/page-loading.component';
import { MoneyPipe } from '../../../../../pipes/framework/money-short.pipe';
import { primeChangesActions } from '../../../../../store/prime-commitments/prime-commitments.actions';
import { INTERACTION_BAR_STATES } from '../../../../constants/interaction-bar.constants';
import { primeCommitmentsDetailsSelectors } from '../../../../../store/prime-commitments/prime-commtiments.selectors';
import { FadedTextComponent } from '../../../../faded-text/faded-text.component';
import { InteractionBarStateService } from 'src/app/services/interaction-bar-state.service';

@Component({
  selector: 'app-prime-cor-details',
  standalone: true,
  imports: [
    AsyncPipe,
    UnStyledOptionsListComponent,
    StatusBlockComponent,
    DynamicProjectedTableComponent,
    ExpansionPanelComponent,
    PageLoadingComponent,
    MoneyPipe,
    FadedTextComponent,
  ],
  templateUrl: './prime-cor-details.component.html',
  styleUrl: './prime-cor-details.component.scss',
})
export class PrimeCorDetailsComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly interactionBar = inject(InteractionBarStateService);
  private editChangeActionTriggered = false;

  @Input() CORLogItem: CORLogItem;

  COR$ = this.store.select(primeCommitmentsDetailsSelectors.selectCORDetailsSidebar);

  wasDataLoadedOnce$: Observable<boolean> = this.store.select(
    primeCommitmentsDetailsSelectors.selectWasCORDataLoadedOnce,
  );
  isLoading$: Observable<boolean> = this.store.select(
    primeCommitmentsDetailsSelectors.selectIsCORDetailLoading,
  );

  editOptions: OPTIONS[] = [OPTIONS.EDIT, OPTIONS.DELETE];

  expandedPCOs = new Map<number, boolean>();
  expandedLineItems = new Map<string, boolean>();

  combinedCommitmentTotals$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectCORDetailsCombinedCommitmentTotals,
  );
  totalDaysExtension$ = this.store.select(
    primeCommitmentsDetailsSelectors.selectCORDetailsTotalDurationExtension,
  );

  registerOption(option: OPTIONS) {
    switch (option) {
      case OPTIONS.EDIT:
        this.editChangeActionTriggered = true;
        this.interactionBar.openInteractionBar(INTERACTION_BAR_STATES.CHANGE_ACTION, {
          changeActionType: 'Change Order Request',
          isEdit: true,
        });
        break;
      case OPTIONS.DELETE:
        this.store.dispatch(primeChangesActions.onDeleteCOR({ id: this.CORLogItem.id }));
        break;
    }
  }

  ngOnInit() {
    this.store.dispatch(primeChangesActions.onCORDetailsAccess({ id: this.CORLogItem.id }));
  }

  ngOnDestroy() {
    this.store.dispatch(
      primeChangesActions.onCORDetailsSidebarClosed({
        editChangeActionTriggered: this.editChangeActionTriggered,
      }),
    );
  }
}
