<div class="sidebar-wrapper">
  @if ((currentPage$ | async) === 1 || (currentPage$ | async) === 4) {
    <app-sidebar-header (backButtonClicked)="goToPreviousPage()" [showBackButton]="(currentPage$ | async) !== 1">
      <div smallTitle>{{ model.id ? 'Edit' : 'Add' }} Prime Contract</div>
      @switch (currentPage$ | async) {
        @case (1) {
          <div mainTitle>General Information</div>
        }
        <!--        CASE 2 & 3 IS HANDLED IN THE COMPONENT-->
        @case (4) {
          <div mainTitle>Retainage Settings</div>
        }
      }
    </app-sidebar-header>
  }
  <div class="main-content m-0 flex flex-col">
    @switch (currentPage$ | async) {
      @case (1) {
        <app-prime-contract-general-page [model]="model"></app-prime-contract-general-page>
      }
      @case (2) {
        @if (showPrimeTemplateUploadPage) {
          <app-prime-contract-upload-template
            (parsedData)="replaceDataWithTemplate($event)"
          ></app-prime-contract-upload-template>
        } @else {
          <app-prime-contract-values [(model)]="model"></app-prime-contract-values>
        }
      }
      @case (3) {
        <app-prime-change-order-markups [(model)]="model"></app-prime-change-order-markups>
      }
      @case (4) {
        <app-prime-invoice-retainage [(model)]="model" (saveContract)="saveContract()"></app-prime-invoice-retainage>
      }
    }
  </div>
</div>
