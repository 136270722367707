<div class="flex flex-col h-full">
  <div class="flex text-size_xl text-shade_2 font-semibold ml-7 pl-0.5 mt-6 mb-12 pt-px">
    <app-back-button-box (click)="goBack()"></app-back-button-box>
    <span>Add Change Action</span>
  </div>

  <form #lineItemsForm="ngForm" role="form" class="flex-1 min-h-0 flex flex-col">
    <ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
      @for (lineItem of lineItems; track index; let index = $index) {
        <div class="line-item-title">
          <span>{{ name }} Line Item {{ index + 1 }}</span>
          <span
            (click)="deleteLineItem(index)"
            class="icon-trash-x cursor-pointer text-size_icon_bigger text-color_reject"
          ></span>
        </div>
        <div class="my-2.5 mx-8 flex flex-col items-center">
          @if (scope === 'new') {
            <app-pco-new-scope-entry
              class="w-full"
              [lineItem]="lineItem"
              [index]="index"
              (lineItemsChange)="lineItemUpdated($event, index)"
            ></app-pco-new-scope-entry>
          }

          @if (scope === 'existing') {
            <app-pco-existing-scope-entry
              class="w-full"
              [lineItem]="lineItem"
              [index]="index"
              (lineItemsChange)="lineItemUpdated($event, index)"
            ></app-pco-existing-scope-entry>
          }
        </div>
      }

      <div class="flex my-2.5 mx-8">
        <app-simple-button
          class="flex-1"
          [wrapperClass]="{ 'w-full': true }"
          buttonStyle="color_secondary_border"
          (click)="addEntry()"
          >Add Entry
        </app-simple-button>
      </div>
    </ng-scrollbar>

    <div class="mt-auto flex flex-col mx-8">
      <div class="border-b border-b-solid border-shade_5"></div>

      <div class="flex justify-end my-5">
        <app-simple-button (click)="submitForm()" buttonStyle="color_accent_filled"> Submit </app-simple-button>
      </div>
    </div>
  </form>
</div>
